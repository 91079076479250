<div class="row">
    <div class="col">
        <h1>Thank you for your purchase!</h1>
        <div appConfetti></div>
        <p id="confetti">
            If you need to manage your subscription, click the <strong>Manage Subscription</strong> button located at the top of the screen.
        </p>
        <p>
            Also, we want you to be successful with Scormify, so please contact our support if you need assistance.
        </p>
        <p><strong>Note: </strong>It may take a few moments for your subscription to take effect and for the <strong>Manage Subscription</strong> button appear (you may need to refresh the page). If you do not see it within 5 minutes, please contact us. </p>
    </div>
</div>