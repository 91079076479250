<form [formGroup]="formquestion">
  <div *ngIf="!this.questionTypeSelected">
    <div>
      <label class="qselect">Select question type</label>
    </div>

    <div>
      <label>
        <input type="radio" value="1" formControlName="questionType" [(ngModel)]="this.questionType">
        <span>{{texts.question_type_multiple_choice}}</span>
      </label>
    </div>
    <div>
      <label>
        <input type="radio" value="2" formControlName="questionType" [(ngModel)]="this.questionType">
        <span>{{texts.question_type_multiple_select}}</span>
      </label>
    </div>
    <div>
      <label>
        <input type="radio" value="3" formControlName="questionType" [(ngModel)]="this.questionType">
        <span>{{texts.question_type_true_false}}</span>
      </label>
    </div>
    <div>
      <label>
        <input type="radio" value="4" formControlName="questionType" [(ngModel)]="this.questionType">
        <span>{{texts.question_type_tip}}</span>
      </label>
    </div>
    <div>
      <button type="submit" class="btn btn-primary qselectbtn" [disabled]="!this.formquestion.valid"
        (click)="onSelectQuestionClick()">{{texts.button_select}}</button>
    </div>
  </div>

  <div *ngIf="this.questionTypeSelected && this.questionType=='1'">
    <app-question-multiplechoice [question]="workingQuestion" (addQuestionOutput)="onAddQuestion($event)"
      (cancelQuestion)="onCancelQuestion()" (saveQuestionOutput)="onSaveQuestion($event)"
      (deleteQuestion)="onDeleteQuestion($event)">
    </app-question-multiplechoice>
  </div>

  <div *ngIf="this.questionTypeSelected && this.questionType=='2'">
    <app-question-multipleselect [question]="workingQuestion" (addQuestionOutput)="onAddQuestion($event)"
      (cancelQuestion)="onCancelQuestion()" (saveQuestionOutput)="onSaveQuestion($event)"
      (deleteQuestion)="onDeleteQuestion($event)">
    </app-question-multipleselect>
  </div>
  <div *ngIf="this.questionTypeSelected && this.questionType=='3'">
    <app-question-truefalse [question]="workingQuestion" (addQuestionOutput)="onAddQuestion($event)"
      (cancelQuestion)="onCancelQuestion()" (saveQuestionOutput)="onSaveQuestion($event)"
      (deleteQuestion)="onDeleteQuestion($event)">
    </app-question-truefalse>
  </div>
  <div *ngIf="this.questionTypeSelected && this.questionType=='4'">
    <app-question-tip [question]="workingQuestion" (addQuestionOutput)="onAddQuestion($event)"
      (cancelQuestion)="onCancelQuestion()" (saveQuestionOutput)="onSaveQuestion($event)"
      (deleteQuestion)="onDeleteQuestion($event)">
    </app-question-tip>
  </div>
</form>