import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject } from 'rxjs';
import { Question, Quiz, Scormify } from '../../../../../models/scormify.model';
import { SlugifyPipe } from '../../../../../pipes/slugify.pipe';
import { texts } from '../../../../../../assets/texts/texts';
import { BaseComponent } from '../../../../../shared/base/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-question',
    templateUrl: './question.component.html',
    providers: [SlugifyPipe],
    standalone: false
})
export class QuestionComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  formquestion: UntypedFormGroup;
  public questionTypeSelected: boolean = false;
  public questionType: string;
  public alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  public workingQuestion: Question;
  texts = texts;

  @ViewChild('instance', {
    static: true,
  })
  instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  stateSub: any;

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  @Output()
  closeQuestion = new EventEmitter();

  @Input()
  public question: Question;

  public changedQuestion: Question;


  @Input()
  public showQuestion: boolean;

  @Input() object$: BehaviorSubject<Scormify>;
  object: Scormify;

  onSelectQuestionClick() {
    this.questionTypeSelected = true;
  }

  onCancelQuestion() {
    this.closeQuestion.emit();
  }

  onDeleteQuestion(question: Question) {
    let deletequestion = this.object.quiz.questions.find((q) => q.id == question.id);

    let index = this.object.quiz.questions.indexOf(deletequestion);

    this.object.quiz.questions.splice(index, 1);

    this.closeQuestion.emit();
  }

  ngOnChanges() {

    this.ngOnInit();
  }

  ngOnInit() {
    this.formquestion = this.fb.group({
      questionType: ['', Validators.required],
    });
    this.object$.pipe(takeUntil(this.destroy$)).subscribe(object => this.object = object);
    if (this.question.id == null) {
      this.formquestion = this.fb.group({
        questionType: ['', Validators.required],
      });
      this.questionTypeSelected = false;
    } else {
      this.questionTypeSelected = true;
      this.questionType = this.question.questionType.toString();
    }

    this.workingQuestion = JSON.parse(JSON.stringify(this.question));
  }

  onAddQuestion(question: Question) {
    if (this.object.quiz == null) this.object.quiz = new Quiz();
    if (this.object.quiz.questions == null) this.object.quiz.questions = new Array<Question>();


    const maxValueOfId = Math.max(...this.object.quiz.questions.map(o => o.id), 0);
    let id = maxValueOfId + 1;
    question.id = id;
    this.object.quiz.questions.push(question);

    this.object.quiz.questions.sort((a, b) => (a.timeStamp < b.timeStamp ? -1 : 1))

    this.closeQuestion.emit();
  }

  onSaveQuestion(question: Question) {
    let updatequestion = this.object.quiz.questions.find((q) => q.id == question.id);

    let index = this.object.quiz.questions.indexOf(updatequestion);

    this.object.quiz.questions[index] = question;

    this.closeQuestion.emit();
  }

  ngOnDestroy(): void { }
}
