<div >
  <div class="modal-header">
    <h4 class="modal-title">{{ texts.label_quiz_results }}</h4>
  </div>
  <div class="modal-body">
    <div *ngIf="this.passedQuiz">
      <p>
        {{texts.label_quiz_passed}}
      </p>
      
    </div>
    <div *ngIf="!this.passedQuiz">
      <p>
      {{texts.label_quiz_failed}}
    </p>
    </div>
    <div>
      <p>{{texts.label_your_score_is}}{{this.score | number : '1.0-2'}} %</p>
    </div>
    <div>
      <p>{{getNumberOfQuestionsCorrectOfTotalQuestionsLabel()}}</p>
    </div>
    <div>
      <p>{{texts.label_passing_score_is}}{{this.passingScore | number : '1.0-2'}} %</p>
    </div>
    <div *ngIf="!this.passedQuiz">
      {{texts.label_please_try_again}}
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" (click)="activeModal.close(false)">
        {{ texts.button_close }}
      </button>
    </div>
  </div>