import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCheck, faCopy, faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { texts } from '../../../assets/texts/texts';
import { Style } from '../../models/style.model';
import { ApiResponse, Link } from './../../models/api-response.model';
import { LinksService } from './../../services/links.service';
import { ModalService } from './../../services/modal.service';
import { StylesService } from './../../services/styles.service';
import { IconDefinition } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-manage-styles',
    templateUrl: './manage-styles.component.html',
    standalone: false
})
export class ManageStylesComponent implements OnInit, OnDestroy {
  faPlus = faPlus;
  faEdit = faPencilAlt;
  styles: Array<Style>;
  sub: Subscription;
  modalSub: Subscription;
  execActionResponse: Observable<ApiResponse>;
  texts = texts;
  faCheck = faCheck;
  faCopy = faCopy;
  constructor(
    private svc: StylesService,
    private linksSvc: LinksService,
    private router: Router,
    private modalSvc: ModalService
  ) { }

  ngOnInit() {
    this.getStyles();
  }

  getStyles() {
    this.sub = this.svc.getStyles(false).subscribe((styles) => (this.styles = styles));
  }

  buttonAction(link: Link) {
    if (link.requireConfirm) {
      this.modalSub = this.modalSvc
        .confirm(link.confirmText)
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.execAction(link);
          }
        });
    } else {
      this.execAction(link);
    }
  }

  execAction(link: Link) {
    this.execActionResponse = this.linksSvc.handleRel(link) as Observable<ApiResponse>;
    this.execActionResponse.subscribe((res) => this.getStyles());
  }

  getIcon(link: Link): IconDefinition {
    return this.linksSvc.getIcon(link);
  }

  gotoNewStyle() {
    return this.router.navigate(['styles/new']);
  }

  copyId(style: Style) { }

  ngOnDestroy() {
    this.sub.unsubscribe();
    if (this.modalSub) {
      this.modalSub.unsubscribe();
    }
  }
}
