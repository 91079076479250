import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { take } from 'rxjs/operators';
import { ApiResponse } from '../../models/api-response.model';
import { Reset } from '../../models/reset.model';
import { AccountService } from '../../services/account.service';
import { texts } from './../../../assets/texts/texts';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    providers: [AccountService],
    standalone: false
})
export class ResetPasswordComponent implements OnInit {
  searchString: string;
  reset: Reset;
  precheckOk: boolean;
  error: string;
  message: string;
  texts = texts;
  constructor(
    private svc: AccountService,
    private router: Router,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.reset = new Reset();
    this.reset.profile = {
      email: '',
    };
    this.precheckOk = false;
  }

  ngOnInit() {
    this.precheckOk = false;
    this.route.params.pipe(take(1)).subscribe((params) => {
      this.searchString = params['searchString'];
      if (this.searchString) {
        this.precheck(this.searchString);
      }
    });
  }

  precheck(searchString: string) {
    this.svc
      .precheck('RESET', searchString)
      .pipe(take(1))
      .subscribe((res) => {
        this.reset = res.payload;
        if (this.reset.profile.email) {
          this.precheckOk = true;
        }
      });
  }

  dispatch() {
    this.error = undefined;
    this.message = undefined;
    if (this.precheckOk && !this.reset.resetCode) {
      this.error = this.texts.message_reset_no_code;
    }
    if (this.precheckOk && this.reset.resetCode) {
      this.resetPassword();
    }
    if (!this.precheckOk && this.reset.profile.email) {
      this.precheck(this.reset.profile.email);
    }
  }

  resend() {
    this.error = undefined;
    this.message = undefined;
    this.svc
      .resendCode('RESET', this.reset.profile.email)
      .toPromise()
      .then((res: ApiResponse) => {
        this.message = res.message;
      })
      .catch((err) => (this.error = err.error));
  }

  resetPassword() {
    this.error = undefined;
    this.message = undefined;
    this.recaptchaV3Service
      .execute('resetpassword')
      .toPromise()
      .then((token) => {
        this.reset.recaptchaToken = token;
        this.svc
          .reset(this.reset)
          .toPromise()
          .then((res: ApiResponse) => {
            this.router.navigate([res.action.href], { queryParams: { reset: 1 } });
          })
          .catch((err) => (this.error = err.error));
      });
  }
}
