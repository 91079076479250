import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../services/account.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    standalone: false
})
export class LogoutComponent implements OnInit {
  constructor(
    private svc: AccountService,
  ) {}

  ngOnInit(): void {
    this.svc.logout();
  }
}
