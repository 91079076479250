@if (show()) {
<button
        type="button"
        aria-label="Scroll to top of the page"
        tabindex="0"
        class="scrolltop-button"
        #scrollTopButton
        (click)="scrollToTop()"
        [ngClass]="theme()"
        [style.left]="position() === 'left' ? '20px' : ''"
        [style.backgroundColor]="backgroundColor()"
        [style.width.px]="size()"
        [style.height.px]="size()">
  <div class="symbol-container">
    <span #ref>
      <ng-content></ng-content>
    </span>
    <img src="assets/images/arrow.svg" alt="Scroll to top" class="scrolltop-icon" />
  </div>
</button>
}