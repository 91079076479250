<h1>{{ texts.header_bulk }}</h1>
<p *ngIf="loading || !objectControlsReady"><app-spinner></app-spinner></p>
<div class="alert alert-danger" *ngIf="hasError">
  {{error}}
</div>
<small class="text-muted">{{ texts.tooltip_bulk }}</small>
<form
      [formGroup]="createForm"
      *ngIf="objectControlsReady && !loading"
      (ngSubmit)="onSubmit(createForm)">
  <ul class="nav justify-content-end">
    <li class="nav-item">
      <button class="btn btn-primary me-3" type="submit">{{texts.button_create}}</button>
    </li>
    <li class="nav-item">
      <app-bulk-import [success$]="success$" (uploadSuccess)="import($event)" *ngIf="showBulkImport"></app-bulk-import>
    </li>
  </ul>
  <div formArrayName="objects">
    <ngx-datatable
                   #datatable
                   [rows]="objectControls"
                   [columns]="columns"
                   class="bootstrap"
                   [columnMode]="ColumnMode.force"
                   rowHeight="auto"
                   [headerHeight]="40"
                   [scrollbarH]="true">
      <ngx-datatable-column name="" [sortable]="false" [maxWidth]="50" [width]="50">
        <ng-template
                     let-row="row"
                     let-value="value"
                     let-rowIndex="rowIndex"
                     ngx-datatable-cell-template>
          <fa-icon
                   class="mt-3"
                   [id]="'row_' + rowIndex"
                   [ngClass]="{'text-success': submitted && row.valid,  'text-danger': submitted && row.invalid}"
                   *ngIf="submitted"
                   [icon]="faCheck"
                   size="lg"></fa-icon>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="" [sortable]="false" [maxWidth]="50" [width]="50">
        <ng-template
                     let-row="row"
                     let-value="value"
                     let-rowIndex="rowIndex"
                     ngx-datatable-cell-template>
          <fa-icon
                   class="mt-3 clickable"
                   [id]="'row_' + rowIndex"
                   (click)="removeRow(rowIndex)"
                   [icon]="faTrash"
                   size="lg"></fa-icon>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="texts.label_type" [sortable]="false" [maxWidth]="250">
        <ng-template
                     let-row="row"
                     let-value="value"
                     let-rowIndex="rowIndex"
                     ngx-datatable-cell-template>
          <div [formGroupName]="rowIndex">
            <select
                    (change)="setTypeFromEvent($event, rowIndex)"
                    class="form-control"
                    title="select type"
                    formControlName="type">
              <option *ngFor="let type of types" [value]="type.key" [disabled]="type.disabled">
                {{ type.name }}
              </option>
            </select>
            <small class="form-text text-muted">{{texts.form_required}}</small>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="texts.label_title" [sortable]="false">
        <ng-template
                     let-row="row"
                     let-value="value"
                     let-rowIndex="rowIndex"
                     ngx-datatable-cell-template>
          <div [formGroupName]="rowIndex">
            <div formGroupName="descriptors">
              <input
                     type="text"
                     name="title-input"
                     formControlName="title"
                     #title
                     class="form-control"
                     (change)="watchSlugify(title.value, row)"
                     required />
              <input
                     id="descriptors-filename"
                     type="hidden"
                     class="form-control"
                     formControlName="filename"
                     #filename />
            </div>
            <small class="form-text text-muted">{{texts.form_required}}</small>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="texts.label_style" [sortable]="false" [maxWidth]="250">
        <ng-template
                     let-row="row"
                     let-value="value"
                     let-rowIndex="rowIndex"
                     ngx-datatable-cell-template>
          <div [formGroupName]="rowIndex">
            <select class="form-control" formControlName="style" [title]="texts.label_style">
              <option *ngFor="let style of styles" [value]="style.id" [disabled]="style.disabled">
                {{ style.name }}
              </option>
            </select>
            <small class="form-text text-muted">{{texts.form_required}}</small>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="" [sortable]="false">
        <ng-template
                     let-row="row"
                     let-value="value"
                     let-rowIndex="rowIndex"
                     ngx-datatable-cell-template>
          <div *ngIf="isTypeSet(row)">
            <div [ngSwitch]="getRowType(row)">
              <ng-template [ngSwitchCase]="'VIDEO_STREAMING'">
                <app-streaming
                               (update)="onStreaming(row, $event)"
                               [object$]="getObjectSubFromRow(rowIndex)"
                               [success$]="success$"></app-streaming>
              </ng-template>
              <ng-template [ngSwitchCase]="'VIDEO_YOUTUBE'">
                <app-youtube
                             (update)="onYoutube(row, $event)"
                             [object$]="getObjectSubFromRow(rowIndex)"
                             [success$]="success$"></app-youtube>
              </ng-template>
              <ng-template [ngSwitchCase]="'VIDEO_VIMEO'">
                <app-vimeo
                           (update)="onVimeo(row, $event)"
                           [object$]="getObjectSubFromRow(rowIndex)"
                           [success$]="success$"></app-vimeo>
              </ng-template>
              <ng-template [ngSwitchCase]="'PPT'">
                <app-powerpoint
                                [object$]="getObjectSubFromRow(rowIndex)"
                                [type$]="getTypeSubFromRow(rowIndex)"
                                (update)="onPowerPoint(row, $event)"
                                [success$]="success$"></app-powerpoint>
              </ng-template>
              <ng-template [ngSwitchCase]="'PDF'">
                <app-document
                              [object$]="getObjectSubFromRow(rowIndex)"
                              [type$]="getTypeSubFromRow(rowIndex)"
                              (update)="onPDF(row, $event)"
                              [success$]="success$"></app-document>
              </ng-template>
            </div>
            <app-completion
                            class="create-section mb-5"
                            [rowIndex]="rowIndex"
                            (update)="onCompletion(row, $event)"
                            [object$]="getObjectSubFromRow(rowIndex)"
                            [type$]="getTypeSubFromRow(rowIndex)"></app-completion>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
  <div>
    <button class="btn btn-primary me-3" (click)="addRow()" type="button">{{ texts.button_add_row }}</button>
    <button class="btn btn-primary me-3" (click)="addRow(true)" type="button">
      {{ texts.button_copy_row }}
    </button>
    <button class="btn btn-primary" type="submit">{{ texts.button_create }}</button>
  </div>
</form>

<!--
    <ngx-datatable
      [rows]="rows | async"
      [columns]="columns"
      class="bootstrap"
      columnMode="force"
      rowHeight="auto"
      [headerHeight]="40"
    >
      <ngx-datatable-column [name]="texts.label_title">
        <ng-template
          let-row="row"
          let-value="value"
          let-rowIndex="rowIndex"
          ngx-datatable-cell-template
        >
        <div [formGroupName]="rowIndex">
          {{ rowObject(rowIndex).get('descriptors').get('title').value  }}
          <div [formGroupName]="descriptors">
            <input type="text" formControlName="title">
          </div>

        </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="texts.label_type">
        <ng-template
          let-row="row"
          let-value="value"
          let-rowIndex="rowIndex"
          ngx-datatable-cell-template
        >
          placeholder
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="">
        <ng-template
          let-row="row"
          let-value="value"
          let-rowIndex="rowIndex"
          ngx-datatable-cell-template
        >
          placeholder
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>

-->

<!--

    <table>
      <tr *ngFor="let objectForm of objectControls; let i = index" [formGroup]="objectForm">
        <td formGroupName="descriptors">
          <input type="text" formControlName="title" >
        </td>
        <td >
          <input type="text" formControlName="type">
        </td>
      </tr>

    </table>
-->