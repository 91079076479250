<app-spinner *ngIf="loading"></app-spinner>
<section id="new-style" *ngIf="!loading" class="container-fluid" scrollSpyElement="styleElem">
  <br>
  <div class="row">
    <div class="col-2">
      <div class="sticky-top">
        <ul class="nav nav-pills flex-column justify-content-center ps-3" role="navigation" id="new-style-menu" scroll-spy>
          <li class="nav-item">
            <button class="nav-link btn btn-link" (click)="scrollTo('#sec-title')">{{texts.label_style}}</button>
          </li>
          <li class="nav-item">
            <button class="nav-link btn btn-link" (click)="scrollTo('#sec-html')">{{texts.header_html}}</button>
          </li>
          <li class="nav-item">
            <button class="nav-link btn btn-link" (click)="scrollTo('#sec-javascript')">{{texts.header_javascript}}</button>
          </li>
          <li class="nav-item">
            <button class="nav-link btn btn-link" (click)="scrollTo('#sec-scss')">{{texts.header_scss}}</button>
          </li>
          <li class="nav-item">
            <button class="nav-link btn btn-link" (click)="scrollTo('#sec-footer')">{{texts.header_footer}}</button>
          </li>
          <li class="nav-item">
            <button class="nav-link btn btn-link" (click)="scrollTo('#sec-logo')">{{texts.header_logo}}</button>
          </li>
        </ul>
      </div>
      <br>
    </div>
    <div class="col-8">
      <div class="row col-12" *ngIf="showResetConfirm">
        <div class="alert alert-primary p-4 col-8">
          {{texts.message_style_revert_confirm}}
          <ul class="list-inline list-unstyled mt-4">
            <li class="list-inline-item">
              <button class="btn btn-primary" (click)="reset()">{{texts.button_confirm}}</button>
            </li>
            <li class="list-inline-item">
              <button class="btn btn-primary" (click)="resetCancel()">{{texts.button_cancel}}</button>
            </li>
          </ul>
        </div>
      </div>
      <div class="row" *ngIf="error">
        <div class="alert alert-danger col-12" [innerHTML]="error">
        </div>
      </div>
      <form #styleform class="row col-12">
        <div class="row">
          <div class="col">
            <div class="form-group" id="sec-title">
              <label>{{texts.header_style_name}}</label>
              <input class="form-control" name="styletitle" [(ngModel)]="style.name"
                     (ngModelChange)="hasChangesEv($event)" required [readonly]="style.readonly" />
            </div>
            <div class="form-group" id="sec-html">
              <label>{{texts.header_html}}</label>
              <div class="form-text text-muted">
                <ul class="list-unstyled list-inline">
                  <li class="list-inline-item">{{texts.form_required_tags}}</li>
                  <li class="list-inline-item" *ngFor="let tag of style.tags; index as i; last as isLast">
                    <code>{{tag}}</code><span *ngIf="!isLast">,</span>
                  </li>
                </ul>
              </div>
              <ngx-monaco-editor [(ngModel)]="style.body.html" name="stylehtml" [options]="htmlConfig"
                                 (ngModelChange)="hasChangesEv($event)" class="editor-container"></ngx-monaco-editor>
            </div>
            <div class="form-group" id="sec-javascript">
              <label>{{texts.header_javascript}}</label>
              <ngx-monaco-editor [(ngModel)]="style.body.javascript" name="stylejavascript" [options]="jsConfig"
                                 (ngModelChange)="hasChangesEv($event)" class="editor-container"></ngx-monaco-editor>
            </div>
            <div class="form-group" id="sec-scss">
              <label>{{texts.header_scss}}</label>
              <ngx-monaco-editor [(ngModel)]="style.body.sass" name="stylescss" [options]="scssConfig"
                                 (ngModelChange)="hasChangesEv($event)" class="editor-container"></ngx-monaco-editor>
            </div>
            <div class="form-group" id="sec-footer">
              <label>
                {{texts.header_footer}}
              </label>
              <quill-editor format="html" [(ngModel)]="style.body.footer" (ngModelChange)="hasChangesEv($event)" name="stylefooter"
                            [style]="{height: '250px'}"></quill-editor>
            </div>
            <div class="form-group" id="sec-logo">
              <label>{{texts.header_logo}}</label>
              <div class="center" *ngIf="style.body.logo && style.body.logo.embed">
                <img [src]="style.body.logo.embed" style="max-height: 50px; width: auto; margin-bottom: 10px;" alt="">
                <button class="btn btn-primary d-block mt-2" (click)="removeLogo()">{{texts.button_remove_logo}}</button>
              </div>
              <div class="center" *ngIf="!style.body.logo">
                <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" accept=".jpg,.png,.gif,.jpeg" multiple="false">
                  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                    <div class="upload-help-text">
                      Drag and drop files here.<br />(.jpg,.png,.gif,.jpeg) Size Limit: 1 MB
                    </div>
                    <button type="button" class="btn btn-primary btn-sm" (click)="openFileSelector()">Select File</button>
                  </ng-template>
                </ngx-file-drop>
                <div class="file-size-error" *ngIf="fileTooBig">File is too big: {{ this.fileSize.toFixed(2) }} MB</div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-2">
      <div class="sticky-top">
        <ul class="nav nav-pills flex-column justify-content-center">
          <li class="nav-item mb-2">
            <button class="btn btn-primary btn-block" (click)="save()"
                    [disabled]="!canSave()">{{texts.button_save}}</button>
          </li>
          <li class="nav-item mb-2" [ngbTooltip]="texts.tooltip_reset">
            <button class="btn btn-outline-primary btn-block" (click)="confirmReset()" [disabled]="!canSave()">
              {{texts.button_reset}}
            </button>
          </li>
          <li class="nav-item mb-2" *ngFor="let action of style.$actions" [ngbTooltip]="action.tooltip">
            <button
                    class="btn btn-outline-{{action.css}} btn-block" (click)="buttonAction(action)"
                    [disabled]="action.disabled">
              <!--come back to this <fa-icon *ngIf="action.icon" [icon]="getIcon(action)"></fa-icon>-->
              {{action.text}}
            </button>
          </li>
          <li class="nav-item mb-2"><button class="btn btn-outline-primary btn-block" (click)="goBack()">{{texts.button_back}}</button>
          </li>
        </ul>
      </div>
    </div>
  </div> <br>
</section>