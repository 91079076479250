import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { User } from '../../models/user.model';
import { AccountService } from '../../services/account.service';
import { LinksService } from '../../services/links.service';
import { ApiResponse, Link } from './../../models/api-response.model';
import { Scormifyv1Service } from './../../services/scormifyv1.service';
import { StylesService } from './../../services/styles.service';

@Component({
    selector: 'app-admin-home',
    templateUrl: './home.component.html',
    standalone: false
})
export class AdminHomeComponent implements OnInit {
  numberOfStyles = 0;
  numberOfUsers = 0;
  numberOfVideos = 0;
  numberOfQuizes = 0;
  numberOfQuestions = 0;
  sub: Subscription;
  execActionResponse: Observable<ApiResponse>;
  filter = { text: '', confirmed: false, marketing: false };

  loading = true;
  constructor(
    private accountService: AccountService,
    private stylesService: StylesService,
    private scorfmiyService: Scormifyv1Service,
    private links: LinksService) {
  }

  ngOnInit() {
    this.getUsers();
    this.getStyles();
    this.getNumberOfVideos();
    this.getNumberOfQuestions();
    this.getNumberOfQuizes();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  dispatch(action: Link, user: User) {
    this.links.xhrAction(action, user).subscribe(res => this.getUsers());
  }

  getUsers(filterText?: string, filterMarketing?: boolean, filterConfirmed?: boolean) {
    this.sub = this.accountService
      .getUsers(this.numberOfUsers, filterText, filterMarketing, filterConfirmed)
      .subscribe(users => {
        this.numberOfUsers += users.$records;
        this.loading = false;
      });
  }

  getStyles() {
    this.stylesService
        .getNumberOfStyles()
        .subscribe((res: ApiResponse) => {
          const data = res.$records;
          this.numberOfStyles += data;
        });
    }

  getNumberOfVideos(){
    this.scorfmiyService.getNumberOfVideos().subscribe((res: ApiResponse) => {
      const data = res.$records;
      this.numberOfVideos+=data;
    })
  }

  getNumberOfQuizes(){
    this.scorfmiyService.getNumberOfQuizes().subscribe((res: ApiResponse) => {
      const data = res.$records;
      this.numberOfQuizes +=data;
    })
  }


  getNumberOfQuestions(){
    this.scorfmiyService.getNumberOfQuestions().subscribe((res: ApiResponse) => {
      const data = res.$records;
      this.numberOfQuestions +=data ;
    })
  }
  }
