<div *ngIf=!isPreview>
  <div class="row">
    <div class="col-lg-8">
      <figure>
        <video #my_video id="my_video" (play)="play()" (pause)="pause()" (loadeddata)="initialLoad($event)"
               [src]="this.object.muse.file.url" (timeupdate)="setCurrentTime($event)" [currentTime]="videoOffset" style="pointer-events: none;">
        </video>
        <div class="timestamptext" *ngIf="!this.isPreview">
          <span>{{texts.label_lesson}} {{texts.label_question}} {{texts.label_timestamps}}</span>
        </div>
        <div style="display: flex;background-color: transparent;" *ngIf="!this.isPreview">

          <div style=" width: 760px;
    overflow-y: hidden;">
            <table *ngIf="this.object.quiz && this.object.quiz.questions" class="qstyle">
              <tr class="qtr">
                <td class="right qtr" *ngFor="let question of this.object.quiz.questions;let i=index">
                  <button (click)="selectQuestion(question.id)" class="qstemps">
                    {{i+1}}
                  </button>
                </td>
              </tr>
              <tr>
                <td class="right qtime" *ngFor="let question of this.object.quiz.questions">
                  {{question.timeStamp}}
                </td>
              </tr>
            </table>
          </div>

        </div>
        <figcaption>
          <div *ngIf="!this.isPreview">
            <input type="range" class="LessonView-Slider" class="qvideoslide" id="slider" [(ngModel)]="rangeOffset"
                   (ngModelChange)="setRam($event)" min="0" max="1000" step="1" />
            <label id="timer" for="range" role="timer">{{currentTime * 1000 | date:'mm:ss' }} / {{ videoDuration *
                1000 |
                date:'mm:ss'}}</label>
          </div>

        </figcaption>
      </figure>


      <div *ngIf="this.playvideo" class="inline-display">
        <button (click)="my_video.play()" class="btn btn-primary" [disabled]="loading">{{texts.button_play}}</button>
      </div>
      <div *ngIf="!this.playvideo" style="display:inline-block">
        <button (click)="my_video.pause()" class="btn btn-primary" [disabled]="loading">{{texts.button_pause}}</button>
      </div>
      <div class="inline-display">
        <button (click)="my_video.load(); setPlayButton();" class="btn btn-primary"
                [disabled]="loading">{{texts.button_rewind}}</button>
      </div>
      <div class="inline-display">
        <button (click)="my_video.pause(); insertquestion()"
                class="btn btn-primary">{{texts.button_insert_question}}</button>
      </div>
      <div class="inline-display">
        <button (click)="preview();" class="btn btn-primary"
                [disabled]="!this.formvideo.valid">{{texts.button_preview}}</button>
      </div>
      <div class="inline-display">
        <button (click)="publishClick()" class="btn btn-primary"
                [disabled]="!this.formvideo.valid">Publish</button>
      </div>
      <form [formGroup]="formvideo">
        <div>
          <label class="passingspace">{{texts.label_passing_score}}
            <span [ngbTooltip]="texts.tooltip_passing_score">
              <fa-icon [icon]="faQC"></fa-icon>
            </span>
          </label>
        </div>
        <div *ngIf="!this.isPreview">
          <input type="number" min="0" max="100" class="form-control" [(ngModel)]="this.object.quiz.passingScore"
                 style="width: 100px;display: initial;" formControlName="passingScore">
        </div>
        <div *ngIf="!this.isPreview"><small
                 class="form-text qsmall">{{texts.form_required_min_zero_max_hundred}}</small>
        </div>

      </form>
    </div>
    <div class="col-lg-4" *ngIf="this.showQuestion == true">
      <app-question [object$]="object$" [question]="this.questionSelected" (closeQuestion)="onCloseQuestion()">
      </app-question>
    </div>
  </div>
</div>
<div *ngIf="isPreview">
  <div class="modal-header">
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <figure>
          <video #my_video id="my_video" (play)="play()" (pause)="pause()" (ended)="videoEnded()"
                 (loadeddata)="initialLoad($event)" [src]="this.object.muse.file.url" (timeupdate)="setCurrentTime($event)"
                 [currentTime]="videoOffset" style="pointer-events: none;">
          </video>

          <figcaption>
            <div> <label id="timer" for="range" role="timer">{{currentTime * 1000 | date:'mm:ss' }} / {{ videoDuration *
                1000 |
                date:'mm:ss'}}</label>
            </div>
          </figcaption>
        </figure>


        <div *ngIf="this.playvideo" class="inline-display">
          <button (click)="my_video.play()" class="btn btn-primary" [disabled]="loading">{{texts.button_play}}</button>
        </div>
        <div *ngIf="!this.playvideo" style="display:inline-block">
          <button (click)="my_video.pause()" class="btn btn-primary"
                  [disabled]="loading">{{texts.button_pause}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="activeModal.close(false)" class="btn btn-primary"
            [disabled]="loading">{{texts.button_close}}</button>
  </div>
</div>