import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    template: '',
    standalone: false
})
export class BaseComponent implements OnDestroy {
  /**
   * Object to hold the observables that need to be unsubscribed from
   */
  destroy$ = new Subject<void>();

  constructor() { }

  /**
   * Implements onDestroy and steps through anything that was subscribed to an unsubscribes
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }



}
