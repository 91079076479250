import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { constants } from '../../assets/constants/constants';
import { environment } from '../../environments/environment';
import { ApiResponse } from '../models/api-response.model';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(private http: HttpClient, private accountSvc: AccountService) {}
  getHomeContent() {
    return this.http.get(`${environment.api}/content/home`).pipe(map((res: ApiResponse) => res));
  }
  getCache(cache: string) {
    return this.http.get(`${environment.api}/cache/${cache}`).pipe(
      take(1),
      map((res: ApiResponse) => res)
    );
  }
  getHeaders(headers: HttpHeaders) {
    if (this.accountSvc.hasToken()) {
      headers = headers.append(
        constants.header_authorization,
        `Bearer ${this.accountSvc.getToken()}`
      );
    }
    return headers;
  }
}
