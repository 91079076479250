<div class="form-group mt-1" *ngIf="selectedType">
  <label>{{texts.label_completion}}
    <span [ngbTooltip]="texts.tooltip_completion">
      <fa-icon [icon]="faQC"></fa-icon>
    </span>
  </label>
  <div class="form-row mb-3" *ngIf="!selectedType.opts?.disableThreshold">
    <div class="input-group mb-2 100">
      <input type="number" appThreshold (ngModelChange)="onChangeThreshold($event)" [(ngModel)]="completion.threshold" name="threshold" id="threshold" #threshold="ngModel"
             class="form-control">
      <div class="input-group-append">
        <div class="input-group-text">%</div>
      </div>
    </div>
    <small class="form-text text-muted d-block">{{texts.form_required}}</small>
    <div *ngIf="threshold.invalid  && (threshold.dirty || threshold.touched)" class="text-danger d-block col">
      <small class="form-text" *ngIf="threshold?.errors?.invalidRange">Invalid range. Must be between 1 and 100.</small>
    </div>
  </div>
  <div class="form-row" *ngIf="selectedType.opts.completeOnDownload">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" [(ngModel)]="completion.completeOnDownload" (ngModelChange)="onChange($event)">
      <label class="form-check-label">
        {{texts.label_complete_download}}
        <span [ngbTooltip]="texts.tooltip_complete_download">
          <fa-icon [icon]="faQC"></fa-icon>
        </span>
      </label>
    </div>
  </div>
  <div class="form-row">
    <div class="form-check form-check-inline" *ngIf="selectedType.opts?.disableScrubber">
      <input class="form-check-input" [id]="'completion-disable-scrubber'+rowIndex.toString()" type="checkbox" value="" [(ngModel)]="completion.disableScrubber"
             (ngModelChange)="onChange($event)">
      <label class="form-check-label" [for]="'completion-disable-scrubber'+rowIndex.toString()">
        {{ texts.label_disable_seek }}
        <span [ngbTooltip]="texts.tooltip_disable_seek">
          <fa-icon [icon]="faQC"></fa-icon>
        </span>
      </label>
    </div>
    <div class="form-check  form-check-inline" *ngIf="selectedType.opts?.enableBookmarks">
      <input class="form-check-input" type="checkbox" value=""
             [disabled]="completion.addOverlayQuiz"
             [(ngModel)]="completion.enableBookmarks"
             (ngModelChange)="onChange($event)" [id]="'completion-enableBookmarks'+rowIndex.toString()">
      <label [for]="'completion-enableBookmarks'+rowIndex.toString()" class="form-check-label">
        {{ texts.label_enable_bookmarking }}
        <span [ngbTooltip]="texts.tooltip_enable_bookmarking">
          <fa-icon [icon]="faQC"></fa-icon>
        </span>
      </label>
    </div>
    <div class="form-check  form-check-inline" *ngIf="selectedType.opts?.addOverlayQuiz">
      <input class="form-check-input" type="checkbox" value="" [(ngModel)]="completion.addOverlayQuiz"
             (ngModelChange)="onChange($event)">
      <label class="form-check-label">
        {{ texts.label_enable_quiz_overlay }}
        <span [ngbTooltip]="texts.tooltip_quiz">
          <fa-icon [icon]="faQC"></fa-icon>
        </span>
      </label>
    </div>
  </div>
</div>
<div class="mb-3"></div>