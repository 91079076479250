import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { texts } from '../../../../assets/texts/texts';

@Component({
    selector: 'app-confirm-navigation-modal',
    templateUrl: './confirm-navigation-modal.component.html',
    standalone: false
})
export class ConfirmNavigationModalComponent {
  constructor(public modal: NgbActiveModal) { }
  texts = texts;
}
