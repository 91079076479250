import { IconDefinition } from '@fortawesome/angular-fontawesome';
import { LockStatus } from './user-subscription';

export class ApiResponse {
  message?: string;
  payload?: any;
  links?: Array<Link>;
  action?: Link;
  $collectionSize?: number;
  $records?: number;
  $next?: string;
  remaining?: number;
  lockStatus?: LockStatus;
  bulkResponse?: ApiResponse;
}

export class Link {
  rel?: string;
  href?: string;
  text?: string;
  data?: any;
  params?: any;
  param?: any;
  css?: string;
  icon?: IconDefinition;
  tooltip?: string;
  requireConfirm?: string;
  confirmText?: string;
  disabled?: boolean;
  disabledText?: string;
  method?: HttpMethod;
  active?: boolean;
  mobileOnly?: boolean;
  mobileHidden?: boolean;
}

export enum HttpMethod {
  get,
  post,
  put,
  patch,
  delete,
}
