<section class="container" id="forgot-password">

  <h1>Forgot Password</h1>

  <p>If you have forgotten your password, or would like to reset it, enter your email address below.</p>
  <div class="alert alert-danger" *ngIf="error">{{error}}</div>
  <div class="alert alert-success" *ngIf="message">{{message}}</div>
  <form #f="ngForm" (ngSubmit)="onSubmit(f)" novalidate>
    <div class="form-group">
      <label>Email Address:</label>
      <input name="email" type="text" class="form-control mb-3" [(ngModel)]="user.profile.email" required>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <ul class="list-inline">
          <li class="list-inline-item">
            <button type="submit" class="btn btn-primary">Forgot Password</button>
          </li>
        </ul>

      </div>

    </div>
  </form>
</section>
