import { UserSubscription } from '../../models/user-subscription';
import { User } from './../../models/user.model';

export interface IApp {
  id?: string;
  testMode?: boolean;
  authenticated?: boolean;
  user?: User;
  role?: string;
  subscription?: UserSubscription;
}

export interface IAppState {
  app: IApp;
}

export const initialAppState: IAppState = {
  app: {
    id: null,
    testMode: false,
    authenticated: false,
    user: null,
    role: 'END_USER',
    subscription: null,
  },
};
