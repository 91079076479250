import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from './../services/app.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(private service: AppService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = this.service.getHeaders(req.headers);
    const modifiedReq = req.clone({
      headers
    });
    return next.handle(modifiedReq);
    }
}
