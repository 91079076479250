// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  protocol: 'https://',
  get root() {
    return `${this.protocol}dev.scormify.io`;
  },
  get api() {
    return `${this.root}/api/v1`;
  },
  title: 'Scormify | Powered by Knowledge Anywhere',
  get appUri() {
    return `${this.protocol}dev.scormify.io`;
  },
  get oauthUri() {
    return `${this.appUri}/oauth`;
  },
  ghClientId: 'e2e53f57fee0aa0330d1',
  liClientId: '863wbzo04o3vfc',
  fbClientId: '1953032445011989',
  gClientId: '183837773556-6sptunulvkanahg7unt5pbofdg25knrj.apps.googleusercontent.com',
  pageSize: 25,
  recaptchaKey: '6LeMoLoUAAAAAABvwKLlCZ9HjAJoi1VciN9TwPR3',
  sk: 'pk_test_GMyUxtdosUJ6MI2WVkDPvI23',
  subService: 'https://service-dev.scormify.io/api',
  analytics_id: 'UA-31448990-5',
};