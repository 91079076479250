import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ApiResponse } from '../models/api-response.model';
import { Style } from '../models/style.model';
import { ConfirmNavigationModalComponent } from '../shared/modals/confirm-navigation-modal/confirm-navigation-modal.component';
import { texts } from './../..//assets/texts/texts';
import { environment } from './../../environments/environment';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root'
})
export class StylesService {
  constructor(private http: HttpClient, private ngbModal: NgbModal, private modalSvc: ModalService) { }

  getStyle(id: string) {
    return this.http
      .get(`${environment.api}/styles/${id}`)
      .pipe(map((res: ApiResponse) => res.payload));
  }

  getStyles(publishedOnly: boolean): Observable<Style[]> {
    let url = `${environment.api}/styles`;
    if (publishedOnly) {
      url += '?published=1';
    }
    return this.http.get(url).pipe(map((res: ApiResponse) => res.payload));
  }

  getNumberOfStyles() {
    let url = `${environment.api}/styles/get/numberOfStyles`;
    return this.http.get(url).pipe(map((res: ApiResponse) => res.payload));
  }

  saveStyle(style: Style) {
    return this.http
      .put(`${environment.api}/styles/${style._id}`, style)
      .pipe(map((res: ApiResponse) => res));
  }

  deleteStyle(id: string) {
    return this.http.delete(`${environment.api}/styles/${id}`).
      pipe(map((res: ApiResponse) => res));
  }

  removeLogo(id: string) {
    return this.http.delete(`${environment.api}/styles/${id}/logo`).
      pipe(map((res: ApiResponse) => res), take(1));
  }

  createStyle() {
    return this.http.post(`${environment.api}/styles`, {}).pipe(map((res: ApiResponse) => res));
  }

  confirmNavigation(id: string): Promise<boolean> {
    const modal = this.ngbModal.open(ConfirmNavigationModalComponent, {
      ariaLabelledBy: 'confirm-navigation'
    });
    return modal.result;
  }
  confirmDiscard(): Promise<boolean> {
    return this.modalSvc.confirm(texts.message_confirm_navigation).toPromise();
  }

  sendLogoFile(id: string, formData: FormData) {
    return this.http.post(`${environment.api}/styles/${id}/logo`, formData, { responseType: 'blob' });
  }
}
