<div class="uploader-wrapper">
    <div class="form-group">
      <div class="form-check form-check-inline d-none">
        <!-- future support-->
        <input class="form-check-input" type="checkbox" id="forcesize" name="forcesize" [(ngModel)]="video.forceVideoSize"
          (change)="onForceSizeChange()">
        <label class="form-check-label" for="forcesize">
          {{texts.label_force_size}}
          <span [ngbTooltip]="texts.tooltip_force_size">
            <fa-icon [icon]="faQC"></fa-icon>
          </span>
        </label>
      </div>
      <div class="form-check form-check-inline d-none">
        <!-- future support -->
        <input class="form-check-input " type="checkbox" id="subtitles" name="subtitles" [(ngModel)]="video.disableSubtitles"
          (change)="onChanges()">
        <label class="form-check-label" for="subtitles">
          {{texts.label_disable_generated_captions}}
          <span [ngbTooltip]="texts.tooltip_disable_subtitles">
            <fa-icon [icon]="faQC"></fa-icon>
          </span>
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="search" name="search" [(ngModel)]="video.disableSearch"
          (change)="onChanges()">
        <label class="form-check-label" for="search">
          {{texts.label_disable_search}}
          <span [ngbTooltip]="texts.tooltip_disable_search">
            <fa-icon [icon]="faQC"></fa-icon>
          </span>
        </label>
      </div>
      <div *ngIf="video.forceVideoSize" class="mt-3">
        <p class="help-block text-muted">
          {{texts.label_checked_force_size}}
        </p>
        <div class="form-group">
          <label>{{texts.label_common_aspect_ratios}}</label>
          <select class="form-control" (change)="onAspectChange($event.target.value)" [disabled]="!constrain">
            <option *ngFor="let r of ratios" [value]="r.label">{{r.label}}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label>
            {{texts.label_aspect_ratio}}
          </label>
          <div class="form-row">
            <div class="col input-group">
              <input type="number" name="ratiow" #ratiow class="form-control" [(ngModel)]="ratio.width" placeholder="width"
                [disabled]="!constrain" (ngModelChange)="onChanges()">
            </div>
            <div class="col input-group">
              <input type="number" name="ratioh" #ratioh class="form-control" [(ngModel)]="ratio.height"
                placeholder="height" [disabled]="!constrain" (ngModelChange)="onChanges()">
            </div>
          </div>
        </div>
        <div class="form-check form-check-inline mb-3">
          <input class="form-check-input" type="checkbox" id="constrain" name="constrain"
            (change)="onConstrain($event.target.value)" [(ngModel)]="constrain" (ngModelChange)="onChanges()">
          <label class="form-check-label" for="constrain">{{texts.label_constrain_proportions}}</label>
        </div>
        <div class="form-group">
          <label>
            {{texts.label_video_size}}
          </label>
          <div class="form-row">
            <div class="col input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">w</span>
              </div>
              <input type="number" name="sizew" #sizew class="form-control" [(ngModel)]="video.videoSize.width"
                placeholder="width" (change)="onSetWidth()" (ngModelChange)="onChanges()">
              <div class="input-group-append">
                <span class="input-group-text">px</span>
              </div>
            </div>
    
            <div class="col input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">h</span>
              </div>
              <input type="number" name="sizeh" #sizeh class="form-control" [(ngModel)]="video.videoSize.height"
                placeholder="height" (change)="onSetHeight()" (ngModelChange)="onChanges()">
              <div class="input-group-append">
                <span class="input-group-text">px</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-uploader [type$]="type$" (uploadSuccess)="uploadSuccess($event)" (isRemoving)="isRemoving($event)" [success$]="success$"></app-uploader>
  </div>