<form [formGroup]="formtip">
  <div>
    <textarea placeholder="{{texts.place_holder_enter_tip_question}}" rows="3" cols="37" formControlName="questionText"
      [(ngModel)]="this.question.text" class="form-control"></textarea>
    <div><small class="form-text">Required.</small></div>

  </div>
  <div *ngIf="this.question.id == null" style="padding-left:60px">
    <button class="btn btn-primary" type="submit" [disabled]="this.formtip.invalid"
      (click)="onInsertClick()">{{texts.button_save}}</button>
    <button class="btn btn-primary" (click)="onCancelClick()">Cancel</button>
  </div>
  <div *ngIf="this.question.id != null" style="padding-left:60px">
    <button class="btn btn-primary" type="submit" [disabled]="this.formtip.invalid"
      (click)="onSaveClick()">{{texts.button_save}}</button>
    <button class="btn btn-primary" (click)="onDeleteClick()">{{texts.button_delete}}</button>

    <button class="btn btn-primary" (click)="onCancelClick()">{{texts.button_cancel}}</button>
  </div>
</form>