import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  aspectRatio,
  Ratio,
  ratios, Scormify, StreamingVideo
} from '../../../../models/scormify.model';
import { BaseComponent } from '../../../../shared/base/base.component';
import { IState } from '../../../../store/reducers';
import { selectApp } from '../../../../store/selectors/app.selectors';
import { texts } from './../../../../../assets/texts/texts';

@Component({
    selector: 'app-streaming',
    templateUrl: './streaming.component.html',
    standalone: false
})
export class StreamingComponent extends BaseComponent implements OnInit {
  video = new StreamingVideo();
  ratios = ratios;
  ratio: Ratio;
  computedRatio: Ratio;
  constrain = true;
  appState$ = this._store.pipe(select(selectApp));
  texts = texts;
  faQC = faQuestionCircle;

  @ViewChild('mp4url', { static: true }) inputmp4: NgModel;
  @ViewChild('posterurl', { static: true }) inputposter: NgModel;
  @ViewChild('hlsurl', { static: true }) inputhls: NgModel;
  @ViewChild('hlsurl', { static: true }) inputwebm: NgModel;

  @Input() success$: Subject<boolean>;
  @Input() value$: BehaviorSubject<Scormify>;
  @Output() update = new EventEmitter<StreamingVideo>();

  constructor(private _store: Store<IState>) {
    super();
  }

  ngOnInit() {
    this.appState$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      this.onAspectChange(ratios[0].label);
      this.onChanges();
      this.success$.pipe(takeUntil(this.destroy$)).subscribe((v) => {
        this.video = new StreamingVideo();
        this.inputmp4.control.markAsPristine();
        this.inputmp4.control.updateValueAndValidity();
        this.inputhls.control.markAsPristine();
        this.inputhls.control.updateValueAndValidity();
        this.inputwebm.control.markAsPristine();
        this.inputwebm.control.updateValueAndValidity();
        this.inputposter.control.markAsPristine();
        this.inputposter.control.updateValueAndValidity();
      });
    });
  }

  onChanges() {
    this.update.emit(this.video);
  }

  onForceSizeChange() {
    this.onChanges();
  }

  onConstrain($event: any) {
    console.log($event);
  }

  onSetHeight() {
    if (this.constrain) {
      const width = (this.video.videoSize.height / this.ratio.height) * this.ratio.width;
      this.video.videoSize.width = width;
    } else {
      // set custom ratio
      this.ratio = aspectRatio(this.video.videoSize.width, this.video.videoSize.height);
    }
    this.onChanges();
  }
  onSetWidth() {
    if (this.constrain) {
      const height = (this.video.videoSize.width / this.ratio.width) * this.ratio.height;
      this.video.videoSize.height = height;
    } else {
      this.ratio = aspectRatio(this.video.videoSize.width, this.video.videoSize.height);
    }
    this.onChanges();
  }

  onAspectChange(label: string) {
    this.ratio = ratios.find((x) => x.label === label);
    this.video.videoSize.height = this.ratio.defaultHeight;
    this.video.videoSize.width = this.ratio.defaultWidth;
    this.video.aspectRatio = this.ratio.label;
    this.onChanges();
  }
}
