import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Question, Scormify } from 'app/models/scormify.model';
import { VideoComponent } from 'app/scormify/v1/shared/quizes/video.component';
import { QuizModalComponent } from 'app/shared/modals/quiz-modal/quiz-modal.component';
import { from, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfirmModalComponent } from '../shared/modals/confirm-modal/confirm-modal.component';
import { QuizScoreModalComponent } from '../shared/modals/quiz-score-modal/quiz-score-modal.component';
import { OkModalComponent } from 'app/shared/modals/ok-modal/ok-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private ngbModal: NgbModal) { }

  confirm(
    prompt = 'Confirm you wish to execute the action.',
    title = 'Confirm'
  ): Observable<boolean> {
    const modal = this.ngbModal.open(ConfirmModalComponent, { backdrop: 'static' });

    modal.componentInstance.prompt = prompt;
    modal.componentInstance.title = title;

    return from(modal.result).pipe(
      catchError(error => {
        return of(undefined);
      })
    );
  }

  ok(
    title: string,
    message: string
  ): Observable<boolean> {
    const modal = this.ngbModal.open(OkModalComponent, { backdrop: 'static' });

    modal.componentInstance.message = message;
    modal.componentInstance.title = title;

    return from(modal.result).pipe(
      catchError(error => {
        return of(undefined);
      })
    );
  }

  openQuestion(scormify: Scormify, question: Question) {
    const modal = this.ngbModal.open(QuizModalComponent, { backdrop: 'static' });
    modal.componentInstance.scormify = scormify;
    modal.componentInstance.question = question;
    return from(modal.result).pipe(
      catchError(error => {
        return of(undefined);
      })
    );
  }

  openQuizPreview(scormify: Scormify, isPreview: boolean) {
    const modal = this.ngbModal.open(VideoComponent, { backdrop: 'static' });
    modal.componentInstance.object = scormify;
    modal.componentInstance.isPreview = isPreview;
    return from(modal.result).pipe(
      catchError(error => {
        return of(undefined);
      })
    );
  }

  openQuizScore(numberOfQuestionsToBeAnswered: number,
    numberOfQuestionsAnsweredCorrectly: number,
    score: number,
    passingScore: number) {
    const modal = this.ngbModal.open(QuizScoreModalComponent, { backdrop: 'static' });
    modal.componentInstance.numberOfQuestionsToBeAnswered = numberOfQuestionsToBeAnswered;
    modal.componentInstance.numberOfQuestionsAnsweredCorrectly = numberOfQuestionsAnsweredCorrectly;
    modal.componentInstance.score = score;
    modal.componentInstance.passingScore = passingScore;
    return from(modal.result).pipe(
      catchError(error => {
        return of(undefined);
      })
    );
  }


}
