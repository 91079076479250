import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Link } from '../../models/api-response.model';
import { SubscriptionSelection } from '../../models/subscription-selection';
import { UserSubscription } from '../../models/user-subscription';
import { LinksService } from '../..//services/links.service';
import { IState } from '../../store/reducers';
import { selectApp } from '../../store/selectors/app.selectors';
import { IApp } from '../../store/state/app.state';
import { texts } from '../../../assets/texts/texts';
import { ReplaySubject, Subscription } from 'rxjs';
import { delay, first, map, retryWhen, take, takeUntil } from 'rxjs/operators';
import { ScormifySubscription } from './../../models/scormify-subscription';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  standalone: false
})
export class UpgradeComponent implements OnInit {
  loading = true;
  scormifySubscriptions: Array<ScormifySubscription>;
  texts = texts;
  appState$ = this._store.pipe(select(selectApp));
  state: IApp;
  userSubscription: UserSubscription;
  show = false;
  getSessionLink: Link;
  selectedStep = 1;
  subscriptionSelection: SubscriptionSelection = { scormifySubscription: null, pricingPlan: null };
  routeParamSub: Subscription;
  userSubSub: Subscription;
  planSub: Subscription;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private _store: Store<IState>,
    private linkSvc: LinksService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.appState$
      .pipe(
        first(),
        map((s) => {
          if (!s.subscription) {
            throw new Error(); // Will be caught by `map` and reemitted as an error notification.
          }
          return s;
        }),
        retryWhen((errors) => errors.pipe(take(2), delay(1000)))
      )
      .subscribe((state) => {
        this.state = state;
        if (state.user != null && state.subscription) {
          this.setUserSubscription();
        }
      });
  }

  setReady() {
    this.loading = false;
  }

  setInitial() {
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
      if (params.session_id) {
        this.selectedStep = 3;
      }
      if (params.selectedStep && params.sub && params.price) {
        this.subscriptionSelection.scormifySubscription = this.scormifySubscriptions.find(
          (sub) => params.sub === sub.shortName
        );
        this.subscriptionSelection.pricingPlan = this.subscriptionSelection.scormifySubscription.pricingPlans.find(
          (price) => price.renewal === params.price
        );
        this.selectedStep = Number(params.selectedStep);
      }
    });
  }

  setUserSubscription() {
    this.userSubscription = this.state.subscription;
    this.getPlans();
  }

  getPlans() {
    const link = this.userSubscription.links.find((l) => l.rel === 'getPlans');
    if (link) {
      this.linkSvc
        .httpMethod(link)
        .pipe(first())
        .subscribe((res) => this.setPlans(res));
    }
  }

  setPlans(res) {
    this.scormifySubscriptions = res;
    this.setInitial();
    this.setReady();
  }

  onSelection(selected: SubscriptionSelection) {
    this.subscriptionSelection = selected;
    this.gotoStep(2);
  }

  gotoStep(step: number) {
    this.selectedStep = step;
    this.setUrlState();
  }

  setUrlState() {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {
        selectedStep: this.selectedStep,
        sub: this.subscriptionSelection.scormifySubscription.shortName,
        price: this.subscriptionSelection.pricingPlan.renewal,
      },
    });
  }

  onSuccess(event) {
    this.gotoStep(3);
  }

  OnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  clear() {
    this.selectedStep = 1;
  }
}
