import * as sharedTexts from './../../../../api/shared/texts/texts';
export const texts: any = { ...sharedTexts };

texts.button_cancel = 'Cancel';
texts.button_create = 'Create';
texts.button_create_and_next = 'Create & Next'
texts.button_clear = 'Clear';
texts.button_forgot_password = 'Forgot Password';
texts.button_login = 'Login';
texts.button_register = 'Register';
texts.button_confirm = 'Confirm';
texts.button_submit = "Submit";
texts.button_save = 'Save';
texts.button_back = 'Back';
texts.button_reset = 'Reset';
texts.button_remove_logo = 'Remove Logo';
texts.button_create_new_style = 'Create New Syle';
texts.button_edit = 'Edit';
texts.button_delete_account = 'Deactivate Account';
texts.button_select_ppt = 'Add PowerPoint';
texts.button_select_pdf = 'Add Document';
texts.button_select_video_upload = 'Select Video File'
texts.button_select_generic = 'Browse for File';
texts.button_remove_file = 'Remove @@FILE@@';
texts.button_manage_subscription = 'Manage Subscription';
texts.button_upgrade_subscription = 'Upgrade';
texts.button_apply = 'Apply';
texts.button_promo_code = 'Add Promotion Code';
texts.button_resend = 'Resend';
texts.button_play = 'Play';
texts.button_rewind = 'Rewind';
texts.button_insert_question = 'Insert Question';
texts.button_publish = 'Publish;'
texts.button_pause = 'Pause';
texts.button_select = 'Select';
texts.button_add_new_option = 'Add Answer';
texts.button_insert = 'Insert';
texts.button_save = 'Save';
texts.button_delete = 'Delete';
texts.button_cancel = 'Cancel';
texts.button_preview = 'Preview';
texts.button_close = 'Close';
texts.button_ok = 'OK';
texts.button_copy_row = 'Copy Previous Row';
texts.button_add_row = 'Add Row';


texts.form_optional = 'Optional.';
texts.form_required = 'Required.';
texts.form_required_min_zero_max_hundred = 'Required. Specify value between 0 and 100';
texts.form_required_fields = 'All required fields must be completed before submitting.';
texts.form_required_tags = 'The following tags are required:';
texts.form_placeholder = 'Select one...';
texts.form_default_jobrole = 'Not provided';
texts.form_correct_answer_required = 'Choosing correct answer is required.';

texts.place_holder_enter_multiple_choice_question = 'Enter your multiple choice question',

  texts.place_holder_enter_multiple_select_question = 'Enter your multiple select question',

  texts.place_holder_enter_true_false_question = 'Enter your true/false question',

  texts.place_holder_enter_tip_question = 'Enter your tip question',

  texts.header_profile = 'Profile';
texts.header_change_password = 'Change Password';
texts.header_api_keys = 'API Keys';
texts.header_linked_accounts = 'API Keys';
texts.header_settings = 'Settings';
texts.header_register = 'Register';
texts.header_login = 'Login';
texts.header_title = 'Title';
texts.header_html = 'HTML';
texts.header_scss = 'SCSS';
texts.header_javascript = 'Javascript';
texts.header_footer = 'Footer';
texts.header_logo = 'Logo';
texts.header_style_name = 'Style Name';
texts.header_manage_styles = 'Manage Styles';
texts.header_history = 'History';
texts.header_reset_password = 'Reset Your Password';
texts.header_settings_preferences = 'Settings & Preferences';
texts.header_confirm = 'Confirm';
texts.header_pay_with_card = 'Pay with Card';
texts.header_delete_answer = 'Delete Answer';
texts.header_bulk = 'Bulk Create';
texts.invalid_promo_code = 'Invalid Promotion Code';

texts.label_change = 'Change';
texts.label_title = 'Title';
texts.label_style = 'Style';
texts.label_type = 'Type';
texts.label_status = 'Status';
texts.label_search = 'Search';
texts.label_created = 'Created';
texts.label_window_size_override = 'Override default or custom style window size';
texts.label_window_size = 'Window Size';
texts.label_language = 'Language';
texts.label_language_override = "Allow user's language preference to override specified language";
texts.label_poster_url = 'Poster URL';
texts.label_hls_url = 'HLS URL';
texts.label_mp4_url = 'MP4 URL';
texts.label_webm_url = 'WebM URL';
texts.label_responsive = 'Responsive Controls';
texts.label_fluid_mode = 'Fluid Mode';
texts.label_autoplay = 'Autoplay';
texts.label_force_size = 'Force Size';
texts.label_video_options = 'Video Options (Advanced)';
texts.label_completion = 'Completion';
texts.label_disable_seek = 'Disable Seek (Scrubber Bar)';
texts.label_enable_bookmarking = 'Enable Bookmarking';
texts.label_enable_quiz_overlay = 'Add Quiz';
texts.label_common_aspect_ratios = 'Common Aspect Ratios';
texts.label_video_size = 'Video Size';
texts.label_aspect_ratio = 'Aspect Ratio';
texts.label_constrain_proportions = 'Constrain Proportions';
texts.label_youtube_url = 'YouTube URL';
texts.label_vimeo_url = 'Vimeo URL';
texts.label_checked_force_size = 'Checking force size disables fluid and responsive mode.';
texts.label_new = 'New';
texts.label_login = 'Login';
texts.label_email = 'Email';
texts.label_password = 'Password';
texts.label_password_new = 'New Password';
texts.label_first_name = 'First Name';
texts.label_last_name = 'Last Name';
texts.label_company = 'Company';
texts.label_job_role = 'Job Role';
texts.label_opt_in_email = 'I agree to let Knowledge Anywhere send me marketing related emails.';
texts.label_stay_signed_in = 'Stay signed in';
texts.label_system_style = 'System Style';
texts.label_select_ppt = 'Select PowerPoint';
texts.label_upload_success = 'Upload succeeded.';
texts.label_upload_failed = 'Upload failed.  Try again later.';
texts.label_complete_download = 'Autocomplete';
texts.label_password_required = 'Password is required.';
texts.label_subtotal = 'Subtotal';
texts.label_total = 'Total Due Today';
texts.label_billing_email = 'Billing Email';
texts.label_billing_name = 'Name on Card';
texts.label_confirmation_code = 'Confirmation Code';
texts.label_upgrade_plan = 'Upgrade Plan';
texts.label_upgrade_plan_txt = 'To use this feature you need to upgrade your plan. Do you wish to continue?'
texts.label_lesson = 'Lesson';
texts.label_question = 'Question';
texts.label_timestamps = 'Time Stamps';
texts.label_passing_score = 'Passing Score';
texts.label_quiz_passed = 'You have passed the quiz!';
texts.label_quiz_failed = 'You did not pass the quiz.';
texts.label_your_score_is = 'Your Score: ';
texts.label_passing_score_is = 'Passing Score Minimum Requirement: ';
texts.label_number_of_questions_correct_of_total_questions = 'You answered @@numberOfQuestionsAnsweredCorrectly@@ of @@numberOfQuestionsToBeAnswered@@ questions correctly.'
texts.label_please_try_again = 'Please try again.';
texts.label_are_you_sure = 'Are you sure?';
texts.label_bulk = 'Bulk';
texts.label_allow_document_download = 'Allow Document Download';
texts.label_disable_generated_captions = 'Disable Auto Generated Subtitles';
texts.label_disable_search = 'Disable Video Transcript Search';
texts.message_redirect = 'Any entries to the configuration will be lost.';
texts.message_multiple_choice_minimum_number_of_answers = 'You can not delete answer. Minimum number of answers for this type of question is 2.'
texts.message_multiple_select_minimum_number_of_answers = 'You can not delete answer. Minimum number of answers for this type of question is 3.'

texts.message_please_be_patient_scorm_is_prepared = "Please be patient - Your published SCORM package is being prepared."
texts.message_dont_refresh_page = "Please don't refresh this page.";
texts.message_processing = 'processing...';
texts.message_profile_invalid =
  'Consider setting a password and completing your profile. Setting a password will allow you to login without using a third party login.';
texts.message_profile_partial = 'Consider completing your profile.';
texts.message_login_confirmed = 'Thanks for confirming your account. You can now login.';
texts.message_login_reset = 'You succesfully reset your password. You can now login.';
texts.message_login_inactivity = 'You have been signed out due to inactivity.';
texts.message_login_expired_token = 'You have been automatically signed out.';
texts.message_login_sign_out = 'You have been signed out.';
texts.message_register_error =
  'An error occured during the registration process.  Please contact support.';
texts.message_style_revert_confirm =
  'Are you sure you would like to reset to previously saved state?';
texts.message_delete_account = `The action to close your account will delete all of your course conversions and any custom styles you may have created. Although your account will be closed, Knowledge Anywhere may continue to retain a record of your email address and some older versions of your course conversions in our backups (for up to a period of 12 months). Your email address will not be used for any marketing or communication purposes and will not be shared outside of Knowledge Anywhere. After closing your account, you will be unable to create a new account using the same email as the one associated with the account you are closing. To continue, please confirm  wish to delete your account.`;
texts.message_confirm_navigation = 'Navigating away from this page will discard any changes.';
texts.message_confirm_discard_style =
  'Navigating away will discard changes unless you name the style and save. Click Confirm to proceed or Cancel to go back.';
texts.message_generic_error = 'An error has occured - Please try again later.';
texts.message_comped_sub =
  'You are subscribed to a complimentary Scormify account care of Knowledge Anywhere. Thank you for being a valued customer!';
texts.message_locked_sub =
  'Darn! You have reached the conversions limit for your plan. If you would like to unlock more conversions, please upgrade your plan. ';
texts.message_cancelling =
  'Your subscription is set to cancel at the end of the billing period. (@@DATE@@)';

texts.message_conversions_remaining = 'You have @@CONVERSIONS@@ conversions remaining.';



texts.validation_poster_url =
  "Input doesn't appear to be an valid image url.  URL should end with jpg, jpeg or png.";
texts.validation_hls_url = "Input doesn't appear to be an valid HLS url. URL should end with m3u8.";
texts.validation_mp4_url = "Input doesn't appear to be an valid MP4 url. URL should end with mp4.";
texts.validation_webm_url =
  "Input doesn't appear to be an valid WebM url. URL should end with webm.";
texts.validation_first_name = 'First Name is required.';
texts.validation_last_name = 'Last Name is required.';
texts.validation_email_required = 'Email is required.';
texts.validation_email_format = 'Please provide a valid email.';
texts.validation_email_exists = 'An account with this email already exists.';
texts.validation_company = 'Company is required.';

texts.text_register_1 = 'Get started for free. No credit card required.';
texts.text_register_2 = 'Already have an account?';
texts.text_register_email = "We'll never share your email with anyone else.";
texts.text_login_1 = 'Enter your username and password.';
texts.text_scormify_history_1 = 'View your previously converted objects.';
texts.text_billing_disclaimer =
  'By confirming your subscription, you allow Knowledge Anywhere to charge your card for this payment and future payments until the cancellation of the subscription.';

texts.tooltip_title = 'This value will be populated in the courses manifest and read by the LMS.';
texts.tooltip_style =
  "Choose a style to be applied to the course.   If you don't see a style, make sure it is published.";
texts.tooltip_window_size =
  'The default width and height is 1475 x 900 pixels.  Checking this option will allow you to set custom dimensions.';
texts.tooltip_language =
  'Selecting a language will display the Scormify controls and elements in the desired language.   Translations provided by Amazon.';
texts.tooltip_language_override =
  "Allow the user's browser to determine what language the controls and elements should be displayed in.   We recommend enabling this option.";
texts.tooltip_poster_url = 'URL for a custom image to use as the poster.  Optional.';
texts.tooltip_hls_url =
  'URL leading to your hosted HLS manifest file.   HLS is a standard used for adaptive streaming.   URL should end with .m3u8 - Optional.';
texts.tooltip_mp4_url =
  'URL leading to your hosted MP4 file.  URL should end with .mp4.   Required.';
texts.tooltip_webm_url =
  'URL leading to your hosted WebM file.  URL should end with .webm.   Optional.';
texts.tooltip_responsive = 'Resize the video player controls based on the available screen size.';
texts.tooltip_fluid_mode =
  'Allow the video player to read the intrinsic size of the video and decide on the optimal size.';
texts.tooltip_autoplay = 'Start the video as soon as the video has loaded and is ready to play.';
texts.tooltip_force_size =
  "Checking force size allows you to specify a specific height and width instead of letting the LMS or player decide for you.  This feature may not be supported on all LMS applications.";
texts.tooltip_vimeo_url =
  'The embedded URL for your video found in the embed option in Vimeo.    Should not be a review link - Make sure to double check your videos privacy settings.';
texts.tooltip_youtube_url = 'The publicly available YouTube URL for your video.';
texts.tooltip_reset = 'Resetting will discard any changes since the style was last saved.';
texts.tooltip_enable_bookmarking =
  'Enabling bookmarking will allow the user to return to the previous point they left off during their previous session. Adding a quiz disables bookmarking.';
texts.tooltip_disable_seek =
  'Disabling seek prevents the user from fast-forwarding through a video.';
texts.tooltip_completion =
  'The value set here will determine what percentage of the content must be viewed before the course is considered complete.';
texts.tooltip_style_publish =
  'Publishing your style will make it available in the style picker when creating a new course.';

texts.tooltip_complete_download =
  'Selecting this option will mark the learner completed as soon as the course is opened.  If you do not select this option, the course will stay in progress until the learner is manually marked as completed in your LMS.';

texts.tooltip_passing_score = 'Passing score should be specified in range from 0 to 100';
texts.tooltip_bulk = 'The Bulk feature produces a zip of individual packages (SCOs).  For most LMSs, you will need to unzip the resulting file and upload each package individually.';
texts.tooltip_allow_download = 'Checking this option allows the user to both download and print the document.'
texts.tooltip_disable_search = 'Checking this option disables video transcript search for the user.  NOTE: If the disable scrubber option is selected, search will be always disabled.'
texts.tooltip_disable_subtitles = 'Checking this option disables auto generated subtitles.'
texts.tooltip_quiz = 'Select to insert quiz questions to your video at key intervals. Adding a quiz\'s overall passing score will determine completion. Quiz summary will appear when learner has watched video completion percentage.'

texts.step_one = 'Select Type';
texts.step_two = 'Configure';
texts.step_three = 'Download';

texts.true = 'True';
texts.false = 'False';
