import { regex } from '../constants/regex';

export function mediaExtractId(type: string, url: string): string {
  let id = null;
  switch (type.toLowerCase()) {
    case 'youtube':
      const ytMatch = url.match(regex.youtubeUrlExtractRgx);
      if (ytMatch) {
        id = ytMatch[1];
      }
      break;
    case 'vimeo':
      const vmMatch = url.match(regex.vimeoUrlExtractRgx);
      if (vmMatch) {
        id = vmMatch[5];
      }
      break;
  }
  return id;
}
