import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MuseResponse } from '../models/muse-response';
import { Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiResponse } from '../models/api-response.model';
import { ObjectType } from '../models/object-type.model';
import { Answer, Scormify } from '../models/scormify.model';

@Injectable({
  providedIn: 'root',
})
export class Scormifyv1Service {
  constructor(private http: HttpClient) { }

  public uploadSubject = new Subject<MuseResponse>();

  public answerReviewSubject = new Subject<Answer[]>();

  public onUpload(muse: MuseResponse) {
    this.uploadSubject.next(muse);
  }

  public onAnswered(answers: Answer[]) {
    this.answerReviewSubject.next(answers);
  }

  create(object: Scormify) {
    return this.http
      .post(`${environment.api}/scormify`, object)
      .pipe(take(1))
      .pipe(map((res: ApiResponse) => res));
  }

  bulk(objects: Scormify[]) {
    return this.http
      .post(`${environment.api}/scormify/bulk`, objects)
      .pipe(take(1))
      .pipe(map((res: ApiResponse) => res));
  }

  getTypes() {
    return this.http.get(`${environment.api}/cache/types`).pipe(
      take(1),
      map((res: ApiResponse) => res)
    );
  }

  getTypeByKey(object: ObjectType) {
    return this.http
      .get(`${environment.api}/scormify/types/${object.key}`)
      .pipe(map((res: ApiResponse) => res));
  }

  getLocales() {
    return this.http.get(`${environment.api}/cache/locales`).pipe(
      take(1),
      map((res: ApiResponse) => res)
    );
  }

  get(id: string) {
    return this.http.get(`${environment.api}/scormify/${id}`).pipe(
      take(1),
      map((res: ApiResponse) => res)
    );
  }

  getHistory() {
    return this.http.get(`${environment.api}/scormify`).pipe(
      take(1),
      map((res: ApiResponse) => res)
    );
  }

  getAll() {
    return this.http.get(`${environment.api}/scormify`)
      .pipe(map((res: ApiResponse) => res));
  }

  getNumberOfVideos() {
    return this.http.get(`${environment.api}/videos`)
      .pipe(map((res: ApiResponse) => res));
  }

  getNumberOfQuestions() {
    return this.http.get(`${environment.api}/questions`)
      .pipe(map((res: ApiResponse) => res));
  }

  getNumberOfQuizes() {
    return this.http.get(`${environment.api}/quizes`)
      .pipe(map((res: ApiResponse) => res));
  }
}
