import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CheckoutPackage } from '../models/subscription-selection';
import { IState } from '../store/reducers';
import {
  selectId
} from '../store/selectors/app.selectors';
import { constants } from './../../assets/constants/constants';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiResponse } from '../models/api-response.model';
import { Reset } from '../models/reset.model';
import { User } from '../models/user.model';
import { PatchApp } from '../store/actions/app.actions';
import { IApp } from '../store/state/app.state';
import { LinksService } from './links.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  appState$ = this._store.pipe(select(selectId));

  constructor(
    private http: HttpClient,
    private router: Router,
    private links: LinksService,
    private _store: Store<IState>,
    private linksSvc: LinksService,
    private toastr: ToastrService
  ) {}

  hydrate() {
    this.getIsAuthenticated();
    this.getSelf();
  }

  getIsAuthenticated(): Observable<boolean> {
    return this.http.get<ApiResponse>(`${environment.api}/auth/self`).pipe(
      tap({
        next: (res: ApiResponse) => {
          this._store.dispatch(new PatchApp({ authenticated: !!res.payload, id: res.payload }));
        },
      }),
      map((res: ApiResponse) => {
        return !!res.payload;
      }),
      catchError((err: HttpErrorResponse) => of<boolean>(false))
    );
  }

  getSelf(): Observable<User> {
    return this.http.get(`${environment.api}/account/self`).pipe(
      map((res: ApiResponse) => res.payload),
      tap({
        next: (user: User) => {
          this.getUserSubscription(user);
          this._store.dispatch(new PatchApp({ user: user }));
        },
      })
    );
  }

  getUserSubscription(user: User) {
    const link = user.$links.find((l) => l.rel === 'getSubscription');
    if (link) {
      return this.linksSvc.httpMethod(link);
    }
  }

  getUserSubscriptionFromState(state: IApp) {
    const link = state.user?.$links?.find((l) => l.rel === 'getSubscription');
    if (link) {
      return this.linksSvc.httpMethod(link);
    }
  }

  update(user: User, id: string) {
    return this.http
      .put(`${environment.api}/account/${id}`, user)
      .pipe(map((res: ApiResponse) => res))
      .toPromise();
  }

  remove(id: string) {
    return this.http
      .delete(`${environment.api}/account/${id}`)
      .toPromise()
      .then((res: ApiResponse) => {
        this.toastr.success('We are sad to see you go!', 'Account Removed');
        this.logout();
      });
  }

  register(user: User, token: string): Observable<ApiResponse> {
    return this.http
      .post(`${environment.api}/account/register?token=${token}`, user)
      .pipe(tap((res: ApiResponse) => this.handleAuthenticate(res)));
  }

  checkEmailAvailability(email: string) {
    return this.http.get(`${environment.api}/account/available/${email}`).pipe(
      delay(1000),
      map((res: ApiResponse) => res)
    );
  }

  precheck(type: string, searchString: string): Observable<ApiResponse> {
    return this.http
      .get(`${environment.api}/account/precheck/${type}/${searchString}`)
      .pipe(map((res: ApiResponse) => res));
  }

  login(user: User, token: string) {
    return this.http
      .post(`${environment.api}/account/login?token=${token}`, user)
      .pipe(tap({ next: (res: ApiResponse) => this.handleAuthenticate(res) }));
  }

  handleAuthenticate(res: ApiResponse) {
    this.setToken(res.payload);
  }

  setToken(payload) {
    if (this.getPeristLogin() === false) {
      sessionStorage.setItem(constants.ls_token, payload);
    } else {
      localStorage.setItem(constants.ls_token, payload);
    }
  }
  // needs refactoring - the problem here is you could have a LS stored value for persist login
  // but if you come in via social login it will always be stored in LS
  getToken(): string | undefined {
    let value;
    if (this.getPeristLogin() === false) {
      value = sessionStorage.getItem(constants.ls_token);
    } else {
      value = localStorage.getItem(constants.ls_token);
    }
    if (!value) {
      value = localStorage.getItem(constants.ls_token);
    }
    return value;
  }

  hasToken(): boolean {
    let value = this.getToken();
    if (value && value !== null && value !== '') {
      return true;
    } else {
      return false;
    }
  }

  getPeristLogin(): boolean | undefined {
    let value = localStorage.getItem(constants.ls_persist_login);
    if (value && value !== null) {
      if (value === 'true') return true;
      if (value === 'false') return false;
    }
    return undefined;
  }

  resendCode(type: string, searchString: string) {
    return this.http
      .put(`${environment.api}/account/resend`, { type: type, searchString: searchString })
      .pipe(map((res: ApiResponse) => res));
  }

  forgotPassword(user: User) {
    return this.http
      .post(`${environment.api}/account/forgot`, user)
      .pipe(map((res: ApiResponse) => res));
  }

  reset(reset: Reset) {
    return this.http
      .put(`${environment.api}/account/reset/${reset.resetCode}`, reset)
      .pipe(map((res: ApiResponse) => res));
  }

  logout() {
    localStorage.removeItem(constants.ls_token);
    sessionStorage.removeItem(constants.ls_token);
    this._store.dispatch(
      new PatchApp({
        id: null,
        testMode: false,
        authenticated: false,
        user: null,
        role: 'END_USER',
        subscription: null,
      })
    );
    this.router.navigate(['login'], { queryParams: { case: 'signed-out' } });
  }

  sso(user: User) {
    return this.http
      .post(`${environment.root}/external/sso`, user)
      .toPromise()
      .then((res: ApiResponse) => {
        localStorage.setItem(constants.ls_token, res.payload);
        return res;
      });
  }

  generateApiKeys() {
    return this.http
      .post(`${environment.root}/external/keys`, {})
      .toPromise()
      .then((res: ApiResponse) => res);
  }

  getApiKeys() {
    return this.http
      .get(`${environment.root}/external/keys`, {})
      .pipe(map((res: ApiResponse) => res));
  }

  updateApiKeys(key) {
    return this.http
      .put(`${environment.root}/external/keys`, key)
      .toPromise()
      .then((res: ApiResponse) => res);
  }

  getUserById(id: string) {
    return this.http
      .get(`${environment.api}/admin/account/${id}`, {})
      .pipe(map((res: ApiResponse) => res));
  }

  getUsers(
    skip: number,
    filterText?: string,
    filterMarketing?: boolean,
    filterConfirmed?: boolean
  ): Observable<ApiResponse> {
    let uri = `${environment.api}/admin/accounts?skip=${skip}`;
    if (filterText && filterText !== '') {
      uri = `${uri}&search=${encodeURIComponent(filterText)}`;
    }
    if (filterConfirmed) {
      uri = `${uri}&confirmed=${filterConfirmed}`;
    }
    if (filterMarketing) {
      uri = `${uri}&marketing=${filterMarketing}`;
    }
    return this.http.get(uri).pipe(map((res: ApiResponse) => res));
  }

  getCheckoutById(userId: string, checkoutId: string) {
    return this.http.get<CheckoutPackage>(
      `${environment.subService}/subscriptions/user/${userId}/checkout/${checkoutId}`
    );
  }
}
function next(
  next: any,
  arg1: (res: ApiResponse) => void
): import('rxjs').OperatorFunction<Object, Object> {
  throw new Error('Function not implemented.');
}
