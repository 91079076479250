<section class="container-fluid h-100" id="new">
    <div class="row d-none">
      <div class="col-12 ">
        <h1 class="display-6 ">{{texts.label_new}}</h1>
      </div>
    </div>
    <div class="clearfix mb-5 d-none"></div>
    <app-new-shell></app-new-shell>
  </section>

