import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { User } from '../../../models/user.model';
import { AccountService } from '../../../services/account.service';
import { IState } from '../../../store/reducers';
import { selectApp } from '../../../store/selectors/app.selectors';
import { texts } from './../../../../assets/texts/texts';
import { constants } from '../../../../assets/constants/constants';
import { PatchApp } from '../../../store/actions/app.actions';

@Component({
    selector: 'app-profile-form',
    templateUrl: './profile-form.component.html',
    providers: [AccountService],
    standalone: false
})
export class ProfileFormComponent implements OnInit {
  @Input()
  userId: string;
  user: User;
  profileForm: UntypedFormGroup;
  firstName: UntypedFormControl;
  lastName: UntypedFormControl;
  email: UntypedFormControl;
  optInEmail: UntypedFormControl;
  company: UntypedFormControl;
  jobRole: UntypedFormControl;
  error: string;
  message: string;
  appState$ = this._store.pipe(select(selectApp));
  readonly = false;
  texts = texts;
  jobRoles = constants.job_roles;
  loading = true;
  $destroy = new Subject();

  constructor(private svc: AccountService, public fb: UntypedFormBuilder, private _store: Store<IState>) {}

  ngOnInit() {
    this.createForm();
    if (!this.userId) {
      this.appState$.pipe(take(1)).subscribe((state) => {
        if (state.authenticated && state.user === null) {
          this.svc
            .getSelf()
            .pipe(take(1))
            .subscribe((user) => {
              this.user = user;
              this.populate();
              this._store.dispatch(new PatchApp({ user: user, role: user.role, id: user.id }));
            });
        } else {
          this.user = state.user;
          this.populate();
        }
      });
    }
    if (this.userId) {
      this.svc
        .getUserById(this.userId)
        .pipe(take(1))
        .subscribe((user) => {
          this.user = user.payload;
          this.readonly = true;
          this.populate();
        });
      this.profileForm.disable();
    }
  }

  ngOnDestroy() {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  createForm() {
    this.firstName = this.fb.control('', Validators.required);
    this.lastName = this.fb.control('', Validators.required);
    this.email = this.fb.control('', [Validators.required, Validators.email]);
    this.company = this.fb.control('', Validators.required);
    this.jobRole = this.fb.control('', Validators.required);
    this.optInEmail = this.fb.control(null);
    this.profileForm = this.fb.group({
      profile: this.fb.group({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        company: this.company,
        jobRole: this.jobRole,
      }),
      status: this.fb.group({
        optInEmail: this.optInEmail,
      }),
    });
  }

  populate() {
    this.profileForm.patchValue({
      profile: {
        firstName: this.user.profile.firstName,
        lastName: this.user.profile.lastName,
        email: this.user.profile.email,
        company: this.user.profile.company,
        jobRole: this.user.profile.jobRole,
      },
      status: {
        optInEmail: this.user.status.optInEmail,
      },
    });
    this.loading = false;
  }

  onSubmit(form) {
    this.error = undefined;
    this.message = undefined;
    this.loading = true;
    this.svc
      .update(form.value, this.user.id)
      .then((res) => {
        this.message = res.message;
        this.loading = false;
      })
      .catch((err) => {
        this.error = err.error.message;
        this.loading = false;
      });
  }
}
