<app-spinner *ngIf="loading"></app-spinner>
<section class="container-fluid" *ngIf="!loading" id="hub">
  <div class="row">
    <div class="col-12 ">
      <h1 class="display-3 ">{{texts.header_history}}</h1>
      <p>{{texts.text_scormify_history_1}}</p>
    </div>
  </div>
  <form class="row justify-content-center form-inline">
    <div class="col-3 form-group">
      <label class="me-2">{{texts.label_search}}: </label>
      <input type="text" class="form-control w-75"
             (keyup)="filterChange($event)" [(ngModel)]="filters.search" name="search" [disabled]="processing">
    </div>
    <div class="col-3 form-group">
      <label class="me-2">{{texts.label_type}}: </label>
      <ng-select
                 [items]="types"
                 [multiple]="true"
                 [closeOnSelect]="true"
                 [searchable]="false"
                 bindLabel="name"
                 placeholder="Select types"
                 [(ngModel)]="filters.types" name="type"
                 (change)="filterChange($event)"
                 class="col"
                 [disabled]="processing">
      </ng-select>
    </div>
    <div class="col-3 form-group">
      <label class="me-2">{{texts.label_status}}: </label>
      <ng-select
                 [items]="statuses"
                 [multiple]="true"
                 [closeOnSelect]="true"
                 [searchable]="false"
                 placeholder="Select status"
                 [(ngModel)]="filters.status" name="type"
                 (change)="filterChange($event)"
                 class="col"
                 [disabled]="processing">
        <ng-template ng-label-tmp let-item="item">
          {{ item | titlecase}}
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          {{ item | titlecase}}
        </ng-template>
      </ng-select>
    </div>
    <div class="col-1 form-group d-flex justify-content-center align-self-end">
      <button class="btn btn-primary " [disabled]="processing" (click)="clearSearch()">
        <fa-icon [icon]="faTimes"></fa-icon>
        {{texts.button_clear}}
      </button>
    </div>
  </form>
  <div class="row justify-content-center">
    <div class="col-10">
      <ngx-datatable [rows]="objects" [columns]="columns" class="bootstrap" columnMode="force" rowHeight="auto" [headerHeight]="40">
        <ngx-datatable-column [name]="texts.label_title" prop="descriptors.title"></ngx-datatable-column>
        <ngx-datatable-column [name]="texts.label_type" prop="type.name"></ngx-datatable-column>
        <ngx-datatable-column [name]="texts.label_status">
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span class="badge" [ngClass]="getStatusClass(value)">{{ value | titlecase }}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Created" prop="metadata.createdAt">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{ value | date: 'short' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="">
          <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
            <button [disabled]="objects[rowIndex].republishing" class="btn btn-primary me-2" (click)="dispatch(action, row, rowIndex)" *ngFor="let action of row['$actions'] ">
              <fa-icon *ngIf="objects[rowIndex].republishing" [icon]="faSpinner" [animation]="'spin'"></fa-icon>
              <span *ngIf="!objects[rowIndex].republishing">{{action.text}}</span>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
  <div class="clearfix"></div>
</section>