<footer class="footer py-5 bg-ka-dark-grey">
  <div class="container-fluid">
    <div class="row justify-content-end d-flex">
      <div class="col-lg-4 justify-content-center d-flex">
        <ul class="d-flex flex-row list-unstyled justify-content-center">
          <li>
            <a class="text-muted" href="https://twitter.com/Knowledgeany" target="_blank">
              <fa-icon [icon]="faTwitter" size="lg"></fa-icon>
            </a>
          </li>
          <li class="ms-3">
            <a class="text-muted" href="https://www.facebook.com/KnowledgeAnywhere" target="_blank">
              <fa-icon [icon]="faFacebook" size="lg"></fa-icon>
            </a>
          </li>
          <li class="ms-3">
            <a class="text-muted" href="https://www.linkedin.com/company/knowledge-anywhere/" target="_blank">
              <fa-icon [icon]="faLinkedIn" size="lg"></fa-icon>
            </a>
          </li>
          <li class="ms-3">
            <a class="text-muted" href="https://www.youtube.com/user/KnowledgeAnywhereInc" target="_blank">
              <fa-icon [icon]="faYoutube" size="lg"></fa-icon>
            </a>
          </li>
          <li class="ms-3">
            <a class="text-muted" href="https://www.knowledgeanywhere.com" target="_blank">
              <fa-icon [icon]="faGlobe" size="lg"></fa-icon>
            </a>
          </li>
        </ul>
      </div>
      <div class="col-lg-4 justify-content-center d-flex">
        <ul class="d-flex flex-row list-unstyled justify-content-center">
          <li>
            <a class="btn btn-lg btn-ka-green" href="mailto:support@knowledgeanywhere.com" target="_blank">
              Support
            </a>
          </li>
          <li class="ms-3">
            <a class="btn btn-lg btn-ka-green" href="https://help.knowledgeanywhere.com/scorm-conversion-tool-scormify" target="_blank">
              Help Center
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-4 d-none">
        <div><img src="assets/images/stripe.png" class="img-responsive mt-4" style="height:25px; width: auto;"></div>
      </div>
      <div class="col-8 d-flex justify-content-center">
        <ul class="d-inline-flex flex-column flex-sm-row d-sm-flex list-unstyled text-muted justify-content-center">
          <li>&copy; Knowledge Anywhere {{ year }}</li>
          <li class="ms-3">
            <a class="text-muted" href="https://app.termly.io/document/privacy-policy/012d8e90-b88e-4590-8670-f2f09db947ca" target="_blank">Privacy Policy</a>
          </li>
          <li class="ms-3">
            <a class="text-muted" href="https://app.termly.io/document/terms-of-use-for-saas/b5b8f8fa-b64b-4047-a369-8203100f2436" target="_blank">Terms and Conditions</a>
          </li>
          <li class="ms-3">
            <a class="text-muted" href="https://app.termly.io/document/cookie-policy/65d1a574-3748-4955-9876-ff5b3f85144a" target="_blank">Cookie Policy</a>
          </li>
          <li class="ms-3">
            <a class="text-muted" href="https://app.termly.io/document/disclaimer/7a167f7b-0170-462e-b417-318945f95915" target="_blank">Disclaimer</a>
          </li>
        </ul>
      </div>
    </div>


  </div>
</footer>