<section class="container-fluid" id="login">

  <div class="row">
    <div class="col-sm-12 col-md-7" id="left">
      <div class="container left">
        <div class="row">
          <div class="col">
            <h1>{{texts.header_login}}</h1>
            <br>
            <div class="alert alert-success" *ngIf="confirmed">{{texts.message_login_confirmed}}
            </div>
            <div class="alert alert-success" *ngIf="reset">{{texts.message_login_reset}}</div>
            <p>{{texts.text_login_1}}</p>
            <div class="alert alert-danger" *ngIf="error">{{error}}</div>
            <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
            <div class="alert alert-info" *ngIf="message">{{message}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <form #f="ngForm" (ngSubmit)="onSubmit(f)" novalidate>
              <div class="form-group mb-3">
                <label>{{texts.label_email}}:</label>
                <input name="email" class="form-control" [(ngModel)]="user.profile.email" autofocus autocomplete="email" required>
              </div>
              <div class="form-group mb-3">
                <label>{{texts.label_password}}</label>
                <input type="password" name="password" class="form-control" [(ngModel)]="user.password" autocomplete="current-password" required>
              </div>
              <div class="form-check">
                <input type="checkbox" name="staysignin" class="form-check-input" [(ngModel)]="persistLogin">
                <label class="form-check-label">{{texts.label_stay_signed_in}}</label>
              </div>
              <br>
              <div class="form-group">
                <ul class="list-inline action-buttons">
                  <li class="list-inline-item">
                    <button type="submit" class="btn btn-primary login-button" [disabled]="loading || f.invalid">
                      <span *ngIf="!loading">{{texts.button_login}}</span>
                      <span *ngIf="loading"><fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon></span>
                    </button>
                  </li>
                  <li class="list-inline-item">
                    <button type="button" (click)="forgotPassword()" class="btn btn-outline-primary" [disabled]="loading">{{texts.button_forgot_password}}</button>
                  </li>
                  <li class="list-inline-item">
                    <button type="button" (click)="register()" class="btn btn-outline-primary" [disabled]="loading">{{texts.button_register}}</button>
                  </li>
                  <li class="list-inline-item">
                    <a routerLink="" class="btn btn-outline-primary">Home</a>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5 d-none d-md-flex justify-content-center" id="right">
      <div class="container">
        <div class="mb-5">
          <a routerLink="">
            <img src="../../../assets/images/new_logo_light.png" alt="new-logo-light-image" class="right-logo mx-auto d-block">
          </a>
        </div>
      </div>
    </div>
  </div>
</section>