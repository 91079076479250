<app-spinner *ngIf="loading"></app-spinner>
<section class="container-fluid" *ngIf="!loading" id="hub">
  <div class="row">
    <div class="col-12 ">
      <h1 class="display-3 ">{{object.descriptors?.title}}</h1>
    </div>
  </div>
  <div class="clearfix mb-5"></div>
  <br>
  <div class="row">
    <div class="col-5 col-md-offset-3 border border-light  rounded p-5">
      <div class="row justify-content-center">
        <div class="col-4 label">Type:</div>
        <div class="col-4 field">{{object.type.name}}</div>
      </div>
      <div class="row justify-content-center">
        <div class="col-4 label">Standard:</div>
        <div class="col-4 field">{{getStandard()}}</div>
      </div>
      <div class="row justify-content-center">
        <div class="col-4 label">Created:</div>
        <div class="col-4 field">{{object.metadata.createdAt | date}}</div>
      </div>
      <div class="row justify-content-center" *ngIf="">
        <div class="col-4 label">Created:</div>
        <div class="col-4 field">{{object.metadata.createdAt | date}}</div>
      </div>
    </div>
  </div>
</section>
