import { DOCUMENT } from '@angular/common';
import { inject, Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NgxScrollTopCoreService {
  private readonly scrolledFromTop = signal(false);
  private readonly scrollOffset = signal(0);
  private readonly isBrowser: boolean = typeof window !== 'undefined';

  private readonly document = inject(DOCUMENT);

  public onWindowScroll(mode: string): boolean {
    const position: number =
      this.document.documentElement?.scrollTop || this.document.scrollingElement?.scrollTop;
    switch (mode) {
      case 'classic':
        return this.classicMode(position);
      case 'smart':
        return this.smartMode(position);
    }
  }

  private classicMode(position: number): boolean {
    if (this.isBrowser && position > window.innerHeight) {
      return true;
    } else {
      return false;
    }
  }

  private smartMode(position: number): boolean {
    let show = false;

    if (position === 0) {
      this.scrolledFromTop.set(false);
    }

    if (this.scrolledFromTop() && this.scrollOffset() > position) {
      show = true;
    }

    if (this.isBrowser && position > window.innerHeight * 2) {
      this.scrolledFromTop.set(true);
      this.scrollOffset.set(position);
    }

    return show;
  }

  public scrollToTop(): void {
    if (this.isBrowser) {
      // Scroll to the top
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  }
}