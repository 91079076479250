import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { texts } from '../../../../assets/texts/texts';


@Component({
    selector: 'app-quiz-score-modal',
    templateUrl: './quiz-score-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class QuizScoreModalComponent {
  public numberOfQuestionsToBeAnswered: number;
  public numberOfQuestionsAnsweredCorrectly: number;
  public score: number;
  public passingScore: number;
  public passedQuiz: boolean;
  texts = texts;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    if (this.score < this.passingScore)
      this.passedQuiz = false
    else
      this.passedQuiz = true;
  }

  getNumberOfQuestionsCorrectOfTotalQuestionsLabel() {
    let label = texts.label_number_of_questions_correct_of_total_questions.replace('@@numberOfQuestionsAnsweredCorrectly@@', this.numberOfQuestionsAnsweredCorrectly);

    return label.replace('@@numberOfQuestionsToBeAnswered@@', this.numberOfQuestionsToBeAnswered);
  }

}
