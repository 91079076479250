import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ObjectType } from '../../../../models/object-type.model';
import { Completion, Scormify } from '../../../../models/scormify.model';
import { BaseComponent } from '../../../../shared/base/base.component';
import { texts } from './../../../../../assets/texts/texts';
@Component({
  selector: 'app-completion',
  templateUrl: './completion.component.html',
  standalone: false
})
export class CompletionComponent extends BaseComponent implements OnInit {
  faQC = faQuestionCircle;
  texts = texts;
  completion = new Completion();
  selectedType: ObjectType;

  @Input() type$: BehaviorSubject<ObjectType>;
  @Input() object$: BehaviorSubject<Scormify>;
  @Input() rowIndex: number;
  @Output() update = new EventEmitter<Completion>();

  constructor() {
    super();
  }

  ngOnInit() {
    this.type$.pipe(takeUntil(this.destroy$)).subscribe((type) => {
      this.selectedType = type;
      this.completion.threshold = 80;
      this.completion.disableScrubber = false;
      this.completion.enableBookmarks = true;
      this.completion.completeOnDownload = true;
      this.completion.addOverlayQuiz = false;
      this.update.emit(this.completion);
    });
    this.object$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value.completion) {
        this.completion = value.completion;
      }
    });
  }

  onChange($event) {
    this.update.emit(this.completion);
  }

  onChangeThreshold($event) {
    this.completion.threshold = $event;
    this.update.emit(this.completion);
  }
}
