<section class="container" id="admin-home">
  <div class="row">
    <div class="col-12">
      <app-admin-nav>
      </app-admin-nav>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <router-outlet></router-outlet>
    </div>
  </div>
</section>
