<section id="register">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-7 order-md-1 order-sm-2" id="left">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1>{{texts.header_register}}</h1>
            <br>
            <div class="login-alt">
              <p>{{texts.text_register_1}}</p>
              <p>
                {{texts.text_register_2}}
                <button class="d-inline align-self-center btn btn-primary" (click)="login()">{{texts.button_login}}</button>
              </p>
            </div>
            <div class="alert alert-danger" *ngIf="error">{{texts.message_register_error}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <form novalidate [formGroup]="registerForm" (ngSubmit)="onSubmit(registerForm)">
              <div formGroupName="profile">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label>{{texts.label_first_name}}:</label>
                      <input type="text" class="form-control" required placeholder="" id="name" formControlName="firstName" autofocus>
                      <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                        <div class="text-danger" *ngIf="firstName.errors.required">
                          {{texts.validation_first_name}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label>{{texts.label_last_name}}:</label>
                      <input type="text" class="form-control" required placeholder="" id="last" formControlName="lastName">
                      <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                        <div class="text-danger" *ngIf="lastName.errors.required">
                          {{texts.validation_last_name}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label>{{texts.label_email}}:</label>
                      <input type="email" class="form-control" required placeholder="" name="email" id="email" formControlName="email" autocomplete="email">
                      <div *ngIf="email.invalid && (email.dirty || email.touched)">
                        <div class="text-danger" *ngIf="email.errors.required">
                          {{texts.validation_email_required}}
                        </div>
                        <div class="text-danger" *ngIf="email.errors.email && !email.errors.required">
                          {{texts.validation_email_format}}
                        </div>
                        <div class="text-danger" *ngIf="email.errors.emailAvailable === false">
                          {{texts.validation_email_exists}}
                        </div>
                      </div>
                      <small id="emailHelp" class="form-text text-muted">{{texts.text_register_email}}</small>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label>{{texts.label_company}}:</label>
                      <input type="text" class="form-control" required placeholder="" id="company" formControlName="company">
                      <div *ngIf="company.invalid && (company.dirty || company.touched)">
                        <div class="text-danger" *ngIf="company.errors.required">
                          {{texts.validation_company}}
                        </div>
                      </div>
                      <small class="form-text text-muted">{{texts.form_required}}</small>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label>{{texts.label_job_role}}:</label>
                      <!--<input type="text" class="form-control" placeholder="" id="jobrole" formControlName="jobRole"> -->
                      <select class="form-control" id="jobrole" formControlName="jobRole" #jobrole required>
                        <option value="" disabled>{{texts.form_placeholder}}</option>
                        <option *ngFor="let role of jobRoles" [ngValue]="role">{{role}}</option>
                      </select>
                      <small class="form-text text-muted">{{texts.form_required}}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>{{texts.label_password}}</label>
                    <input type="password" class="form-control" name="new_password" required placeholder="" id="new_password" formControlName="password" autocomplete="new-password">
                    <div *ngIf="password.invalid && (password.dirty || password.touched)">
                      <div class="text-danger" *ngIf="password.errors.required">
                        {{texts.label_password_required}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div formGroupName="status">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" placeholder="" id="optin" formControlName="optInEmail">
                      <label class="form-check-label">{{texts.label_opt_in_email}}</label>
                    </div>
                  </div>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-sm-12">
                  <ul class="list-inline register-actions">
                    <li class="list-inline-item">
                      <button [disabled]="registerForm.invalid || loading" class="btn btn-primary register-button" type="submit">
                        <span *ngIf="!loading">{{texts.button_register}}</span>
                        <span *ngIf="loading"><fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon></span>
                      </button>
                    </li>
                    <li class="list-inline-item">
                      <button type="reset" (click)="gotoMain()" class="btn btn-outline-primary" [disabled]="loading">{{texts.button_cancel}}</button>
                    </li>
                    <li class="list-inline-item">
                      <a routerLink="" class="btn btn-outline-primary">Home</a>
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5  d-none d-md-block justify-content-center" id="right">
      <div class="container">
        <div class="mb-5">
          <a routerLink="">
            <img src="../../../assets/images/new_logo_light.png" alt="new-logo-image" class="left-logo mx-auto d-block">
          </a>
        </div>
        <div class="right-text d-flex justify-content-center flex-column">
          <h2 class="text-center text-light">Get started for free. No credit card required.</h2>
          <h4 class="text-center text-light mt-5"> Already have an account?</h4>
          <button class="d-inline align-self-center btn btn-ka-green" (click)="login()">Login</button>
        </div>
      </div>
    </div>
  </div>
</section>