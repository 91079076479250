import { Action } from '@ngrx/store';
import { IApp } from '../state/app.state';

export enum AppActionTypes {
  PatchApp = '[App] Patch App'
}

export class PatchApp implements Action {
  readonly type = AppActionTypes.PatchApp;
  constructor(public payload: IApp) {}
}

export type AppActions = PatchApp;
