import { Directive } from '@angular/core';
import {
  NG_VALIDATORS,
  Validator,
  AbstractControl,
} from '@angular/forms';
import { ThresholdValidator } from './../functions/threshold-validator.fn';

@Directive({
    selector: '[appThreshold]',
    providers: [{ provide: NG_VALIDATORS, useExisting: ThresholdDirective, multi: true }],
    standalone: false
})
export class ThresholdDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    return control.value ? ThresholdValidator()(control) : null;
  }
}
