const texts = {};

texts.action_delete_style = 'Delete Style';

texts.confirm_delete_style = 'Are you sure you wish to delete @@STYLENAME@@?';

texts.message_style_deleted = 'Style Deleted';
texts.message_success = 'Success';
texts.message_scormify_accepted =
  'Accepted for processing.  Link will be emailed to you when processing is completed.   Alternatively, check the history page.';

texts.error_invalid_type = 'Type not specified or invalid';
texts.error_invalid_style = 'Style not specified or invalid';
texts.error_course_generate = 'Error generating course.';
texts.error_processing_request = 'There was an error processing the request';
texts.error_style_404 = 'Style not found';
texts.error_generic = 'Unknown error';
texts.error_unauth = 'Unauthorized';

texts.status_done = 'done';
texts.status_processing = 'processing';
texts.status_error = 'error';

texts.question_type_multiple_choice='Multiple Choice';
texts.question_type_true_false ='True/False';
texts.question_type_tip ='Tip';
texts.question_type_multiple_select = 'Multiple Select';

texts.email_from = 'Scormify By Knowledge Anywhere';
texts.email_subject_conversion = 'Scormify - Your Course is Ready!';
texts.email_subject_error = 'Scormify Encountered an Error';
texts.email_subject_welcome = 'Welcome to Scormify';
texts.email_subject_reset = 'Scormify Password Reset';

module.exports = texts;
