import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from '../../models/user.model';
import { AccountService } from '../../services/account.service';
import { IState } from '../../store/reducers';
import { selectApp } from '../../store/selectors/app.selectors';
import { IApp } from './../../store/state/app.state';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: false
})
export class HomeComponent implements OnInit, OnDestroy {
  homeContent = [];
  user: User;
  contentSub: Subscription;
  sub: Subscription;
  appState$ = this._store.pipe(select(selectApp));
  state: IApp;
  loading = true;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private svc: AccountService, private _store: Store<IState>) {}

  ngOnInit() {
    this.appState$.pipe(takeUntil(this.destroyed$)).subscribe((state) => {
      this.state = state;
      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
