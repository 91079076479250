import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { PricingPlan, ScormifySubscription } from './../../../models/scormify-subscription';
import { SubscriptionSelection } from './../../../models/subscription-selection';
import { plans, IDisplayPlan } from './../../../shared/constants/plans';
import { SubscriptionRenewal } from '../../../models/user-subscription';

@Component({
    selector: 'app-sub-card',
    templateUrl: './sub-card.component.html',
    standalone: false
})
export class SubCardComponent implements OnInit {
  @Input() sub: ScormifySubscription;
  @Input() showAnnualPricing: boolean;
  @Output() selection = new EventEmitter<SubscriptionSelection>();

  displayData: IDisplayPlan = null;
  price: PricingPlan;
  hasPricingPlan = true;
  faCheck = faCheck;

  constructor() {}

  ngOnInit(): void {
    this.setup();
  }

  setup() {
    this.displayData = plans.find(
      (x) =>
        x.shortName === this.sub.shortName ||
        (this.sub.shortName === 'FREE' && x.shortName === 'FREE TRIAL')
    );
    if (this.sub.pricingPlans !== null) {
      this.price = this.getPrice(this.sub.pricingPlans);
    } else {
      this.hasPricingPlan = false;
    }
  }

  getPrice(prices: PricingPlan[]) {
    return prices.find((p) => {
      if (this.showAnnualPricing) {
        return p.renewal == SubscriptionRenewal.yearly;
      } else {
        return p.renewal == SubscriptionRenewal.monthly;
      }
    });
  }

  setSelected(sub: ScormifySubscription, price: PricingPlan) {
    const selected: SubscriptionSelection = {
      scormifySubscription: sub,
      pricingPlan: price,
    };
    this.selection.emit(selected);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.showAnnualPricing) {
      this.showAnnualPricing = changes.showAnnualPricing.currentValue;
      this.setup();
    }
  }
}
