import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { ReplaySubject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { constants } from '../../../assets/constants/constants';
import { ApiResponse } from '../../models/api-response.model';
import { AccountService } from '../../services/account.service';
import { LinksService } from '../../services/links.service';
import { EmailAvailableDirective } from '../../shared/directives/email-available.directive';
import { texts } from './../../../assets/texts/texts';

declare var gtag: any;

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    providers: [AccountService],
    standalone: false
})
export class RegisterComponent implements OnInit, OnDestroy {
  registerForm: UntypedFormGroup;
  firstName: UntypedFormControl;
  lastName: UntypedFormControl;
  email: UntypedFormControl;
  password: UntypedFormControl;
  optInEmail: UntypedFormControl;
  company: UntypedFormControl;
  jobRole: UntypedFormControl;
  error: ApiResponse;
  jobRoles = constants.job_roles;
  texts = texts;
  registerSub: Subscription;
  loading = false;
  faSpinner = faSpinner;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private fb: UntypedFormBuilder,
    private svc: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private links: LinksService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit(): void {
    gtag('event', 'conversion', { 'send_to': 'AW-701873332/VltTCKj7pr8DELT51s4C' });
    this.setupRegisterForm();
  }

  setupRegisterForm() {
    this.firstName = this.fb.control('', Validators.required);
    this.lastName = this.fb.control('', Validators.required);
    this.email = this.fb.control(
      '',
      [Validators.required, Validators.email],
      EmailAvailableDirective.createValidator(this.svc)
    );
    this.company = this.fb.control('', Validators.required);
    this.jobRole = this.fb.control('', Validators.required);
    this.password = this.fb.control('', [Validators.required]);
    this.optInEmail = this.fb.control(true);

    this.registerForm = this.fb.group({
      password: this.password,
      profile: this.fb.group({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        company: this.company,
        jobRole: this.jobRole,
      }),
      status: this.fb.group({
        optInEmail: this.optInEmail,
      }),
    });
  }

  login() {
    return this.router.navigate(['login']);
  }

  gotoMain() {
    return this.router.navigate(['/']);
  }

  onSubmit({ value, valid }) {
    this.loading = true;
    this.recaptchaV3Service
      .execute('register')
      .toPromise()
      .then((token) => this.register(token, value));
  }

  register(token: string, value: any) {
    value.recaptchaToken = token;
    this.svc
      .register(value, token)
      .pipe(take(1))
      .subscribe(
        (res) => {
          gtag("event", "sign_up", {
            method: "Email"
          });
          this.links.resolve(res);
        },
        (err) => {
          this.loading = false;
          this.error = err.message;
        }
      );
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
