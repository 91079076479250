import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { texts } from '../../../../../assets/texts/texts';
import { Scormify, Vimeo } from '../../../../models/scormify.model';
import { BaseComponent } from '../../../../shared/base/base.component';
import { IState } from '../../../../store/reducers';
import { selectApp } from '../../../../store/selectors/app.selectors';

@Component({
    selector: 'app-vimeo',
    templateUrl: './vimeo.component.html',
    standalone: false
})
export class VimeoComponent extends BaseComponent implements OnInit {
  video = new Vimeo();
  appState$ = this._store.pipe(select(selectApp));
  texts = texts;
  faQC = faQuestionCircle;

  @ViewChild('vimeourl', { static: true }) input: NgModel;

  @Input() success$: Subject<boolean>;
  @Input() object$: BehaviorSubject<Scormify>;
  @Output() update = new EventEmitter<Vimeo>();

  constructor(private _store: Store<IState>) {
    super();
  }

  ngOnInit() {
    this.appState$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      this.onChanges();
      this.success$.pipe(takeUntil(this.destroy$)).subscribe((v) => {
        this.video = new Vimeo();
        this.input.control.markAsPristine();
        this.input.control.updateValueAndValidity();
      });
      this.object$.pipe(takeUntil(this.destroy$)).subscribe(val => {
        if(val.vimeo) {
          this.video = val.vimeo;
        }
      });
    });
  }

  onChanges() {
    this.update.emit(this.video);
  }
}
