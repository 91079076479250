import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IState } from '../store/reducers';
import { selectApp } from '../store/selectors/app.selectors';
import { IApp } from '../store/state/app.state';
import { AccountService } from '../services/account.service';

@Injectable()
export class AdminGuard  {
  appState$: Observable<IApp> = this._store.pipe(select(selectApp));
  constructor(public router: Router, private _store: Store<IState>, private svc: AccountService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.svc.getSelf().pipe(
      map((payload) => {
        if (!payload?.isAdmin) {
          this.router.navigate(['unauthorized']);
          return false;
        }
        return true;
      })
    );
  }
}
