<div class="row justify-content-center" *ngIf="locked">
  <div class="alert alert-danger">
    {{texts.message_locked_sub}}
  </div>
</div>
<div class="row justify-content-center" *ngIf="loading">
  <app-spinner></app-spinner>
</div>
<div class="row d-none d-lg-flex" *ngIf="!locked && !loading" kaz>
  <div class="col-sm-12 col-lg-3 ps-5">
    <app-step [step]="1" [label]="texts.step_one" [active]="getIsStepActive(1)"></app-step>
    <div class="mb-4"></div>
  </div>
  <div class="col-sm-12 col-lg-6 ps-3 pe-3">
    <app-step [step]="2" [label]="texts.step_two" [active]="getIsStepActive(2)"></app-step>
    <div class="mb-4"></div>
  </div>
  <div class="col-sm-12 col-lg-3">
    <app-step [step]="3" [label]="texts.step_three" [active]="getIsStepActive(3)"></app-step>
    <div class="mb-4"></div>
  </div>
</div>
<div class="row" *ngIf="(!locked && !loading) || locked && getShowSuccessBackground()">
  <div class="col-sm-12 col-lg-3 align-items-center collapse" [ngbCollapse]="isCollapsed">
    <ul class="nav flex-column d-flex new-select-type d-grid align-content-center">
      <li class="nav-item clickable d-flex" *ngFor="let t of types"
          [ngClass]="{'text-muted': !isTypeActive(t), 'text-ka-turquoise': isTypeActive(t)  }"
          (click)="setType(t)" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
        <fa-icon [icon]="faPlus" class="me-3 mt-2"></fa-icon>
        <h3 [ngClass]="{'text-muted': !isTypeActive(t), 'text-ka-turquoise': isTypeActive(t) || !getShowStepTwo() }" class="d-inline">{{t.name}}</h3>
      </li>
      <li class="nav-item clickable d-flex text-muted" (click)="gotoBulk()" *ngIf="allowBulk">
        <fa-icon [icon]="faPlus" class="me-3 mt-2"></fa-icon>
        <h3 class="text-muted">{{texts.label_bulk}}</h3>
      </li>
    </ul>
  </div>
  <div [ngClass]="{'col-lg-9':isCollapsed}" class="col-sm-12 col-lg-6 border-left border-right" [@slideInOut] *ngIf="getShowStepTwo()">
    <div class="redirect-label" *ngIf="selectedType">
      <strong><label>{{texts.label_type}}: {{selectedType?.name}} </label><a class="cursor-pointer" (click)="changeType()"> [{{texts.label_change}}] </a></strong>
    </div>
    <app-create-shell [type$]="type$" [success$]="success$" [createError]="error" (create)="onCreate($event)" (createAndNext)="onCreateAndNext($event)">
    </app-create-shell>
  </div>
  <div class="col" *ngIf="getShowStepThree()" [@slideInOut]>
    <div *ngIf="processing">
      <div class="loader"></div>
      <p class="text-muted text-center">{{texts.message_processing}}</p>
    </div>
    <div *ngIf="getShowSuccessBackground()">
      <div class="alert alert-success text-center">
        <p>{{result.message}}</p>
        <p>{{getConversionsRemainingMessage()}}</p>
      </div>
      <ul class="nav justify-content-center" *ngIf="getShowSuccess()">
        <li *ngFor="let link of result.links">
          <a href="{{link.href}}" class="btn btn-lg btn-primary" download (click)="onDownload()">{{link.text}}</a>
        </li>
      </ul>
    </div>
    <div class="col" *ngIf="getShowError()">
      <div class="alert alert-danger">
        <p>{{texts.error_processing_request}}</p>
      </div>
    </div>
    <div class="col" *ngIf="getShowOutOfConversions()">
      <div class="alert alert-danger">
        <p>{{getShowOutOfConversionsMessage()}}</p>
      </div>
    </div>
  </div>
</div>