import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AccountService } from '../../services/account.service';
import { IState } from '../../store/reducers';
import { selectApp } from '../../store/selectors/app.selectors';
import { IApp } from '../../store/state/app.state';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    standalone: false
})
export class NotFoundComponent implements OnInit {
  public isAuthenticated: Boolean;
  appState$: Observable<IApp> = this._store.pipe(select(selectApp));
  constructor(
    private location: Location,
    private router: Router,
    private acctSvc: AccountService,
    private _store: Store<IState>
  ) {}

  ngOnInit() {
    this.appState$.subscribe(state => {
      this.isAuthenticated = state.authenticated;
    });
  }

  goBack() {
    this.location.back();
  }
}
