<form [formGroup]="formmultipleselect">
  <div>
    <textarea placeholder="{{texts.place_holder_enter_multiple_select_question}}" rows="3" cols="37"
      class="form-control" formControlName="questionText" [(ngModel)]="this.question.text"></textarea>
    <div><small class="form-text qsmall">Required.</small></div>

  </div>
  <table class="table">

    <tbody>
      <tr>
        <td class="head1" scope="row">Choice</td>


        <td class="head2">Correct</td>
      </tr>

    </tbody>
  </table>


  <ul style="list-style-type:none">
    <li *ngFor="let answer of this.question.answers; trackBy:customTrackBy;let i = index">




      <table class="table">

        <tbody>
          <tr>
            <td class="deleteico" scope="row">{{this.alphabet[i]}}.</td>



            <td scope="row"><input type="text" class="form-control multinpt" formControlName="{{i.toString()}}"
                [(ngModel)]="answer.text"></td>



            <td class="deleteico">
              <fa-icon [icon]="faBin" (click)="onDeleteAnswerClick(answer.letter,i)" class="fa-sm"
                style="cursor: pointer;color:red"></fa-icon>
            </td>

            <td> <input type="checkbox" class="checkcorrect" value="{{i.toString()}}" formControlName="mycheckbox{{i}}"
                [(ngModel)]="answer.correctAnswer" (click)="checkValidCheckBoxes($event)"></td>
          </tr>

        </tbody>
      </table>

      <div><small class="form-text qsmall">{{texts.form_required}}</small></div>




    </li>
  </ul>
  <div><small class="form-text">{{texts.form_correct_answer_required}}</small></div>
  <br>
  <div>
    <button class="btn btn-primary qadd" type="submit"
      (click)="onAddNewOptionClick()">{{texts.button_add_new_option}}</button>
  </div>

  <br>
  <br>
  <div *ngIf="this.question.id == null" class="text-center">
    <button class="btn btn-primary" type="submit"
      [disabled]="!this.formmultipleselect.valid || !this.anyCheckboxChecked"
      (click)="onInsertClick()">{{texts.button_save}}</button>
    <button class="btn btn-primary" (click)="onCancelClick()">Cancel</button>
  </div>
  <div *ngIf="this.question.id != null" class="text-center">
    <button class="btn btn-primary" type="submit"
      [disabled]="!this.formmultipleselect.valid || !this.anyCheckboxChecked"
      (click)="onSaveClick()">{{texts.button_save}}</button>
    <button class="btn btn-primary" (click)="onDeleteClick()">{{texts.button_delete}}</button>

    <button class="btn btn-primary" (click)="onCancelClick()">{{texts.button_cancel}}</button>
  </div>
</form>