import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { texts } from '../../../../../assets/texts/texts';
import { MuseResponse } from '../../../../models/muse-response';
import { ObjectType, TypeSize } from '../../../../models/object-type.model';
import { NewVideo, Scormify } from '../../../../models/scormify.model';
import { BaseComponent } from '../../../../shared/base/base.component';
import { IState } from '../../../../store/reducers';
import { selectApp } from '../../../../store/selectors/app.selectors';
import { IApp } from '../../../../store/state/app.state';
import { constants } from './../../../../../assets/constants/constants';
import {
  aspectRatio,
  Ratio,
  ratios,
} from '../../../../models/scormify.model';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';



@Component({
    selector: 'app-new-video',
    templateUrl: './new-video.component.html',
    standalone: false
})
export class NewVideoComponent extends BaseComponent implements OnInit {
  video = new NewVideo();
  appState$ = this._store.pipe(select(selectApp));
  state: IApp;
  uploading = false;
  constants = constants;
  texts = texts;
  ratios = ratios;
  ratio: Ratio;
  computedRatio: Ratio;
  constrain = true;
  faQC = faQuestionCircle;
  @Input() success$: Subject<boolean>;
  @Input() type$: BehaviorSubject<ObjectType>;
  @Input() object$: BehaviorSubject<Scormify>;

  @Output() update = new EventEmitter<NewVideo>();
  @Output() remove = new EventEmitter<boolean>();

  constructor(private _store: Store<IState>) {
    super();
  }

  ngOnInit() {
    this.video.file = new MuseResponse();
    this.onAspectChange(ratios[0].label);
  }

  uploadSuccess($event: MuseResponse) {
    this.video.file = $event;
    this.update.emit(this.video);
  }

  isRemoving($event: boolean) {
    this.remove.emit($event);
  }

  onChanges() {
    this.update.emit(this.video);
  }

  onForceSizeChange() {
    this.onChanges();
  }

  onConstrain($event: any) {
    console.log($event);
  }

  onSetHeight() {
    if (this.constrain) {
      const width = (this.video.videoSize.height / this.ratio.height) * this.ratio.width;
      this.video.videoSize.width = width;
    } else {
      // set custom ratio
      this.ratio = aspectRatio(this.video.videoSize.width, this.video.videoSize.height);
    }
    this.onChanges();
  }
  
  onSetWidth() {
    if (this.constrain) {
      const height = (this.video.videoSize.width / this.ratio.width) * this.ratio.height;
      this.video.videoSize.height = height;
    } else {
      this.ratio = aspectRatio(this.video.videoSize.width, this.video.videoSize.height);
    }
    this.onChanges();
  }

  onAspectChange(label: string) {
    this.ratio = ratios.find((x) => x.label === label);
    this.video.videoSize.height = this.ratio.defaultHeight;
    this.video.videoSize.width = this.ratio.defaultWidth;
    this.video.aspectRatio = this.ratio.label;
    this.onChanges();
  }

}
