import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { Scormify } from '../../../../models/scormify.model';
import { SlugifyPipe } from '../../../../pipes/slugify.pipe';
import { Scormifyv1Service } from '../../../../services/scormifyv1.service';
import { IState } from '../../../../store/reducers';
import { BaseScormifyComponent } from '../../base-scormify-component';
import { ObjectType } from './../../../../models/object-type.model';
import { StylesService } from './../../../../services/styles.service';

@Component({
  selector: 'app-create-shell',
  templateUrl: './create-shell.component.html',
  providers: [SlugifyPipe],
  standalone: false
})
export class CreateShellComponent extends BaseScormifyComponent implements OnInit, OnDestroy {
  @Input() type$: BehaviorSubject<ObjectType>;
  @Input() success$: Subject<boolean>;
  @Input() object$: BehaviorSubject<Scormify> = new BehaviorSubject<Scormify>(new Scormify());
  @Input() createError: any; //error if creation fails
  @Output() create = new EventEmitter<Scormify>();
  @Output() createAndNext = new EventEmitter<boolean>();

  faQC = faQuestionCircle;
  selectedType: ObjectType;
  typesLoaded = false;
  showQuizes: boolean = false;
  addQuiz: boolean = false;
  createAndNextVisible: boolean = false;
  isRemoving: boolean = false;

  constructor(
    stylesService: StylesService,
    fb: FormBuilder,
    slugifyPipe: SlugifyPipe,
    _store: Store<IState>,
    svc: Scormifyv1Service
  ) {
    super(svc, slugifyPipe, fb, stylesService, _store);
  }

  /**
   * There is a lot going on here
   * step 1) Create the create form with minimal data
   * step 2) Get data needed to complete the rest of the form.  Such as locales and styles.
   * step 3) use that setup data to populate default values.
   * step 4) create the controls for the object type and delete the rest if they exist.
   * step 5) If a new type is set in the parent - reset stuff.
   * step 6) If we have a succesful download - reset stuff and notify the uploader component to reset itself.
   *
   */

  ngOnInit() {
    this.watchSuccess();
    this.setState();
  }

  setState() {
    this.setData()
      .pipe(switchMap(() => this.appState$))
      .pipe(
        filter((state) => !!state.subscription),
        takeUntil(this.destroyed$)
      )
      .subscribe((state) => {
        this.state = state;
        this.watchType();
      });
  }

  watchSuccess() {
    this.success$
      .pipe(
        takeUntil(this.destroyed$),
        filter(() => !!this.createForm)
      )
      .subscribe(() => {
        this.loading = true;
        this.createForm.reset();
        this.addQuiz = false;
        this.setFormByType(this.createForm, this.selectedType, true);
      });
  }

  watchCreateForm() {
    this.createForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((form) => {
      this.object$.next(form);
    });
  }

  watchType() {
    this.type$
      .pipe(
        first(),
        filter((t) => !!t)
      )
      .subscribe((type) => {
        this.selectedType = type;
        if (this.selectedType.key == 'MUSE') this.createAndNextVisible = true;

        this.setupCreateForm();
        this.onType(this.createForm, type, true);
      });
  }

  get f() {
    return this.createForm.controls;
  }

  setupCreateForm() {
    this.createForm = this.createFormGroup();
    this.setFormByType(this.createForm, this.selectedType, true);
    this.setWindowSize(this.createForm, this.selectedType);
    this.watchCreateForm();
  }

  getForceWindowSize() {
    if (this.createForm) {
      return this.createForm.get('forceWindowSize').value;
    }
  }

  onSubmit({ value, valid }) {
    if (valid) {
      this.create.emit(value);
      this.loading = true;
    }
  }

  onPublish($event) {
    const formValue: Scormify = this.createForm.value;
    formValue.quiz = $event;
    this.create.emit(formValue);
    this.showQuizes = false;
    this.loading = true;
  }

  onCreateAndNext(event: any): void {
    this.addQuiz = true;
    this.showQuizes = true;
    this.createAndNext.emit(true);
  }

  getIsInValid() {
    return this.createForm.invalid;
  }

  onRemove($event: boolean) {
    this.isRemoving = $event;
    if (this.selectedType.key === 'MUSE') {
      this.onRemoveMuse();
    } else if (this.selectedType.key === 'PPT') {
      this.onRemovePowerPoint(this.createForm);
    } else if (this.selectedType.key === 'PDF') {
      this.onRemovePDF(this.createForm);
    }
  }
}
