import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faBan, faBars, faCheck } from '@fortawesome/free-solid-svg-icons';
import { AppService } from './../../services/app.service';
import { plans } from './../../shared/constants/plans';
import { features } from './../../shared/constants/features';
import { resources } from './../../shared/constants/resources';
import { conversions } from './../../shared/constants/conversions';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    standalone: false
})
export class MainComponent implements OnInit {
  plansData = plans;
  features = features;
  resources = resources;
  conversions = conversions;
  faCheck = faCheck;
  faBan = faBan;
  isCollapsed = true;
  faBars = faBars;
  constructor(private router: Router, private appService: AppService) {}

  ngOnInit() {}

  scrollTo(target: string) {
    document.querySelector(target).scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.isCollapsed = true;
  }

  gotoRegister() {
    this.router.navigate(['register']);
  }

  gotoLogin() {
    this.router.navigate(['login']);
  }

  gotoResource(r) {
    window.open(r.href);
  }
}
