import { ScormifyResponse } from './scormify-response';

export class MuseResponse implements ScormifyResponse {
    fid: string;
    svid: string;
    filename: string;
    title: string;
    description: string;
    url: string;
    duration: number;
    width: number;
    height: number
    size: number;
    tcreated: number;
    visibility: string;
    ingesting: boolean;
}