import { Injectable } from '@angular/core';

import { StylesService } from '../services/styles.service';

// https://stackoverflow.com/questions/35922071/warn-user-of-unsaved-changes-before-leaving-page
// NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
// when navigating away from your angular app, the browser will show a generic warning message
// see http://stackoverflow.com/a/42207299/7307355

// https://stackoverflow.com/questions/43811368/popup-modal-dialog-from-route-guard
// stackblitz.com/github/LMFinney/angular-type-partial-ngbmodal/tree/8b61d7aaa4551883bcac56f17c31eb827b6874f5?file=src%2Fapp%2Fmodals%2Fmodal.service.ts

export class DeactivateDecision {
  id: string;
  cleanup: boolean;
  immediate: boolean;
  discard: boolean;
}

export interface ComponentCanDeactivate {
  canDeactivate: () => DeactivateDecision;
}
@Injectable()
export class ConfirmNavigationGuard  {
  constructor(private styleSvc: StylesService) { }
  async canDeactivate(component: ComponentCanDeactivate) {
    const decision: DeactivateDecision = component.canDeactivate();
    if (decision.immediate) {
      await this.styleSvc.deleteStyle(decision.id).toPromise().then();
      return true;
    }
    if (decision.cleanup) {
      const result = await this.styleSvc.confirmNavigation(decision.id);
      if (result) {
        const deleteResult = await this.styleSvc.deleteStyle(decision.id).toPromise().then(ok => true);
        return true;
      }
      return result;
    }
    if (decision.discard) {
      const result = await this.styleSvc.confirmDiscard();
      return result;
    } else {
      return true;
    }
  }
}
