import { AbstractControl, ValidatorFn } from '@angular/forms';
import { regex } from '../constants/regex';

export function mediaValidatorFn(type: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value || control.value === '') {
      return null;
    }
    let invalid = false;
    switch (type.toLowerCase()) {
      case 'mp4':
        invalid = !regex.mp4UrlRgx.test(control.value);
        break;
      case 'webm':
        invalid = !regex.webmUrlRgx.test(control.value);
        break;
      case 'hls':
        invalid = !regex.hlsUrlRgx.test(control.value);
        break;
      case 'poster':
        invalid = !regex.posterUrlRgx.test(control.value);
        break;
      case 'youtube':
        invalid = !regex.youtubeUrlRgx.test(control.value);
        break;
      case 'vimeo':
        invalid = !regex.vimeoUrlRgx.test(control.value);
        break;
    }
    return invalid ? { mediaValidator: { value: control.value } } : null;
  };
}
