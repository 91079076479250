<div>
  <div class="modal-header">
    <h4 class="modal-title"></h4>
  </div>
  <div class="modal-body">
    <p>{{ question.text }}</p>
    <div *ngIf="question.questionType === QuestionType.multipleChoice">
      <div class="form-check" *ngFor="let answer of question.answers">
        <input [value]="answer.letter" (change)="onSelected(answer)" type="radio" class="form-check-input"
          name="multiplechoice" />
        <label class="form-check-label">{{ answer.text }}</label>
      </div>
    </div>
    <div *ngIf="question.questionType === QuestionType.multipleSelect">
      <div class="form-check" *ngFor="let answer of question.answers">
        <input [value]="answer.letter" (change)="onSelected(answer)" type="checkbox" class="form-check-input"
          [id]="answer.letter" />
        <label class="form-check-label">{{ answer.text }}</label>
      </div>
    </div>
    <div *ngIf="question.questionType === QuestionType.trueFalse">
      <div class="form-check" *ngFor="let answer of question.answers">
        <input [value]="answer.letter" (change)="onSelected(answer)" type="radio" class="form-check-input"
          name="multiplechoice" />
        <label class="form-check-label">{{ answer.text }}</label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="onCancelClick()">
      {{ texts.button_cancel }}
    </button>
    <button type="button" class="btn btn-primary" (click)="onConfirmClick()">
      {{ texts.button_submit }}
    </button>
  </div>
</div>