import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { delay, map, retryWhen, take } from 'rxjs/operators';
import { User } from '../../../models/user.model';
import { AccountService } from '../../../services/account.service';
import { IState } from '../../../store/reducers';
import { selectApp } from '../../../store/selectors/app.selectors';
import { IApp } from '../../../store/state/app.state';
import { texts } from '../../../../assets/texts/texts';

@Component({
    selector: 'app-password-input',
    templateUrl: './password-input.component.html',
    standalone: false
})
export class PasswordInputComponent implements OnInit {
  @Input()
  label = texts.label_password;

  @Input()
  standalone = false;

  @Output()
  passwordEv = new EventEmitter();

  message: String;
  error: String;
  texts = texts;
  appState$ = this._store.pipe(select(selectApp));
  state: IApp;
  loading: boolean = false;
  email: string;
  password: string;
  constructor(
    private fb: UntypedFormBuilder,
    private svc: AccountService,
    private _store: Store<IState>
  ) { }

  ngOnInit() {
    this.appState$
      .pipe(
        take(1),
        map((s) => {
          if (!s.subscription) {
            throw new Error(); // Will be caught by `map` and reemitted as an error notification.
          }
          return s;
        }),
        retryWhen((errors) => errors.pipe(take(2), delay(1000)))
      )
      .subscribe((state) => {
        this.state = state;
        this.setup();
      });
  }

  setup() {
    this.email = this.state.user.profile.email;
  }
  onChange($event) {
    this.passwordEv.emit(this.password);
  }

  onSubmit(f) {
    this.message = undefined;
    this.error = undefined;
    this.loading = true;
    if (!this.standalone) {
      return;
    }
    this.svc
      .update({ password: this.password } as User, this.state.id)
      .then((res) => {
        this.message = res.message;
        this.loading = false;
      })
      .catch((err) => {
        this.error = err.message;
        this.loading = false;
      });
  }
}
