import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { texts } from '../../../assets/texts/texts';
import { ComponentCanDeactivate, DeactivateDecision } from '../../guards/confirm-navigation.guard';
import { Link } from '../../models/api-response.model';
import { Style } from '../../models/style.model';
import { ModalService } from '../../services/modal.service';
import { ApiResponse } from './../../models/api-response.model';
import { LinksService } from './../../services/links.service';
import { StylesService } from './../../services/styles.service';
import { IconDefinition } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-style-editor',
    templateUrl: './style-editor.component.html',
    styleUrls: ['./style-editor.component.scss'],
    standalone: false
})
export class StyleEditorComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  style: Style;
  id: string;
  originalStyle: Style;
  defaultSub = new Subscription();
  styleSub = new Subscription();
  paramSub: Subscription;
  modalSub: Subscription;
  showResetConfirm: boolean;
  showDeleteConfirm: boolean;
  autoSave: boolean;
  message: string;
  error: string;
  hasChanges = false;
  hasLogo = false;
  isSaved = false;
  loading = false;
  texts = texts;
  files: NgxFileDropEntry[];
  fileTooBig: boolean;
  fileSize: number;

  jsConfig = {
    lineNumbers: true,
    language: 'javascript',
    theme: 'vs-dark',
    readOnly: true,
  };
  scssConfig = {
    lineNumbers: true,
    language: 'scss',
    theme: 'vs-dark',
    readOnly: true,
  };
  htmlConfig = {
    lineNumbers: true,
    language: 'html',
    theme: 'vs-dark',
    readOnly: true,
  };
  constructor(
    private svc: StylesService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private linksSvc: LinksService,
    private modalSvc: ModalService
  ) {
    this.files = [];
  }

  @HostListener('window:beforeunload')
  canDeactivate(): DeactivateDecision {
    const toGuard = {
      id: this.style._id,
      cleanup: this.hasChanges && !this.isSaved && (!this.style.name || this.style.name === ''),
      immediate: !this.hasChanges && (!this.style.name || this.style.name === ''),
      discard: this.hasChanges && !this.isSaved,
    };
    return toGuard;
  }

  getIcon(link: Link): IconDefinition {
    return this.linksSvc.getIcon(link);
  }

  hasChangesEv(event) {
    this.isSaved = false;
    this.hasChanges = true;
  }

  canSave(): boolean {
    return (this.style.name || this.style.name !== '') && this.hasChanges;
  }

  getStyle() {
    this.loading = true;
    this.styleSub = this.svc
      .getStyle(this.id)
      .pipe(take(1))
      .subscribe((style) => {
        this.originalStyle = { ...style };
        this.setStyle();
      });
  }

  buttonAction(link: Link) {
    if (link.requireConfirm) {
      this.modalSub = this.modalSvc
        .confirm(link.confirmText)
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            (this.linksSvc.handleRel(link) as Observable<ApiResponse>).pipe(take(1)).subscribe();
          }
        });
    } else {
      (this.linksSvc.handleRel(link) as Observable<ApiResponse>).pipe(take(1)).subscribe();
    }
  }

  scrollTo(target: string) {
    document.querySelector(target).scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  save(autosave: boolean = false) {
    this.loading = true;
    this.error = undefined;
    const payload = { ...this.style };
    delete payload.body.logo;
    this.svc
      .saveStyle(payload)
      .toPromise()
      .then((res) => {
        this.isSaved = true;
        this.getStyle();
        this.hasChanges = false;
        this.error = undefined;
        this.toastr.success(res.message);
        this.loading = false;
      })
      .catch((err) => {
        this.error = err.error.message;
        this.hasChanges = false;
        this.loading = false;
      });
  }

  confirmReset() {
    this.showResetConfirm = true;
  }

  confirmDelete() {
    this.showDeleteConfirm = true;
  }

  delete() {
    this.svc
      .deleteStyle(this.style._id)
      .toPromise()
      .then((res) => this.router.navigate(['styles']))
      .catch((err) => {
        this.toastr.error('Error deleting style');
        this.router.navigate(['styles']);
      });
  }

  handleLogoUpload($event) {
    this.getStyle();
  }

  dropped(files: NgxFileDropEntry[]) {

    this.fileTooBig = false;
    this.files = files;
    for (const droppedFile of files) {

      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          if (file.size > 1048576) {
            this.fileTooBig = true;
            this.fileSize = file.size / 1024;
            return;
          }

          const formData = new FormData()
          formData.append('logo', file, droppedFile.relativePath)

          this.svc.sendLogoFile(this.id, formData).subscribe(result => {
            this.getStyle();
          });

        });
      } else {
        // It was a directory; not cool.
      }
    }

  }


  removeLogo() {
    this.svc
      .removeLogo(this.id)
      .pipe(take(1))
      .subscribe((x) => this.getStyle());
  }

  deleteCancel() {
    this.showResetConfirm = false;
  }

  setStyle() {
    this.loading = true;
    this.style = { ...this.originalStyle };
    this.htmlConfig.readOnly = this.style.readonly;
    this.jsConfig.readOnly = this.style.readonly;
    this.scssConfig.readOnly = this.style.readonly;
    this.loading = false;
    this.hasChanges = false;
  }

  reset() {
    this.getStyle();
    this.showResetConfirm = false;
  }

  resetCancel() {
    this.showResetConfirm = false;
  }

  ngOnInit() {
    this.loading = true;
    this.paramSub = this.route.params.subscribe((params) => {
      this.id = params.id ? params.id : 'new';
      if (params.id) {
        this.id = params.id;
        this.getStyle();
      } else {
        this.id = 'new';
        this.svc
          .createStyle()
          .toPromise()
          .then((res) => {
            this.id = res.action.param;
            this.getStyle();
            this.linksSvc.resolve(res);
          });
      }
    });
  }

  goBack() {
    this.router.navigate(['styles']);
  }

  ngOnDestroy() {
    if (this.modalSub) {
      this.modalSub.unsubscribe();
    }
    this.paramSub.unsubscribe();
    this.defaultSub.unsubscribe();
    this.styleSub.unsubscribe();
  }
}
