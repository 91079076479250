import { Link } from './api-response.model';
import { Metadata } from './metadata.model';

export class Profile {
  email?: string;
  firstName?: string;
  lastName?: string;
  company?: string;
  jobRole?: string;
  fullName?: string;
  initials?: string;
  avatar?: string;
}

export class Status {
  confirmed?: boolean;
  optInEmail?: boolean;
  eula?: boolean;
}

export class Provider {
  id?: string;
  token?: string;
  type?: string;
}

export class Providers {
  sso?: Provider = new Provider();
  facebook?: Provider = new Provider();
  github?: Provider = new Provider();
  linkedin?: Provider = new Provider();
  google?: Provider = new Provider();
}

class UserMetadata extends Metadata {
  lastLogin?: string;
  logins?: number;
}

export class User {
  id?: string;
  _id?: string;
  profile?: Profile = new Profile();
  status?: Status = new Status();
  defaults?: [string];
  role?: string;
  password?: string;
  providers?: Providers = new Providers();
  isAdmin?: boolean;
  $resource?: string;
  $adminLinks?: Array<Link>;
  $adminActions?: Array<Link>;
  $links?: Array<Link>;
  $actions?: Array<Link>;
  metadata?: UserMetadata = new UserMetadata();
  recaptchaToken?: string;
  subscription?: any;
  entitlements?: Array<string>;
  $flags?: FeatureFlags;
  $limits: Limits;
}

export interface FeatureFlags {
  ALLOW_BULK: boolean;
  ALLOW_BULK_IMPORT: boolean;
  ALLOW_VIDEO_UPLOAD: boolean;
}

export interface Limits {
  videoUploadSize: ByteSize;
  docUploadSizeFree: ByteSize;
  docUploadSizePro: ByteSize;
}

export enum Entitlements {
  BulkCreate = 'bulk-create'
}

export interface ByteSize {
  value: string;
  unit: string;
  long: string;
}
