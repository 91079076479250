<div>
    <div class="modal-header">
        <h4 class="modal-title">{{title}}</h4>
    </div>
    <div class="modal-body">
        <p>{{message}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary"
            (click)="activeModal.close(false)">{{texts.button_ok}}</button>
    </div>
</div>