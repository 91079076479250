import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Scormify } from '../../../../models/scormify.model';
import { ApiResponse } from './../../../../models/api-response.model';
import { Scormifyv1Service } from './../../../../services/scormifyv1.service';

@Component({
    selector: 'app-object-detail',
    templateUrl: './object-detail.component.html',
    standalone: false
})
export class ObjectDetailComponent implements OnInit {
  paramSub: Subscription;
  id: string;
  loading = true;
  object: Scormify;
  constructor(private route: ActivatedRoute, private svc: Scormifyv1Service) { }

  ngOnInit() {
    this.paramSub = this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getObject();
    });
  }

  getObject() {
    this.loading = true;
    this.svc.get(this.id).subscribe((res: ApiResponse) => {
      this.object = res.payload;
      this.loading = false;
    });
  }

  getStandard() {
    return 'SCORM 1.2';
  }

}
