import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  faCloud,
  faHome,
  faPalette,
  faPlus,
  faSignOutAlt,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Link } from '../../models/api-response.model';
import { AccountService } from '../../services/account.service';
import { PatchApp } from '../../store/actions/app.actions';
import { IState } from '../../store/reducers';
import { selectApp } from '../../store/selectors/app.selectors';
import { WindowRef } from '../../window';
import { constants } from './../../../assets/constants/constants';
import { texts } from './../../../assets/texts/texts';
import { IApp } from '../../store/state/app.state';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [AccountService],
    standalone: false
})

export class HeaderComponent implements OnInit, OnDestroy {
  texts = texts;
  constants = constants;
  faPalette = faPalette;
  faPlus = faPlus;
  faHome = faHome;
  faCloud = faCloud;
  faUser = faUser;
  faSignOutAlt = faSignOutAlt;
  title = environment.title;
  isCollapsed = true;
  appState$ = this._store.pipe(select(selectApp));
  _authenticatedTemp = false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  navItems: Link[] = [
    {
      rel: 'nav-home',
      params: ['home'],
      text: 'Home',
      icon: faHome,
      tooltip: 'Go home',
      active: false,
    },
    /*{
      rel: 'nav-new',
      params: ['app', 'new'],
      text: 'New Course',
      icon: faPlus,
      tooltip: 'Create a new SCORM course',
      active: false,
      disabled: true,
    }, */
    {
      rel: 'nav-hub',
      params: ['app', 'hub'],
      text: 'History',
      icon: faCloud,
      tooltip: 'View and download previous courses',
      active: false,
    },
    {
      rel: 'nav-styles',
      params: ['styles', 'hub'],
      text: 'Manage Styles',
      icon: faPalette,
      tooltip: 'Create a style to align courses with your branding',
      active: false,
      mobileHidden: true,
    },
    {
      rel: 'nav-styles',
      params: ['profile'],
      text: 'Profile',
      icon: faUser,
      tooltip: 'View your profile',
      active: false,
      mobileOnly: true,
    },
    {
      rel: 'nav-styles',
      params: ['logout'],
      text: 'Logout',
      icon: faSignOutAlt,
      tooltip: 'Sign out of Scormify',
      active: false,
      mobileOnly: true,
    },
  ];
  routeObserver: any;
  state: IApp;
  homepage: string;

  constructor(
    private svc: AccountService,
    private router: Router,
    private _store: Store<IState>,
    private route: ActivatedRoute,
    private window: WindowRef
  ) {}

  ngOnInit() {
    this.appState$.pipe(takeUntil(this.destroyed$)).subscribe((state) => {
      this.state = state;
      if (state.authenticated && state.user === null) {
        this.svc.getSelf().subscribe((user) => {
          this._store.dispatch(new PatchApp({ user: user, role: user.role, id: user.id }));
        });
      }
      if (!state.authenticated && this._authenticatedTemp) {
        this.router.navigate(['login'], { queryParams: { case: 'expired-token' } });
      }
      this._authenticatedTemp = state.authenticated;
      if (this.navItems.some((item) => !item.active)) {
        this.setInitialActive();
      }

      this.listenForRouteChange();
      this.setTestMode();
      this.homepage = this.window.nativeWindow.document.location.origin;
    });
  }

  hasProfilePicture() {
    return this.state?.user?.profile?.avatar && this.state?.user?.profile?.avatar !== 'false'; // data bug :(
  }

  getInitials() {
    return this.state.user?.profile?.initials;
  }

  getAvatar() {
    if (this.state.user?.profile?.avatar) {
      return this.state.user?.profile?.avatar;
    }
    return null;
  }

  getAvatarCss() {
    return { 'background-image': `url('${this.getAvatar()}')` };
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._store.dispatch(
      new PatchApp({
        id: null,
        testMode: false,
        authenticated: false,
        user: null,
        role: 'END_USER',
        subscription: null,
      })
    );
  }

  toggleTestMode($event) {
    localStorage.setItem(constants.ls_test_mode, $event.currentTarget.checked);
    this._store.dispatch(new PatchApp({ testMode: $event.currentTarget.checked }));
  }

  gotoProfile() {
    this.router.navigate(['profile']);
  }

  gotoAdmin() {
    this.router.navigate(['admin']);
  }

  go(item: Link) {
    this.router.navigate(item.params);
  }

  logout() {
    this.svc.logout();
  }

  getNavItemUrl(item: Link) {
    return `/${item.params.join('/')}`;
  }

  setInitialActive() {
    const url = this.router.url;
    this.navItems = this.navItems.map((item) => {
      item.active = false;
      if (this.getNavItemUrl(item) === url) {
        item.active = true;
      }
      return item;
    });
  }

  listenForRouteChange() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.navItems = this.navItems.map((item) => {
          item.active = false;
          if (this.getNavItemUrl(item) === event.url) {
            item.active = true;
          }
          return item;
        });
      });
  }

  setTestMode() {
    if (localStorage.getItem(constants.ls_test_mode)) {
      this._store.dispatch(
        new PatchApp({ testMode: /true/i.test(localStorage.getItem(constants.ls_test_mode)) })
      );
    }
  }
}
