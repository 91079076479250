import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { texts } from '../../../../assets/texts/texts';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ConfirmModalComponent {
  title: string;
  prompt: string;
  texts = texts;

  constructor(public activeModal: NgbActiveModal) {}
}
