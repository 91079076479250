<div class="form-group">
  <label>{{texts.label_vimeo_url}}
    <span [ngbTooltip]="texts.tooltip_vimeo_url">
      <fa-icon [icon]="faQC"></fa-icon>
    </span>
  </label>
  <input id="vimeourl" class="form-control" [(ngModel)]="video.url" appCloudMediaValidator="vimeo"
         placeholder="Example: https://player.vimeo.com/video/123456789?h=123a4567cb"
         [ngModelOptions]="{updateOn: 'change'}" (ngModelChange)="onChanges()" name="vimeourl" #vimeourl="ngModel">
  <small class="form-text"
         [ngClass]="{'text-danger': (vimeourl.invalid  && (vimeourl.dirty || vimeourl.touched) && vimeourl?.errors?.required), 'text-muted': !!vimeourl.invalid}">
    {{texts.form_required}}
  </small>
  <div><a target="_blank" href="https://help.knowledgeanywhere.com/scormify-vimeo-privacy-settings">If your video is not public, please double check your privacy settings in Vimeo and ensure your video can be embedded. </a></div>
  <div *ngIf="vimeourl.invalid  && (vimeourl.dirty || vimeourl.touched)" class="text-danger">
    <small class="form-text" *ngIf="vimeourl?.errors?.mediaValidator">{{texts.validation_vimeo_url}}</small>
  </div>

</div>