<div class="form-group">
  <label>{{texts.label_youtube_url}}
    <span [ngbTooltip]="texts.tooltip_youtube_url">
      <fa-icon [icon]="faQC"></fa-icon>
    </span>
  </label>
  <input id="youtubeurl" class="form-control" [(ngModel)]="video.url" appCloudMediaValidator="youtube"
  placeholder="Example: https://www.youtube.com/watch?v=Baur2Ypgd60"
    [ngModelOptions]="{updateOn: 'change'}" (ngModelChange)="onChanges()" name="youtubeurl" #youtubeurl="ngModel">
  <small class="form-text"
    [ngClass]="{'text-danger': (youtubeurl.invalid  && (youtubeurl.dirty || youtubeurl.touched) && youtubeurl?.errors?.required), 'text-muted': !!youtubeurl.invalid}">
    {{texts.form_required}}
  </small>
  <div *ngIf="youtubeurl.invalid  && (youtubeurl.dirty || youtubeurl.touched)" class="text-danger">
    <small class="form-text" *ngIf="youtubeurl?.errors?.mediaValidator">
      {{texts.validation_youtube_url}}
    </small>
  </div>

</div>
