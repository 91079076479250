
import { Component, HostListener, inject, input, signal } from '@angular/core';
import { NgxScrollTopCoreService } from './ngx-scroll-top-core-service.service';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-scrolltop',
  imports: [NgClass],
  templateUrl: './scrolltop.component.html',
  styleUrl: './scrolltop.component.scss',
  standalone: true
})
export class ScrolltopComponent {
  public backgroundColor = input<string>();
  public position = input<string>('right');
  public size = input<number>();
  public mode = input<string>('classic');
  public theme = input<string>('gray');

  public show = signal(false);

  private readonly core = inject(NgxScrollTopCoreService);

  @HostListener('window:scroll')
  public onWindowScroll(): void {
    const show = this.core.onWindowScroll(this.mode());

    // Performance boost. Only update the state if it has changed.
    if (this.show() !== show) {
      this.show.set(show);
    }
  }

  public scrollToTop(): void {
    this.core.scrollToTop();
  }

}
