<nav class="navbar navbar-expand-lg navbar-dark bg-ka-grey-blue static-top">
  <div class="container-fluid">
    <a class="navbar-brand" href="https://www.knowledgeanywhere.com">
      <img src="../../../assets/images/ka_logo_light.png" class="d-none d-sm-inline-flex" alt="Knowledge Anywhere">
    </a>
    <a [href]="homepage"><img src="../../../assets/images/new_logo_light.png" class="ms-4 mb-3" alt="Scormify" /></a>
    <button class="navbar-toggler collapsed" type="button" (click)="isCollapsed = !isCollapsed"
            aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse collapse" id="navbarResponsive" [ngbCollapse]="isCollapsed">
      <ul class="navbar-nav ms-auto" *ngIf="!(appState$ | async).authenticated">
        <li class="nav-item">
          <a class="nav-link" routerLink="/register">{{texts.button_register}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/login">{{texts.button_login}}</a>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto topnav-right-menu" *ngIf="(appState$ | async).authenticated">
        <li class="nav-item me-5 manage-subscription-container">
          <app-manage-subscription-button class="mt-1 d-block"></app-manage-subscription-button>
        </li>
        <li *ngFor="let item of navItems" class="nav-item" [ngClass]="{'active-nav-item': item.active, 'd-flex d-lg-none': item.mobileOnly, 'mobile-hidden': item.mobileHidden }">
          <div (click)="go(item)" class="nav-link mb-1 menu-link" [ngbTooltip]="item.tooltip">
            <fa-icon [icon]="item.icon" size="2x" class="text-md-center nav-item-icon"></fa-icon>
            <span class="link-label nav-item-text" [ngClass]="{'active-nav-label': item.active}">{{item.text}}</span>
          </div>
        </li>
        <li class="nav-item  d-none d-lg-flex" ngbDropdown>
          <div class="profile-icon">
            <button class="btn btn-outline-light profile-icon text-light" id="ddprofile" ngbDropdownToggle [ngStyle]="getAvatarCss()">
              <span *ngIf="!hasProfilePicture()">{{getInitials()}}</span>
            </button>
            <div ngbDropdownMenu aria-labelledby="ddprofilemenu" class="animate-dropdown slide-menu">
              <button class="dropdown-item" *ngIf="(appState$ | async).user?.isAdmin"
                      (click)="gotoAdmin()">Admin</button>
              <button class="dropdown-item" (click)="gotoProfile()">Profile</button>
              <button class="dropdown-item" (click)="logout()">Logout</button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>