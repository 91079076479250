<div *ngIf="selectedType">
  <div class="form-group" *ngIf="!hasFile">
    <label [for]="'file-select_' + nonce" class="file-select-label">
      <span class="btn btn-primary upload-button" [ngClass]="{'disabled': uploading}">
        <span *ngIf="!uploading"><fa-icon [icon]="faPlus"></fa-icon>&nbsp;{{ getLabel() }}</span>
        <span *ngIf="uploading"><fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon></span>
      </span>
      <small class="text-muted form-text" *ngIf="sizeLimit">Limit: {{ sizeLimit.value }}{{sizeLimit.unit }}</small>
      <small class="text-danger form-text" *ngIf="error">{{ error.error?.message }}</small>
    </label>
    <input type="file" [id]="'file-select_' + nonce"
           [accept]="selectedType.accept"
           (change)="handleFileInput($event.target.files)"
           class="d-none">
  </div>
  <div class="form-group" *ngIf="isRemoving && hasFile">
    <button type="button" class="btn btn-primary" (click)="remove()"><fa-icon [icon]="faRemove"></fa-icon>&nbsp;{{ getRemoveLabel() }}</button>
    <br>
    <strong *ngIf="invalid" class="text-danger">Invalid file type detected.</strong>
  </div>
</div>