<div class="step-container container-fluid"  [ngClass]="{'step-active': true}">
    <div class="row align-content-center justify-content-center">
        <div class="col-12 d-flex align-content-center justify-content-center">
            <h1 class="text-center border border-primary rounded-circle step-num"  
            [ngClass]="{'bg-ka-turquoise text-light border-ka-turquoise': active}">
            {{step}}
        </h1>
        </div>
    </div>
    <div class="row align-content-center justify-content-center">
        <div class="col-12 d-flex align-content-center justify-content-center">
            <h1  class="text-center border border-primary rounded step-label p-2" 
            [ngClass]="{'bg-ka-turquoise text-light border-ka-turquoise': active}">
            {{label}}
        </h1>
        </div>
    </div>
</div>

