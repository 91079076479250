import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import { Link } from '../../..//models/api-response.model';
import { AccountService } from '../../..//services/account.service';
import { LinksService } from '../../..//services/links.service';
import { IState } from '../../..//store/reducers';
import { selectApp } from '../../..//store/selectors/app.selectors';
import { IApp } from '../../..//store/state/app.state';
import { texts } from './../../../../assets/texts/texts';
import { take, takeUntil } from 'rxjs/operators';
import { CheckoutItem, CheckoutItemType } from './../../../models/checkout-item';
import { CheckoutPackage, SubscriptionSelection } from './../../../models/subscription-selection';
import { CheckoutRequest } from './../../../models/checkout-request';
import { generateGuid } from './../../../shared/functions/guid-generator.fn';
import { ReplaySubject } from 'rxjs';

declare var gtag: any;

@Component({
    selector: 'app-step-two',
    templateUrl: './step-two.component.html',
    styleUrls: ['./step-two.component.scss'],
    standalone: false
})
export class StepTwoComponent implements OnInit, OnDestroy {
  @Input() selected: SubscriptionSelection;
  @Output() success = new EventEmitter<boolean>();
  @Output() clear = new EventEmitter<void>();

  checkoutItem: CheckoutItem;
  checkoutPackage: CheckoutPackage;
  showPromoCode = false;
  code: string = '';
  loading = true;
  routeParamSub: any;
  appState$ = this._store.pipe(select(selectApp));
  state: IApp;
  texts = texts;
  hasCoupon: boolean;
  couponLoading = false;
  couponNotFound = false;
  faCheck = faCheck;
  faClose = faTimes;
  couponIsForever = false;
  reqCount = 0;
  hasError = false;
  errorMessage = texts.message_generic_error;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private linksSvc: LinksService,
    private router: Router,
    private accountSvc: AccountService,
    private route: ActivatedRoute,
    private _store: Store<IState>
  ) { }

  ngOnInit(): void {
    this.appState$.pipe(takeUntil(this.destroyed$)).subscribe((state) => {
      this.state = state;
      if (state.user != null) {
        this.checkoutItem = new CheckoutItem();
        this.checkoutItem.item = this.selected;
        this.checkoutItem.itemType = CheckoutItemType.subscription;
        this.sendStartAnalyticsEvent();
        this.setInitial();
      }
    });
  }
  onRedirectSuccess(event) { }

  onTokenReceived(event) {
    this.loading = true;
    this.hasError = false;
    const link: Link = this.checkoutPackage.actions.find((x) => x.rel === 'convertCheckout');
    const req = this.getCheckoutRequest(event);
    this.linksSvc
      .httpMethod(link, req)
      .pipe(take(1))
      .subscribe(
        (res) => this.handleSuccess(res),
        (error) => this.handleError(error)
      );
  }

  getCheckoutRequest(event: any) {
    const req: CheckoutRequest = {
      userId: this.state.id,
      token: event.token,
      billingEmail: event.billingEmail,
      billingName: event.billingName,
      checkoutId: this.checkoutPackage.id,
    };
    req.ik = this.makeIdempotencyKey(req);
    return req;
  }

  makeIdempotencyKey(checkoutRequest: CheckoutRequest) {
    return `ik_${checkoutRequest.checkoutId}_${this.reqCount}_${generateGuid()}`;
  }

  onClickPromoCode() {
    this.showPromoCode = true;
  }

  onClickCancelPromoCode() {
    this.showPromoCode = false;
  }

  handleError(error) {
    this.hasError = true;
    this.loading = false;
    if (error.error.message) {
      this.errorMessage = error.error.message;
    }
    this.reqCount++;
  }

  handleSuccess(res) {
    this.loading = false;
    this.sendSuccessAnalyticsEvent()
    this.success.emit(true);
  }

  sendStartAnalyticsEvent() {
    gtag("event", "begin_checkout", {
      value: this.checkoutItem.item.pricingPlan.costDollars,
      currency: 'USD',
      items: [
        {
          item_id: this.checkoutItem.item.pricingPlan.planId,
          item_name: this.checkoutItem.item.pricingPlan.name,
          currency: 'USD',
          index: 0,
          item_brand: "Scormify",
          item_category: "Subscription",
          item_category2: this.checkoutItem.item.pricingPlan.renewal,
          price: this.checkoutItem.item.pricingPlan.costDollars,
          quantity: 1
        }]
    });
  }

  sendSuccessAnalyticsEvent() {
    gtag("event", "purchase", {
      transaction_id: this.checkoutPackage.id,
      value: this.checkoutPackage.totalDollars,
      currency: this.checkoutPackage.currency,
      coupon: this.checkoutPackage.code,
      items: [
        {
          item_id: this.checkoutItem.item.pricingPlan.planId,
          item_name: this.checkoutItem.item.pricingPlan.name,
          coupon: this.checkoutPackage.code,
          currency: this.checkoutPackage.currency,
          index: 0,
          item_brand: "Google",
          item_category: "Subscription",
          item_category2: this.checkoutItem.item.pricingPlan.renewal,
          price: this.checkoutPackage.totalDollars,
          quantity: 1
        }]
    });
  }

  goBack() {
    this.router.navigate(['upgrade']);
    this.clear.emit();
  }

  getNewCheckoutPackage() {
    let link = this.checkoutItem.item.pricingPlan.actions.find((a) => a.rel === 'createCheckout');
    this.linksSvc
      .httpMethod(link)
      .pipe(take(1))
      .subscribe((checkoutPackage) => {
        this.setCheckoutPackage(checkoutPackage as any);
      });
  }

  setInitial() {
    this.routeParamSub = this.route.queryParams.pipe(take(1)).subscribe((params) => {
      if (params.checkout_id) {
        this.getExistingCheckoutPackage(params.checkout_id);
      } else {
        this.getNewCheckoutPackage();
      }
    });
  }

  getExistingCheckoutPackage(checkoutId: string) {
    this.accountSvc
      .getCheckoutById(this.state.id, checkoutId)
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          this.setCheckoutPackage(res);
        },
        (err) => this.getNewCheckoutPackage()
      );
  }

  setCheckoutPackage(item: CheckoutPackage) {
    this.checkoutPackage = item;
    this.setUrlState(item.id);
    if (item.code) {
      this.code = item.code;
      this.hasCoupon = true;
      if (this.checkoutPackage.couponDuration == 'forever') {
        this.couponIsForever = true;
      }
    } else {
      this.code = '';
      this.hasCoupon = false;
    }
    this.loading = false;
  }

  setUrlState(checkoutId: string) {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams: {
        checkout_id: checkoutId,
      },
    });
  }

  applyCoupon() {
    this.couponLoading = true;
    let link: Link = { ...this.checkoutPackage.actions.find((l) => l.rel === 'updateCheckout') };
    link.href += `?pcode=${this.code}`;
    this.linksSvc.httpMethod(link).subscribe(
      (checkoutPackage) => {
        this.couponLoading = false;
        this.hasCoupon = true;
        this.setCheckoutPackage(checkoutPackage as any);
      },
      (err) => {
        this.couponNotFound = true;
        this.couponLoading = false;
      }
    );
  }

  removeCoupon() {
    this.couponLoading = true;
    let link: Link = this.checkoutPackage.actions.find((l) => l.rel === 'removeCheckoutCode');
    this.linksSvc.httpMethod(link).subscribe(
      (checkoutPackage) => {
        this.couponLoading = false;
        this.setCheckoutPackage(checkoutPackage as any);
      },
      (err) => {
        this.couponLoading = false;
      }
    );
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
