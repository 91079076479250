import { Component } from '@angular/core';
import { texts } from '../../../../assets/texts/texts';

@Component({
    selector: 'app-new',
    templateUrl: './new.component.html',
    styleUrls: ['./new.component.scss'],
    standalone: false
})
export class NewComponent {
  texts = texts;
}
