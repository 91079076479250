import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import waitUntil, { WAIT_FOREVER } from 'async-wait-until';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { texts } from '../../../../../assets/texts/texts';
import { Answer, Question, Quiz, Scormify } from '../../../../models/scormify.model';
import { SlugifyPipe } from '../../../../pipes/slugify.pipe';
import { ModalService } from '../../../../services/modal.service';
import { Scormifyv1Service } from '../../../../services/scormifyv1.service';
import { BaseComponent } from '../../../../shared/base/base.component';


@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss'],
    providers: [SlugifyPipe],
    standalone: false
})
export class VideoComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild("my_video") my_video: ElementRef;
  formvideo: UntypedFormGroup;
  texts = texts;
  faQC = faQuestionCircle;

  public playvideo: boolean = true;
  public questionSelected: Question;
  public loading: boolean = false;
  public videoOffset: number = 0;
  public videoDuration: number = 0;
  public currentTime: number = 0;
  public lastQuestionTime: number = 0;
  public rangeOffset: string = '0';
  public videoSafeUrl: SafeUrl;
  public showQuestion: boolean = false;
  public timer: string;
  public questionid: number;
  public isPreview: boolean = false;
  private previewAnsers: Answer[] = [];
  private coveredQuestions: Question[] = [];
  private BASE_MARGIN = 0.05;
  public numberOfQuestionsToBeAnswered: number;
  public numberOfQuestionsAnsweredCorrectly: number;
  public totalNumberOfQuestions: number;
  public score: number;
  public numberOfQuestionsAnsweredIncorrectly: number;
  public currentQuestionNumberWithSameTimeStamp: number;

  @Output() publish = new EventEmitter<Quiz>();
  @Input() success$: Subject<boolean>;
  @Input() object$: BehaviorSubject<Scormify>;
  object: Scormify;

  constructor(private fb: UntypedFormBuilder, private modalService: ModalService, public activeModal: NgbActiveModal, private scormifyService: Scormifyv1Service) {
    super();
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public initialLoad(value) {
    this.videoDuration = value.target.duration;
    this.currentTime = value.target.currentTime;
    let percentage = Math.floor((value.target.currentTime * 1000) / value.target.duration);
    this.rangeOffset = percentage.toString();
  }

  public setCurrentTime(value): void {
    this.videoDuration = value.target.duration;
    this.currentTime = value.target.currentTime;
    let percentage = Math.floor((value.target.currentTime * 1000) / value.target.duration);
    this.rangeOffset = percentage.toString();
    this.handleQuestionPreview();

  }

  public setRam(value) {
    let percentage = Math.floor((value / 1000) * this.videoDuration);
    this.videoOffset = percentage;
  }

  ngOnInit() {
    this.object$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.object = value;
      if (!this.isPreview)
        this.object.quiz = new Quiz();
      this.scormifyService.answerReviewSubject.subscribe((answers: Answer[]) => {
        this.previewAnsers.push(...answers);
      });
      this.formvideo = this.fb.group({
        passingScore: ['', [Validators.required, Validators.max(100)]],
      });
    })
  }

  selectQuestion(index: number) {
    this.showQuestion = true;
    this.questionSelected = this.object.quiz.questions.find(a => a.id == index);
  }

  setPlayButton() {
    this.playvideo = true;
    this.coveredQuestions = [];
    this.lastQuestionTime = 0;
  }

  pad(n: any): string {
    return (n < 10 ? '0' + n : n).toString();
  }

  insertquestion() {
    this.showQuestion = true;
    this.questionSelected = new Question();
    if (this.currentTime === 0) {
      this.questionSelected.timeStamp = Math.round((this.BASE_MARGIN + Number.EPSILON) * 100) / 100;
    } else {
      this.questionSelected.timeStamp = Math.round((this.currentTime + Number.EPSILON) * 100) / 100;
    }
    if (this.questionSelected.timeStamp) {
      const sameTime = this.object.quiz?.questions?.find((x: Question) => x.timeStamp === this.questionSelected.timeStamp);
      if (sameTime) {
        this.currentTime+= 0.1;
        this.questionSelected.timeStamp = Math.round((this.currentTime + Number.EPSILON) * 100) / 100;
      }
    }
  }

  onCloseQuestion() {
    this.showQuestion = false;
  }

  publishClick() {
    if (this.formvideo.valid) {
      this.publish.emit(this.object.quiz);
    }
  }

  play() {
    this.playvideo = false;
  }

  pause() {
    this.playvideo = true;
  }

  ngOnDestroy(): void { }

  private handleQuestionPreview(): Promise<void> {
    if (this.isPreview && this.object.quiz) {
      if (this.object.quiz.questions) {
        const videoTime = (Math.round((this.currentTime + Number.EPSILON) * 100) / 100);

        const findQuestions = this.object.quiz.questions.filter(
          (x: Question) => (videoTime >= x.timeStamp && x.timeStamp >= this.lastQuestionTime)
        );
        this.lastQuestionTime = videoTime;
        if (findQuestions) {
          const currentQuestion: Question = JSON.parse(JSON.stringify(findQuestions[0]));

          const temp: Question = this.coveredQuestions.find((x: Question) => x.id == currentQuestion.id);
          if (temp) {
            return;
          }

          this.currentQuestionNumberWithSameTimeStamp = 1;
          this.my_video.nativeElement.pause();
          this.modalService
            .openQuestion(this.object, currentQuestion)
            .subscribe(async (result: boolean) => {
              if (result == true)
                this.coveredQuestions.push(currentQuestion);

              if (this.currentQuestionNumberWithSameTimeStamp < findQuestions.length)
                this.handleMultipleQuestionsOnSameTimeStamp(findQuestions)
              await waitUntil(() => this.currentQuestionNumberWithSameTimeStamp == findQuestions.length, {
                timeout: WAIT_FOREVER, // === Number.POSITIVE_INFINITY
              });
              this.my_video.nativeElement.play();
            });

        }
      }
    }
  }

  handleMultipleQuestionsOnSameTimeStamp(findQuestions: Question[]) {
    const currentQuestion: Question = JSON.parse(JSON.stringify(findQuestions[this.currentQuestionNumberWithSameTimeStamp]));

    const temp: Question = this.coveredQuestions.find((x: Question) => x.id == currentQuestion.id);
    if (temp) {
      return;
    }

    this.modalService
      .openQuestion(this.object, currentQuestion)
      .subscribe(async (result: boolean) => {
        if (result == true)
          this.coveredQuestions.push(currentQuestion);
        this.currentQuestionNumberWithSameTimeStamp++;

        if (this.currentQuestionNumberWithSameTimeStamp < findQuestions.length)
          this.handleMultipleQuestionsOnSameTimeStamp(findQuestions)
        await waitUntil(() => this.currentQuestionNumberWithSameTimeStamp == findQuestions.length, {
          timeout: WAIT_FOREVER, // === Number.POSITIVE_INFINITY
        });
      });
  }

  preview() {
    this.modalService
      .openQuizPreview(this.object, true)
      .subscribe((result: boolean) => {
      });
  }

  videoEnded() {

    if (this.object.quiz.questions && this.object.quiz.questions.length > 0) {
      this.numberOfQuestionsToBeAnswered = this.object.quiz.questions.filter(q => q.questionType != 4).length;

      if (this.coveredQuestions) {
        this.numberOfQuestionsAnsweredCorrectly = this.coveredQuestions.filter(q => q.answers.every(a => a.correctAnswer == true) && (q.questionType == 1 || q.questionType == 3)).length;

        let multipleSelectQuestions = this.object.quiz.questions.filter(q => q.questionType == 2);


        multipleSelectQuestions.forEach((obj, index) => {
          let coveredSelectQuestion = this.coveredQuestions.find(x => x.id == obj.id);

          let answer: any;

          if (coveredSelectQuestion != null) {

            let allMatched = false;

            let objCorrectAnswers = obj.answers.filter(a => a.correctAnswer == true);

            if (objCorrectAnswers.length == coveredSelectQuestion.answers.length) {
              for (answer of objCorrectAnswers) {
                allMatched = false;


                if (coveredSelectQuestion.answers.find(a => a.letter == answer.letter) != null)
                  allMatched = true;
                else
                  return false;
              }

              if (allMatched)
                this.numberOfQuestionsAnsweredCorrectly++;
            }
          }
        });

      }
      else
        this.numberOfQuestionsAnsweredCorrectly = 0;

      this.numberOfQuestionsAnsweredIncorrectly = this.numberOfQuestionsToBeAnswered - this.numberOfQuestionsAnsweredCorrectly;

      if (this.numberOfQuestionsToBeAnswered > 0)
        this.score = (this.numberOfQuestionsAnsweredCorrectly / this.numberOfQuestionsToBeAnswered) * 100;
      else
        this.score = 100;
      this.coveredQuestions = [];
      this.lastQuestionTime = -1;
      this.modalService
        .openQuizScore(this.numberOfQuestionsToBeAnswered, this.numberOfQuestionsAnsweredCorrectly, this.score, this.object.quiz.passingScore)

    }
  }
}
