export interface IDisplayPlan {
  shortName: string;
  title: string;
  description: string;
  description2?: string;
  items: string[];
  annual?: string;
  annual2?: string;
  monthly?: string;
  highlight?: boolean;
  isFree?: boolean;
}

const free: IDisplayPlan = {
  shortName: 'FREE TRIAL',
  title: 'Trial',
  description: `The free trial gives you a bucket of 5 conversions that don't expire.`,
  items: ['5 conversions total', 'Access to all templates', 'Custom theming'],
  annual: '$0',
  annual2: '0',
  description2:
    'The allotment of 5 conversions is fixed and does not reset at the end of the month',
  highlight: false,
  isFree: true,
};

const pro: IDisplayPlan = {
  shortName: 'PRO',
  title: 'Pro',
  description: `Pro allows for unlimited conversions. Scormify to your heart's content!`,
  items: ['<strong>Unlimited conversions</strong>', 'Access to all templates', 'Custom theming'],
  annual: '$20',
  annual2: '20',
  monthly: '$25',
  highlight: false,
  isFree: false,
};

export const plans = [free,  pro];
