<form [formGroup]="formtruefalse">
  <div>
    <textarea placeholder="{{texts.place_holder_enter_true_false_question}}" rows="3" cols="37"
      formControlName="questionText" [(ngModel)]="this.question.text" class="form-control"></textarea>
    <div><small class="form-text qsmall">Required.</small></div>

  </div>
  <ul style="list-style-type:none">
    <li>
      {{question.answers[0].text}}
      <input type="radio" [value]="question.answers[0].letter" formControlName="correctAnswer"
        [checked]="question.answers[0].correctAnswer" [(ngModel)]="this.correctAnswer">
    </li>
    <li>
      {{question.answers[1].text}}
      <input type="radio" [value]="question.answers[1].letter" formControlName="correctAnswer"
        [checked]="question.answers[1].correctAnswer" [(ngModel)]="this.correctAnswer">
    </li>
  </ul>
  <div><small class="form-text qsmall">{{texts.form_correct_answer_required}}</small></div>
  <br>
  <br>
  <br>
  <div *ngIf="this.question.id == null" class="text-center">
    <button class="btn btn-primary" type="submit" [disabled]="this.formtruefalse.invalid"
      (click)="onInsertClick()">{{texts.button_save}}</button>
    <button class="btn btn-primary" (click)="onCancelClick()">Cancel</button>
  </div>
  <div *ngIf="this.question.id != null" class="text-center">
    <button class="btn btn-primary" type="submit" [disabled]="this.formtruefalse.invalid"
      (click)="onSaveClick()">{{texts.button_save}}</button>
    <button class="btn btn-primary" (click)="onDeleteClick()">{{texts.button_delete}}</button>

    <button class="btn btn-primary" (click)="onCancelClick()">{{texts.button_cancel}}</button>
  </div>
</form>