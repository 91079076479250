<div class="card sub">
  <div class="card-body sub-top">
    <h5 class="card-title">{{displayData?.title}}</h5>
  </div>
  <div class="card-body sub-description pt-4">
    <div *ngIf="hasPricingPlan && price" class="mb-3">
      <h2>{{showAnnualPricing ? displayData.annual : displayData.monthly}} <small>per month</small></h2>
      <h4>Billed {{price.renewal}}</h4>
    </div>
    <div *ngIf="!hasPricingPlan && !price" class="mb-3">
      <h3>$0</h3>
      <h4>&nbsp;</h4>
    </div>
    <p class="card-text text-center" [innerHTML]="displayData?.description"></p>
  </div>
  <ul class="list-group list-group-flush d-flex pricing-tier-list">
    <li class="list-group-item pricing-tier flex-column" *ngFor="let item of displayData.items">
      <div class="row">
        <fa-icon [icon]="faCheck" class="col-1 text-ka-green"></fa-icon>
        <span [innerHTML]="item" class="col"></span>
      </div>
    </li>
  </ul>
  <ul class="list-group list-group-flush d-flex pricing-tier-list" *ngIf="hasPricingPlan && price">
    <li class="list-group-item pricing-tier flex-column">
      <button class="btn btn-primary" (click)="setSelected(sub, price)">Select</button>
    </li>
  </ul>
</div>
