import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { delay, map, retryWhen, take, takeUntil } from 'rxjs/operators';
import { AccountService } from '../../services/account.service';
import { PatchApp } from '../../store/actions/app.actions';
import { IState } from '../../store/reducers';
import { selectApp } from '../../store/selectors/app.selectors';
import { constants } from './../../../assets/constants/constants';
import { texts } from './../../../assets/texts/texts';
import { IApp } from '../../store/state/app.state';

@Component({
    selector: 'app-authenticate',
    templateUrl: './authenticate.component.html',
    standalone: false
})
export class AuthenticateComponent implements OnInit, OnDestroy {
  texts = texts;
  constants = constants;
  appState$ = this._store.pipe(select(selectApp));
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  state: IApp;
  constructor(
    private svc: AccountService,
    private router: Router,
    private _store: Store<IState>,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.svc
      .getIsAuthenticated()
      .pipe(
        take(1),
        map((auth) => {
          if (!auth) {
            throw new Error(); // Will be caught by `map` and reemitted as an error notification.
          }
          return auth;
        }),
        retryWhen((errors) => errors.pipe(take(10), delay(1000)))
      )
      .subscribe((auth) => {
        if (auth) {
          this.svc
            .getSelf()
            .pipe(
              takeUntil(this.destroyed$),
              map((user) => {
                if (!user) {
                  throw new Error(); // Will be caught by `map` and reemitted as an error notification.
                }
                return user;
              }),
              retryWhen((errors) => errors.pipe(take(10), delay(1000)))
            )
            .subscribe((user) => {
              this._store.dispatch(new PatchApp({ user: user, role: user.role, id: user.id }));
              this.svc
                .getUserSubscription(user)
                .pipe(
                  take(1),
                  map((user) => {
                    if (!user) {
                      throw new Error(); // Will be caught by `map` and reemitted as an error notification.
                    }
                    return user;
                  }),
                  retryWhen((errors) => errors.pipe(take(10), delay(1000)))
                )
                .subscribe((s: any) => {
                  this._store.dispatch(new PatchApp({ subscription: s }));
                  this.router.navigate(['home']);
                });
            });
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
