<div class="row">
    <h1>Stripe</h1>
</div>
<div class="row">
    <h2>Plans</h2>
</div>
<table class="mt-3 table">
    <thead>
      <tr>
        <th scope="col">Plan Id</th>
        <th scope="col">Plan Name</th>
        <th scope="col">&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of plans">
        <th scope="row">{{item.plan.id}}</th>
        <td>{{item.plan.name}}</td>
        <td>
            <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Price id</th>
                    <th scope="col">Price Name</th>
                    <th scope="col">Interval</th>
                    <th scope="col">Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let price of item.prices">
                    <th scope="row">{{price.id}}</th>
                    <td>{{price.nickname}}</td>
                    <td>{{price.recurring.interval}}</td>
                    <td>{{price.unit_amount / 100 | currency}}</td>
                  </tr>
                </tbody>
              </table>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="row">
    <h2>Coupons</h2>
</div>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Parent Coupon</th>
        <th scope="col">Code</th>
        <th scope="col">Percent Off</th>
        <th scope="col">Duration</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of coupons.data">
        <th scope="row">{{item.id}}</th>
        <td>{{item.coupon.name}}</td>
        <td>{{item.code}}</td>
        <td>{{item.coupon.percent_off}}</td>
        <td>{{item.coupon.duration}}</td>
      </tr>
    </tbody>
  </table>
