import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Link } from '../../../models/api-response.model';
import { LinksService } from '../../..//services/links.service';
import { environment } from './../../../../environments/environment';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { User } from '../../../models/user.model';
import { AccountService } from '../../../services/account.service';

@Component({
    selector: 'app-user-detail',
    templateUrl: './user-detail.component.html',
    styleUrls: ['./user-detail.component.scss'],
    standalone: false
})
export class AdminUserDetailComponent implements OnInit, OnDestroy {
  user: User;
  id: string;
  error: string;
  paramSub: Subscription;
  loading = true;
  constructor(
    private route: ActivatedRoute,
    private svc: AccountService,
    private router: Router,
    private http: HttpClient,
    private linksSvc: LinksService
  ) {}

  ngOnInit() {
    this.init();
  }
  init() {
    this.paramSub = this.route.params.pipe(take(1)).subscribe((params) => {
      this.id = params['id'];
      this.svc
        .getUserById(this.id)
        .pipe(take(1))
        .subscribe((res) => {
          this.user = res.payload;
          this.adminGetSub();
        });
    });
  }

  adminGetSub() {
    this.http
      .post(`${environment.subService}/protected/subscriptions/user/${this.id}`, {})
      .pipe(take(1))
      .subscribe((res) => this.setSub(res));
  }

  setSub(res) {
    console.log(res);
    this.user.subscription = res;
    this.loading = false;
  }

  compBasic() {
    let link: Link = this.user.subscription.actions.find((x) => x.rel === 'compSubBasic');
    this.linksSvc
      .xhrAction(link, {}, true)
      .toPromise()
      .then((r) => {
        console.log(r);
      })
      .catch((e) => console.log(e));
  }
  compPro() {
    let link: Link = this.user.subscription.actions.find((x) => x.rel === 'compSubPro');
    this.linksSvc
      .xhrAction(link, {}, true)
      .toPromise()
      .then((r) => {
        console.log(r);
      })
      .catch((e) => console.log(e));
  }

  goBack() {
    this.router.navigate(['admin', 'users']);
  }

  ObjectKey = (object, index) => {
    return Object.keys(object)[index];
  };

  isArray = (obj) => {
    //  console.log(Array.isArray(obj), obj);
    return Array.isArray(obj);
  };

  arrayOfObject = (obj) => {
    console.log(Object.keys(obj));
    return Object.keys(obj);
  };

  ngOnDestroy() {
    this.paramSub.unsubscribe();
  }
}
