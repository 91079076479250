<section class="container-fluid" id="manage-styles">
  <div class="row">
    <div class="col-12 ">
      <h1 class="display-3 ">{{texts.header_manage_styles}}</h1>
    </div>
  </div>
  <div class="clearfix mb-5"></div>
  <div class="row col">
    <button class="btn btn-primary" (click)="gotoNewStyle()">
      <fa-icon [icon]="faPlus"></fa-icon> {{texts.button_create_new_style}}
    </button>
  </div>
  <div class="row mt-5">
    <div class="col-8">
      <table class="table">
        <caption class="hide-element">Available styles option for edit/customize</caption>
        <tr>
          <th>{{texts.label_style}}</th>
          <th>{{texts.label_system_style}}</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
        <tr *ngFor="let style of styles">
          <td>{{style.name}}</td>
          <td>
            <span *ngIf="style.isSystem"><fa-icon [icon]="faCheck" class="text-success"></fa-icon></span>
          </td>
          <td>&nbsp;</td>
          <td>
            <ul class="nav">
              <li class="nav-item me-2" *ngIf="!style.isSystem || style.isSystem && style.isCustomizedDefault">
                <button class="btn btn-sm btn-primary" [appGoToLink]="style">
                  <fa-icon [icon]="faEdit"></fa-icon> {{texts.button_edit}}
                </button>
              </li>
              <li class="nav-item me-2" *ngFor="let action of style.$actions">
                <button class="btn btn-sm btn-{{action.css}}"
                  (click)="buttonAction(action)">
                  <fa-icon *ngIf="action.icon" [icon]="getIcon(action)"></fa-icon> {{action.text}}
                </button>
              </li>
                <li class="nav-item me-2">
                  <button class="btn btn-sm btn-primary" ngxClipboard [cbContent]="style.id">
                    <fa-icon [icon]="faCopy"></fa-icon> Copy Style ID
                  </button>
                </li>
            </ul>
          </td>
        </tr>
      </table>
    </div>
  </div>
</section>
