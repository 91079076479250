import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  faAward,
  faExclamation,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import { AppEventType, EventBrokerService } from '../../../services/event-broker.service';
import { PatchApp } from '../../..//store/actions/app.actions';
import { selectApp } from '../../..//store/selectors/app.selectors';
import { texts } from '../../../../assets/texts/texts';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StripeSessionRequest } from './../../../models/stripe-session';
import { LockReason, UserSubscription } from './../../../models/user-subscription';
import { LinksService } from './../../../services/links.service';
import { IState } from './../../../store/reducers/index';
import { IApp } from './../../../store/state/app.state';

@Component({
    selector: 'app-manage-subscription-button',
    templateUrl: './manage-subscription-button.component.html',
    styleUrls: ['./manage-subscription-button.component.scss'],
    standalone: false
})
export class ManageSubscriptionButtonComponent implements OnInit {
  texts = texts;
  appState$ = this._store.pipe(select(selectApp));
  state: IApp;
  userSubscription: UserSubscription;
  show = false;
  window: any;
  gotoManageSubSub: any;
  userSubSub: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  faAward = faAward;
  showComped = false;
  faCancel = faExclamationCircle;
  faLocked = faExclamation;

  constructor(
    private _store: Store<IState>,
    private linkSvc: LinksService,
    private router: Router,
    @Inject(DOCUMENT) private document,
    private eventQueue: EventBrokerService
  ) {}

  ngOnInit() {
    this.window = this.document.defaultView;
    this.listenForChanges();
    this.getUserSub();
  }

  getUserSub() {
    this.appState$.pipe(takeUntil(this.destroyed$)).subscribe((state) => {
      this.state = state;
      if (state.user != null && !this.state.subscription) {
        this.getUserSubscription();
      }
      if (this.state.subscription) {
        this.setUserSubscription();
      }
    });
  }

  onClick() {
    if (this.userSubscription.flags.hasPaidSub) {
      this.gotoManageSub();
    } else {
      this.gotoUpgrade();
    }
  }

  gotoUpgrade() {
    this.router.navigate(['upgrade']);
  }

  gotoManageSub() {
    const link = this.userSubscription.actions.find((l) => l.rel === 'getManagementSession');
    const data: StripeSessionRequest = {
      returnUrl: window.location.href,
    };
    this.linkSvc
      .httpMethod(link, data)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: any) => (this.document.location.href = res.url));
  }

  getButtonText() {
    if (!this.userSubscription.flags.hasPaidSub) {
      return this.texts.button_upgrade_subscription;
    }
    return this.texts.button_manage_subscription;
  }

  listenForChanges() {
    this.eventQueue.on(AppEventType.SubscriptionUpdated).subscribe((event) => {
      this.getUserSubscription();
    });
  }

  getUserSubscription() {
    const link = this.state.user.$links.find((l) => l.rel === 'getSubscription');
    if (link) {
      this.linkSvc
        .httpMethod(link)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((res) => this.setUserSubscription(res));
    }
  }

  setUserSubscription(res?) {
    if (res) {
      this.userSubscription = res;
      this.patchSub();
    } else {
      this.userSubscription = this.state.subscription;
    }
    this.setShowButton();
  }

  setShowButton() {
    if (this.userSubscription) {
      this.show = true;
    }
    if (this.userSubscription.flags.hasCompedSub) {
      this.show = false;
      this.showComped = true;
    }
  }

  patchSub() {
    this._store.dispatch(new PatchApp({ subscription: this.userSubscription }));
  }

  getCancellingText() {
    return this.texts.message_cancelling.replace(
      '@@DATE@@',
      this.userSubscription.cancelOnDate.toLocaleString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    );
  }

  getConversionsText() {
    return this.texts.message_locked_sub;
  }

  getIsLocked() {
    return (
      this.userSubscription.lockStatus.isLocked &&
      this.userSubscription.lockStatus.lockReason === LockReason.usageExceeded
    );
  }

  OnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
