import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { texts } from '../../../../assets/texts/texts';

@Component({
    selector: 'app-ok-modal',
    templateUrl: './ok-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OkModalComponent {
    title: string;
    message: string;
    texts = texts;

    constructor(public activeModal: NgbActiveModal) { }
}
