import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import { ScormifySubscription } from '../../../models/scormify-subscription';
import { SubscriptionSelection } from '../../../models/subscription-selection';
import { SubscriptionPlan, SubscriptionRenewal } from '../../../models/user-subscription';

declare var gtag: any;

@Component({
    selector: 'app-step-one',
    templateUrl: './step-one.component.html',
    styleUrls: ['./step-one.component.scss'],
    standalone: false
})
export class StepOneComponent implements OnInit {
  scormifySubscriptions: Array<ScormifySubscription>;
  showAnnualPricing = true;
  loading = true;
  faCheck = faCheck;
  faBan = faBan;

  @Input() subs: Array<ScormifySubscription>;
  @Output() selection = new EventEmitter<SubscriptionSelection>();

  constructor() {}

  ngOnInit(): void {
    this.scormifySubscriptions = this.subs;
    this.loading = false;
  }

  getPrice() {
    let sub = this.subs.find(s => s.subscriptionPlan === SubscriptionPlan.pro);
    let price = sub.pricingPlans.find((p) => {
      if (this.showAnnualPricing) {
        return p.renewal == SubscriptionRenewal.yearly;
      } else {
        return p.renewal == SubscriptionRenewal.monthly;
      }
    });
    return price.costDollars;
  }

  getRenewal() {
    let sub = this.subs.find(s => s.subscriptionPlan === SubscriptionPlan.pro);
    let price = sub.pricingPlans.find((p) => {
      if (this.showAnnualPricing) {
        return p.renewal == SubscriptionRenewal.yearly;
      } else {
        return p.renewal == SubscriptionRenewal.monthly;
      }
    });
    return price.renewal;
  }

  select() {
    let sub = this.subs.find(s => s.subscriptionPlan === SubscriptionPlan.pro);
    let price = sub.pricingPlans.find((p) => {
      if (this.showAnnualPricing) {
        return p.renewal == SubscriptionRenewal.yearly;
      } else {
        return p.renewal == SubscriptionRenewal.monthly;
      }
    });
    const selected: SubscriptionSelection = {
      scormifySubscription: sub,
      pricingPlan: price
    };
    this.sendSelectAnalyticsEvent(selected);
    this.selection.emit(selected);
  }

  sendSelectAnalyticsEvent(selected: SubscriptionSelection) {
    console.log(selected);
    gtag("event", "add_to_cart", {
      currency: "USD",
      value: selected.pricingPlan.costDollars,
      items: [
        {
          item_id: selected.pricingPlan.planId,
          item_name: selected.pricingPlan.name,
          index: 0,
          item_brand: "Scormify",
          item_category: "Subscription",
          item_category2: selected.pricingPlan.renewal,
          price: selected.pricingPlan.costDollars,
          quantity: 1
        }
      ]
    });
  }

  togglePricing($event) {
    this.showAnnualPricing = !this.showAnnualPricing;
  }
}
