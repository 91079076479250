import { Directive, ElementRef, Renderer2 } from '@angular/core';
import * as confetti from 'canvas-confetti';
@Directive({
    selector: '[appConfetti]',
    standalone: false
})
export class ConfettiDirective {
  constructor(private el: ElementRef, private r: Renderer2) {
    this.makeItRain();
  }

  makeItRain() {
    const canv = this.r.createElement('canvas');
    this.r.addClass(canv, 'confetti-canvas');
    this.r.appendChild(this.el.nativeElement, canv);
    confetti.create(canv)({
      shapes: ['square'],
      particleCount: 100,
      spread: 90,
      disableForReducedMotion: true,
      origin: {
        y: 0.5,
        x: 0.5,
      },
    });
  }
}
