import {
  Component, EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { texts } from '../../../../../assets/texts/texts';
import { Scormify, Youtube } from '../../../../models/scormify.model';
import { BaseComponent } from '../../../../shared/base/base.component';
import { IState } from '../../../../store/reducers';
import { selectApp } from '../../../../store/selectors/app.selectors';

@Component({
    selector: 'app-youtube',
    templateUrl: './youtube.component.html',
    standalone: false
})
export class YoutubeComponent extends BaseComponent implements OnInit {
  video = new Youtube();
  appState$ = this._store.pipe(select(selectApp));
  texts = texts;
  faQC = faQuestionCircle;
  @Input() success$: Subject<boolean>;
  @Input() object$: BehaviorSubject<Scormify>;
  @Output() update = new EventEmitter<Youtube>();

  @ViewChild('youtubeurl', { static: true }) input: NgModel;

  constructor(private _store: Store<IState>) {
    super();
  }

  ngOnInit() {
    this.appState$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      this.onChanges();
      this.success$.pipe(takeUntil(this.destroy$)).subscribe((v) => {
        this.video = new Youtube();
        this.input.control.markAsPristine();
        this.input.control.updateValueAndValidity();
      });
      this.object$.pipe(takeUntil(this.destroy$)).subscribe(val => {
        if(val.youtube) {
          this.video = val.youtube;
        }
      });
    });
  }

  onChanges() {
    this.update.emit(this.video);
  }
}
