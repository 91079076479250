import { animate, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { filter, first, take, takeUntil } from 'rxjs/operators';
import { texts } from '../../../../../assets/texts/texts';
import { ApiResponse } from '../../../../models/api-response.model';
import { ObjectType, ObjectTypeKeys } from '../../../../models/object-type.model';
import { Scormify } from '../../../../models/scormify.model';
import { SubscriptionPlan, UserSubscription } from '../../../../models/user-subscription';
import { AccountService } from '../../../../services/account.service';
import { ModalService } from '../../../../services/modal.service';
import { Scormifyv1Service } from '../../../../services/scormifyv1.service';
import { PatchApp } from '../../../../store/actions/app.actions';
import { IState } from '../../../../store/reducers';
import { selectApp } from '../../../../store/selectors/app.selectors';
import { IApp } from '../../../../store/state/app.state';

declare var gtag: any;

@Component({
  selector: 'app-new-shell',
  templateUrl: './new-shell.component.html',
  styleUrls: ['./new-shell.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', 'z-index': '-50' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))]),
    ]),
  ],
  standalone: false
})
export class NewShellComponent implements OnInit, OnDestroy {
  types: Array<ObjectType>;
  faPlus = faPlus;
  result: ApiResponse;
  error: string;
  processing = false;
  appState$ = this._store.pipe(select(selectApp));
  state: IApp;
  type$: Subject<ObjectType> = new BehaviorSubject<ObjectType>(new ObjectType());
  success$: Subject<boolean> = new Subject();
  url = this.router.url;
  texts = texts;
  locked = false;
  loading = true;
  errorResponse: any;
  userSubscription: UserSubscription;
  selectedType: ObjectType;
  allowBulk = false;
  videoUpload = false;
  isCollapsed = false;

  @Input() standalone: boolean;


  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);


  constructor(
    private svc: Scormifyv1Service,
    private _store: Store<IState>,
    private route: ActivatedRoute,
    private router: Router,
    private acctSvc: AccountService,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    this.appState$
      .pipe(
        filter(state => !!state.user),
        takeUntil(this.destroyed$),
      )
      .subscribe((state) => {
        this.state = state;
        this.userSubscription = state.subscription;
        this.allowBulk = state.user.$flags.ALLOW_BULK;
        this.videoUpload = state.user.$flags.ALLOW_VIDEO_UPLOAD;
        if (state.subscription) {
          this.getTypes();
          this.getStatus();
        }
      });
  }

  getStatus() {
    if (this.userSubscription.lockStatus.isLocked) {
      this.locked = true;
    }
    this.loading = false;
  }

  getUserSubscription() {
    if (this.state.user) {
      return this.acctSvc
        .getUserSubscriptionFromState(this.state)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((sub: any) => {
          this.userSubscription = sub;
          this.getStatus();
        });
    }
  }

  reset(type?: ObjectType) {
    if (!type) {
      type = this.selectedType;
    }
    this.setType(type);
  }

  getTypes() {
    this.svc
      .getTypes()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: ApiResponse) => {
        this.types = res.payload;
        if (!this.state.user.$flags.ALLOW_VIDEO_UPLOAD) {
          this.types = this.types.filter(type => type.key !== ObjectTypeKeys.Muse);
        }
        this.setInitialType();
      });
  }

  setType(type: ObjectType) {
    if (type.key == ObjectTypeKeys.Muse && this.state.subscription.plan == SubscriptionPlan.free) {
      this.modalService.confirm(texts.label_upgrade_plan_txt, texts.label_upgrade_plan)
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.router.navigateByUrl('/upgrade');
          }
        });
      return;
    }
    this.isCollapsed = !this.isCollapsed;
    if (!this.processing) {
      if (!this.url.endsWith(type.slug)) {
        this.router.navigate(['app', 'new', type.slug]);
      }
      this.selectedType = type;
      this.type$.next(this.selectedType);
    }
  }

  setInitialType() {
    this.route.paramMap.pipe(takeUntil(this.destroyed$)).subscribe((params: ParamMap) => {
      if (params.get('type') !== null) {
        const type: ObjectType = this.types.find((x) => x.slug === params.get('type'));
        this.setType(type);
        this.isCollapsed = true;
      }
    });
  }

  gotoBulk() {
    if (this.state.subscription.plan == SubscriptionPlan.free) {
      this.modalService.confirm(texts.label_upgrade_plan_txt, texts.label_upgrade_plan)
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.router.navigateByUrl('/upgrade');
          }
        });
      return;
    }
    this.router.navigate(['app', 'bulk']);
  }

  onCreate(object: Scormify) {
    this.processing = true;
    this.error = undefined;
    this.result = undefined;

    window.scrollTo(0, 0);

    this.svc
      .create(object)
      .pipe(first())
      .subscribe({
        next: (res) => {
          this.processing = false;
          this.result = res;
          this.sendConversionAnalyticsEvent(object);
          this.reset(this.selectedType);
          this.success$.next(true);
          this.setStatusFromResponse(this.result);
          localStorage.setItem('style-id', object.style);
        },
        error: (err: HttpErrorResponse) => {
          this.processing = false;
          this.error = err.error.message;
          this.errorResponse = err.error as any;
        }
      });
  }

  onCreateAndNext($event) {
    this.result = undefined;
  }

  setStatusFromResponse(result: ApiResponse) {
    if (result.lockStatus.isLocked) {
      this.locked = true;
    }
    this.patchSub(result);
  }

  patchSub(result) {
    this._store.dispatch(new PatchApp({ subscription: result.sub }));
  }

  onDownload() {
    this.reset();
    gtag('event', 'download');
    this.setType(this.selectedType);
  }

  getIsStepActive(step: number) {
    switch (step) {
      case 1:
        return true;
      case 2:
        return this.getShowStepTwo();
      case 3:
        return this.getShowStepThree();
    }
  }

  getShowStepTwo() {
    return !!this.selectedType?.key;
  }


  getShowStepThree(): boolean {
    return this.processing || !!this.result || !!this.error;
  }

  getShowSuccess() {
    return !this.processing && this.result?.links;
  }

  getShowSuccessBackground() {
    return !this.processing && this.result;
  }

  getShowError() {
    return !this.processing && this.error && this.error !== 'NO_CONVERSIONS';
  }

  getShowOutOfConversions() {
    return !this.processing && this.error === 'NO_CONVERSIONS';
  }

  getShowOutOfConversionsMessage() {
    return texts.message_locked_sub;
  }

  getConversionsRemainingMessage() {
    if (this.userSubscription.plan == SubscriptionPlan.pro || this.userSubscription.plan == SubscriptionPlan.basic) {
      return '';
    } else {
      return texts.message_conversions_remaining.replace('@@CONVERSIONS@@', this.result.remaining > 0 ? this.result.remaining : 0);
    }
  }

  isTypeActive(type: ObjectType) {
    return this.selectedType && this.selectedType.key === type.key;
  }

  changeType() {
    this.modalService.confirm(texts.message_redirect, texts.label_are_you_sure).pipe(take(1)).subscribe(res => {
      if (res) {
        this.router.navigateByUrl("");
      }
    });
  }

  sendConversionAnalyticsEvent(object: Scormify) {
    gtag('event', 'conversion', {
      type: object.type.name,
      threshold: object.completion.threshold,
      bookmarks: object.completion?.enableBookmarks ?? false,
      quiz: object.completion?.addOverlayQuiz ?? false,
      lang: object.locale,
      allowLangOverride: object.allowLangOverride
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.success$.next(true);
    this.success$.complete();
  }
}
