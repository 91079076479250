import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { SubscriptionSelection } from '../../../models/subscription-selection';
import { UserSubscription } from '../../..//models/user-subscription';
import { LinksService } from '../../..//services/links.service';
import { PatchApp } from '../../..//store/actions/app.actions';
import { IState } from '../../..//store/reducers';
import { selectApp } from '../../..//store/selectors/app.selectors';
import { IApp } from '../../..//store/state/app.state';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-step-three',
    templateUrl: './step-three.component.html',
    standalone: false
})
export class StepThreeComponent implements OnInit, OnDestroy {
  @Input() selected: SubscriptionSelection;
  reloadDone: string;
  appState$ = this._store.pipe(select(selectApp));
  state: IApp;
  userSubscription: UserSubscription;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _store: Store<IState>,
    private linkSvc: LinksService
  ) {}

  ngOnInit(): void {
    this.getUserSub();
  }

  reload() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['./'], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams: { reload: 1 },
    });
  }

  getUserSub() {
    this.appState$.pipe(take(1)).subscribe((state) => {
      this.state = state;
      this.getUserSubscription();
    });
  }

  getUserSubscription() {
    const link = this.state.user.$links.find((l) => l.rel === 'getSubscription');
    if (link) {
      this.linkSvc
        .httpMethod(link)
        .pipe(take(1))
        .subscribe((res: any) => {
          this._store.dispatch(new PatchApp({ subscription: res }));
        });
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
