import {
  Component,
  EventEmitter,
  Input, OnChanges, OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { texts } from './../../../../../../../assets/texts/texts';
import { Question, QuestionType } from './../../../../../../models/scormify.model';
import { SlugifyPipe } from './../../../../../../pipes/slugify.pipe';


@Component({
    selector: 'app-question-tip',
    templateUrl: './tip.component.html',
    providers: [SlugifyPipe],
    standalone: false
})
export class TipComponent implements OnInit, OnDestroy, OnChanges {
  formtip: UntypedFormGroup;
  texts = texts;
  public correctAnswer: string = '';

  constructor() {}

  @Input()
  public question: Question;

  public initialquestion: Question;

  @Output()
  addQuestionOutput = new EventEmitter<Question>();

  @Output()
  saveQuestionOutput = new EventEmitter<Question>();

  @Output()
  cancelQuestion = new EventEmitter();

  @Output()
  deleteQuestion = new EventEmitter<Question>();

  ngOnInit() {
    const group: any = {};

    if (this.question.id == null) {
      group['questionText'] = new UntypedFormControl('', Validators.required);
    } else {
      this.initialquestion = JSON.parse(JSON.stringify(this.question));
      group['questionText'] = new UntypedFormControl(this.question.text, Validators.required);
    }

    this.formtip = new UntypedFormGroup(group);
  }

  onCancelClick() {
    this.cancelQuestion.emit();
  }
  onDeleteClick() {
    this.deleteQuestion.emit(this.question);
  }

  ngOnDestroy(): void {}

  ngOnChanges() {
    this.ngOnInit();
  }

  onInsertClick() {
    this.question.questionType = QuestionType.tip;
    this.addQuestionOutput.emit(this.question);
  }

  onSaveClick() {
    this.question.questionType = QuestionType.tip;
    this.saveQuestionOutput.emit(this.question);
  }
}
