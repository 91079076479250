import {
  faFilePdf,
  faPlayCircle,
  faFilePowerpoint,
  faVideo
} from '@fortawesome/free-solid-svg-icons';

import { faYoutube, faVimeo } from '@fortawesome/free-brands-svg-icons';
import { IconDefinition } from '@fortawesome/angular-fontawesome';

export interface IConversionDefinition {
  title: string;
  icon: IconDefinition;
}

export const conversions: Array<IConversionDefinition> = [
  {
    title: 'Vimeo',
    icon: faVimeo,
  },
  {
    title: 'YouTube',
    icon: faYoutube,
  },
  {
    title: 'Your Own Video',
    icon: faVideo,
  },
  {
    title: 'PDF/Word',
    icon: faFilePdf,
  },
  {
    title: 'PowerPoint',
    icon: faFilePowerpoint,
  },
  {
    title: 'Generic Streaming',
    icon: faPlayCircle,
  },
];
