import { Directive, HostListener, Input } from '@angular/core';
import { goToLink } from '../shared/functions/link.fn';

@Directive({
    selector: '[appGoToLink]',
    standalone: false
})
export class GoToLinkDirective {

  constructor() { }

  @Input() appGoToLink: any;
  @Input() rel: string;;

  @HostListener('click', ['$event']) onclick($event) {
    let rel =  this.rel ? this.rel : 'page'
    $event.preventDefault();
    $event.stopPropagation();
    goToLink(this.appGoToLink.$links, rel);
  }

}
