const constants = {};

constants.ls_token = 'token';
constants.ls_test_mode = 'test-mode';
constants.ls_persist_login = 'persist-login';

constants.style_default_name = 'Unnamed style';

constants.job_roles = ['LMS Administrator', 'Content Developer', 'Other'];

constants.max_file_size = 50; // 50 MB

constants.email_generic = 'scormify-generic';
constants.email_welcome = 'scormify-welcome-email-v2-06-02-2022';
constants.email_conversion = 'scormify-conversion';
constants.email_error = 'scormify-error';
constants.email_reset = 'scormify-forgot-password';

constants.header_aws_delete = 'SCORMIFY-KEY';
constants.header_authorization = 'Authorization';

constants.fs_storage = './uploads';

constants.cache_plans = 'plans';
constants.cache_locales = 'locales';
constants.cache_origins = 'origins';
constants.cache_types = 'types';
constants.cache_standards = 'standards';

constants.cache_refresh = 1000 * 60 * 5;
constants.keep_alive = 1000 * 60 * 1;

module.exports = constants;
