<div #content>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{texts.header_confirm}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="lead">
      {{texts.message_confirm_discard_style}}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="modal.dismiss(false)">
      {{texts.button_cancel}}
    </button>
    <button type="button" class="btn btn-primary" (click)="modal.close(true)">
      {{texts.button_confirm}}
    </button>
  </div>
</div>
