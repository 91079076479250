import { createSelector } from '@ngrx/store';
import { IState } from '../reducers';
import { IAppState } from '../state/app.state';

const appState = (state: IState) => state.app;

export const selectApp = createSelector(appState, (state: IAppState) => state.app);

export const selectId = createSelector(appState, (state: IAppState) => state.app.id);
export const selectUser = createSelector(appState, (state: IAppState) => state.app.user);
export const selectIsAuthenticated = createSelector(
  appState,
  (state: IAppState) => state.app.authenticated
);
export const selectRole = createSelector(appState, (state: IAppState) => state.app.role);
