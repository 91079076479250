import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faPlus, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { texts } from '../../../../../assets/texts/texts';
import { AwsResponse } from '../../../../models/aws-response';
import { ObjectType } from '../../../../models/object-type.model';
import { Scormify } from '../../../../models/scormify.model';
import { APIUploader } from '../../../../shared/adapters/apiuploader';
import { BaseComponent } from '../../../../shared/base/base.component';
import { IState } from '../../../../store/reducers';

@Component({
    selector: 'app-bulk-import',
    templateUrl: './bulk-import.component.html',
    standalone: false
})
export class BulkImportComponent extends BaseComponent implements OnInit {

  private _uploader: APIUploader;
  error: any;

  @Input() success$: Subject<boolean>;
  @Output() uploadSuccess = new EventEmitter<Scormify[]>();
  @Output() isUploading = new EventEmitter<boolean>();

  hasFile = false;
  theFile: File = null;
  faPlus = faPlus;
  faRemove = faTimes;
  texts = texts;
  uploading = false;
  response: AwsResponse;
  faSpinner = faSpinner;
  invalid = false;
  sizeLimit;
  selectedType: ObjectType;

  constructor(private _store: Store<IState>, private http: HttpClient) {
    super();
  }

  ngOnInit() {
    this.success$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.reset();
    });
    this.sizeLimit = 1;
    this._uploader = new APIUploader(this.http, "scormify/bulk/import");
  }

  handleFileInput(files: FileList) {
    this.error = undefined;
    this.theFile = files.item(0);
    this.uploading = true;
    this.isUploading.emit(this.uploading);
    this._uploader
      .uploadFile(this.theFile)
      .toPromise()
      .then((res) => {
        this.hasFile = true;
        this.uploading = false;
        this.isUploading.emit(this.uploading);
        this.uploadSuccess.emit(res as any);
        this.response = res;
      })
      .catch((err) => {
        this.uploading = false;
        this.error = err;
      });
  }
  remove() {
    this.uploading = true;
    this.isUploading.emit(this.uploading);
      this.uploading = false;
      this.hasFile = false;
      this.theFile = null;
      this.isUploading.emit(this.uploading);
      this.response = null;
  }

  reset() {
    this.uploading = false;
    this.hasFile = false;
    this.theFile = null;
    this.response = null;
  }

  getRemoveLabel() {
    return texts.button_remove_file.replace('@@FILE@@', this.theFile.name);
  }

}
