import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

export enum AppEventType {
  SubscriptionUpdated = 'SUB_UPDATED',
}

export class AppEvent<T> {
  constructor(public type: AppEventType, public payload: T) {}
}

@Injectable({
  providedIn: 'root',
})
export class EventBrokerService {
  private eventBroker = new Subject<AppEvent<any>>();

  on(eventType: AppEventType): Observable<AppEvent<any>> {
    return this.eventBroker.pipe(filter((event) => event.type === eventType));
  }

  dispatch<T>(event: AppEvent<T>): void {
    this.eventBroker.next(event);
  }
}
