import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Link } from '../../../models/api-response.model';
import { CheckoutItem } from '../../../models/checkout-item';
import { StripeSessionRequest } from '../../../models/stripe-session';
import { LinksService } from '../../../services/links.service';
import { environment } from '../../../../environments/environment';
import { take } from 'rxjs/operators';

declare var Stripe: any;

@Component({
    selector: 'app-stripe-redirect',
    templateUrl: './stripe-redirect.component.html',
    standalone: false
})
export class StripeRedirectComponent implements  OnInit, OnDestroy, AfterViewInit {

  @Input() item: CheckoutItem;
  @Output() token = new EventEmitter<boolean>();

  stripe: any;

  constructor(private linksSvc: LinksService) { }

  ngOnInit() {
    this.initiate()
  }

  ngOnDestroy() {}

  ngAfterViewInit() {}

  initiate() {
    this.getCheckoutSession();
  }

  getCheckoutSession() {
    const data: StripeSessionRequest = {
      priceId: this.item.item.pricingPlan.planId,
      returnUrl: window.location.origin + "/upgrade",
      cancelUrl: window.location.origin + "/upgrade?outcome=0",

    };
    const link: Link = this.item.item.pricingPlan.actions.find(x => x.rel === "getCheckoutSession");
    this.linksSvc
      .httpMethod(link, data)
      .pipe(take(1))
      .subscribe(this.checkoutRedirect);
  }

  checkoutRedirect(res) {
    this.stripe = Stripe(environment.sk);
    this.stripe
    .redirectToCheckout({
      sessionId: res.sessionId
    });
  }

}
