<div class="form-check form-check-inline mb-2">
  <input class="form-check-input" type="checkbox" id="allowDocumentDownload" name="allowDocumentDownload"
         [(ngModel)]="pdf.allowDocumentDownload" (ngModelChange)="onChanges()">
  <label class="form-check-label" for="allowDocumentDownload"> {{ texts.label_allow_document_download }}
    <span [ngbTooltip]="texts.tooltip_allow_download" placement="right">
      <fa-icon [icon]="faQC"></fa-icon>
    </span></label>
</div>
<div class="uploader-wrapper">
  <app-uploader [type$]="type$" (uploadSuccess)="uploadSuccess($event)" (isRemoving)="isRemoving($event)" [success$]="success$"></app-uploader>
</div>