import { Component, EventEmitter, Input, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { texts } from '../../../../../assets/texts/texts';
import { AwsResponse } from '../../../../models/aws-response';
import { ObjectType } from '../../../../models/object-type.model';
import { PowerPoint, Scormify } from '../../../../models/scormify.model';
import { BaseComponent } from '../../../../shared/base/base.component';
import { IState } from '../../../../store/reducers';
import { selectApp } from '../../../../store/selectors/app.selectors';
import { constants } from './../../../../../assets/constants/constants';

@Component({
  selector: 'app-powerpoint',
  templateUrl: './powerpoint.component.html',
  styleUrls: ['./powerpoint.component.scss'],
  standalone: false
})
export class PowerpointComponent extends BaseComponent {
  ppt = new PowerPoint();
  appState$ = this._store.pipe(select(selectApp));
  uploading = false;
  constants = constants;
  texts = texts;
  selectedType: ObjectType;
  @Input() success$: Subject<boolean>;
  @Input() type$: BehaviorSubject<ObjectType>;
  @Input() object$: BehaviorSubject<Scormify>;
  @Output() update = new EventEmitter<PowerPoint>();
  @Output() remove = new EventEmitter<boolean>();

  constructor(private _store: Store<IState>) {
    super();
  }

  isRemoving($event: boolean) {
    this.remove.emit($event);
  }

  uploadSuccess($event: AwsResponse) {
    this.ppt.file = $event;
    this.update.emit(this.ppt);
  }
}
