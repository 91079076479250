import { SubscriptionSelection } from "./subscription-selection";

export class CheckoutItem {
  item: SubscriptionSelection;  // will need to be adapted for future support of "bucket" purchases
  itemType: CheckoutItemType;
}

export enum CheckoutItemType {
  subscription,
  bucket
}
