import { IconDefinition } from '@fortawesome/angular-fontawesome';
import {
  faPaperPlane,
  faMobileAlt,
  faNewspaper,
  faQuestion
} from '@fortawesome/free-solid-svg-icons';

export interface IFeatureDefinition {
  icon: IconDefinition;
  title: string;
  description: string;
}

export const features: Array<IFeatureDefinition> = [
  {
    icon: faPaperPlane,
    title: 'SCORM Compliant',
    description:
      'Meets SCORM standard so your eLearning course works in any Learning Management System (LMS).',
  },
  {
    icon: faMobileAlt,
    title: 'Responsive Design',
    description:
      'Meets SCORM standard so your eLearning course works in any Learning Management System (LMS).',
  },
  {
    icon: faNewspaper,
    title: 'Style Your Courses',
    description: 'Use Sass and CSS to make sure your courses conform to your branding standards.',
  },
  {
    icon: faQuestion,
    title: 'Add Quizzes to Video',
    description: 'Add questions into your video content before conversions for a seamless end user experience.',
  },
];
