import { IAppState, initialAppState } from '../state/app.state';
import { AppActions, AppActionTypes } from './../actions/app.actions';

export function _reducer(state = initialAppState, action: AppActions): IAppState {
  switch (action.type) {
    case AppActionTypes.PatchApp: {
      return {
        ...state,
        app: { ...state.app, ...action.payload }
      };
    }

    default:
      return state;
  }
}

export const appReducers = _reducer;
