<section id="admin-nav pull-right">
    <ul class="nav nav-tabs pull-right">
        <li class="nav-item">
            <a routerLink="home" routerLinkActive="active" class="nav-link active">Home</a>
        </li>
        <li class="nav-item">
            <a routerLink="users" routerLinkActive="active" class="nav-link">Users</a>
        </li>
        <li class="nav-item">
            <a routerLink="stripe" routerLinkActive="active" class="nav-link">Stripe</a>
        </li>
    </ul>
</section>
<br>