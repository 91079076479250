import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { IAppState, initialAppState } from '../state/app.state';
import { appReducers } from './app.reducers';

export interface IState {
  app: IAppState;
}

export const initialState: IState = {
  app: initialAppState
};

export function getInitialState(): IState {
  return initialState;
}

export const reducers: ActionReducerMap<IState, any> = { app: appReducers };

export const metaReducers: MetaReducer<IState>[] = !environment.production ? [] : [];
