<div class="row" *ngIf="loading">
    <div class="col">
        <app-spinner></app-spinner>
    </div>
</div>
<div class="row justify-content-end" *ngIf="hasError">
    <div class="col-6">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
    </div>
</div>
<div class="row h-100" *ngIf="!loading">
    <div class="col-lg-5 col-md-5 checkout-details border-right h-100">
        <h4>Subscribe to {{checkoutItem.item.scormifySubscription.title}}</h4>
        <h3>{{checkoutItem.item.pricingPlan.formattedCost}} per {{checkoutItem.item.pricingPlan.formattedRenewal}}</h3>
        <div class="my-5"></div>
        <div class="row">
            <div class="col-sm-1"><img class="logo" src="assets/images/logo.png"></div>
            <div class="col-sm-5">
                <span class="d-block"><strong>{{checkoutItem.item.pricingPlan.name}}</strong></span>
                <span class="d-block small">Billed {{checkoutItem.item.pricingPlan.renewal | titlecase}}</span>
            </div>
            <div class="col-sm-4 text-right">
                <span *ngIf="couponIsForever">
                    {{checkoutPackage.formattedTotal}}
                </span>
                <span *ngIf="!hasCoupon || (hasCoupon && !couponIsForever)">
                    {{checkoutPackage.formattedSubtotal}}
                </span>

            </div>
        </div>
        <!-- future support for buckets
        <div class="row mt-4 d-none">
            <div class="col-sm-1">&nbsp;</div>
            <div class="col-sm-5"><strong>{{texts.label_subtotal}}</strong></div>
            <div class="col-sm-4 text-right">{{checkoutPackage.formattedSubtotal}}</div>
        </div> -->
        <div class="row mt-4">
            <div class="col-sm-1">
                <span *ngIf="hasCoupon">
                    <fa-icon [icon]="faCheck" class=" coupon-check text-success"></fa-icon>
                </span>
            </div>
            <div class="col-9 border-top border-bottom py-2">
                <div *ngIf="!hasCoupon">
                    <div *ngIf="!showPromoCode">
                        <button class="btn btn-link px-0 mx-0" (click)="onClickPromoCode()"><strong>{{texts.button_promo_code}}</strong></button>
                    </div>
                    <app-spinner *ngIf="showPromoCode && couponLoading"></app-spinner>
                    <div *ngIf="showPromoCode && !couponLoading" class="input-group">
                        <label class="sr-only">Code</label>
                        <input type="text" placeholder="Code" class="form-control no-validate" [(ngModel)]="code" name="code">
                        <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="applyCoupon()">{{texts.button_apply}}</button>
                    </div>
                    <small *ngIf="couponNotFound">{{texts.invalid_promo_code}}</small>
                </div>
                <div *ngIf="hasCoupon">
                    <span class="" [innerHTML]="checkoutPackage.couponFormatted"></span>
                    <fa-icon [icon]="faClose" class="ms-2 text-muted float-right clickable" (click)="removeCoupon()"></fa-icon>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-1">&nbsp;</div>
            <div class="col-sm-5"><strong>{{texts.label_total}}</strong></div>
            <div class="col-sm-4 text-right">{{checkoutPackage.formattedTotal}}</div>
        </div>
        <div class="row mt-5">
            <div class="col-sm-1">&nbsp;</div>
            <div class="col-sm-5">&nbsp;</div>
            <div class="col-sm-4">
                <div class="mt-4"></div>
                <button class="btn btn-outline-primary back-button" (click)="goBack()"> &#10092; Select Plan </button>
            </div>
        </div>
    </div>
    <div class="col-lg-1 col-md-1"></div>
    <div class="col-lg-6 col-md-5">
        <h3>{{texts.header_pay_with_card}}</h3>
        <div class="my-3"></div>
        <app-payment-form [item]="checkoutItem" (token)="onTokenReceived($event)"></app-payment-form>
        <!--<app-stripe-redirect [item]="checkoutItem" (success)="onRedirectSuccess($event)"></app-stripe-redirect>-->
    </div>
</div>