import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { constants } from './../assets/constants/constants';
import { UtilityService } from './services/utility.service';

declare var gtag;
//if(gtag) {
//gtag('config', 'AW-701873332');
//}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [UtilityService],
  standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  title = environment.title;
  hideHeader = true;
  up: Boolean;
  REFRESH_INTERVAL = 1000 * 60 * 5;
  sub: Subscription;
  faCircleNotch = faCircleNotch;
  loading = false;
  constants = constants;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.listenforRouteChange();
    gtag('config', 'AW-701873332');
  }

  ngOnDestroy() {
    console.log('uh oh');
  }

  listenforRouteChange() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        let title = event['title'];
        if (!event['skipSuffix']) title += ` | ${this.title}`
        this.titleService.setTitle(title);
        this.hideHeader = event['hideHeader'];

      });
  }
}
