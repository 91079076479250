import {
  Component,
  EventEmitter,
  Input, OnChanges, OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { texts } from './../../../../../../../assets/texts/texts';
import { Answer, Question, QuestionType } from './../../../../../../models/scormify.model';
import { SlugifyPipe } from './../../../../../../pipes/slugify.pipe';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ModalService } from '../../../../../../services/modal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-question-multipleselect',
    templateUrl: './multipleselect.component.html',
    providers: [SlugifyPipe],
    standalone: false
})
export class MultipleSelectComponent implements OnInit, OnDestroy, OnChanges {
  formmultipleselect: UntypedFormGroup;
  myCheckboxGroup: UntypedFormGroup;
  public alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  texts = texts;
  public anyCheckboxChecked = false;
  faBin = faTrash;

  constructor(private modalService: ModalService, public activeModal: NgbActiveModal) { }

  @Input()
  public question: Question;

  @Output()
  addQuestionOutput = new EventEmitter<Question>();

  @Output()
  saveQuestionOutput = new EventEmitter<Question>();

  @Output()
  cancelQuestion = new EventEmitter();

  @Output()
  deleteQuestion = new EventEmitter<Question>();

  ngOnInit() {
    if (this.question.id == null) {
      this.prepareMultipleSelectQuestion();
    } else {
      this.anyCheckboxChecked = true;
      this.prepareMultipleSelectExistingQuestion();
    }
  }

  checkValidCheckBoxes(evt) {
    if (evt.target.checked == true) this.anyCheckboxChecked = true;

    if (evt.target.checked == false) {
      if (
        this.question.answers.some((a, index) => index != evt.target.value && a.correctAnswer == true)
      )
        this.anyCheckboxChecked = true;
      else this.anyCheckboxChecked = false;
    }
  }

  prepareMultipleSelectExistingQuestion() {
    const group: any = {};

    let index = 1;

    this.question.answers.forEach((answer, index) => {
      group[index.toString()] = new UntypedFormControl(answer.text, Validators.required);

      group['mycheckbox' + index.toString()] = new UntypedFormControl(answer.correctAnswer);

      index++;
    });

    group['questionText'] = new UntypedFormControl('', Validators.required);

    this.formmultipleselect = new UntypedFormGroup(group);
  }

  prepareMultipleSelectQuestion() {
    this.question.questionType = QuestionType.multipleSelect;

    this.question.answers = new Array<Answer>();

    for (let i = 0; i < 3; i++) {
      let answer = new Answer();
      answer.letter = this.alphabet[i];
      answer.correctAnswer = false;
      this.question.answers.push(answer);
    }

    const group: any = {};

    let index = 1;

    this.question.answers.forEach((answer, index) => {
      group[index.toString()] = new UntypedFormControl(answer.text, Validators.required);

      group['mycheckbox' + index.toString()] = new UntypedFormControl(answer.correctAnswer);

      index++;
    });

    group['questionText'] = new UntypedFormControl('', Validators.required);

    this.formmultipleselect = new UntypedFormGroup(group);
  }

  onAddNewOptionClick() {
    let answer = new Answer();
    answer.letter = this.alphabet[this.question.answers.length];
    answer.correctAnswer = false;

    this.question.answers.push(answer);

    this.formmultipleselect.addControl((this.question.answers.length - 1).toString(), new UntypedFormControl('', Validators.required));

    this.formmultipleselect.addControl(
      'mycheckbox' + (this.question.answers.length - 1).toString(),
      new UntypedFormControl(false)
    );
  }
  onCancelClick() {
    this.cancelQuestion.emit();
  }

  onDeleteClick() {
    this.deleteQuestion.emit(this.question);
  }

  ngOnDestroy(): void { }

  ngOnChanges() {
    this.ngOnInit();
  }

  onInsertClick() {
    this.question.questionType = QuestionType.multipleSelect;

    this.question.answers.forEach((obj, index) => {
      obj.letter = this.alphabet[index];
    });
    this.addQuestionOutput.emit(this.question);
  }

  onSaveClick() {
    this.question.questionType = QuestionType.multipleSelect;
    this.question.answers.forEach((obj, index) => {
      obj.letter = this.alphabet[index];
    });

    this.saveQuestionOutput.emit(this.question);
  }


  onDeleteAnswerClick(answerLetter: string, i: number) {

    if (this.question.answers.length == 3) {
      this.modalService
        .ok(texts.header_delete_answer, texts.message_multiple_select_minimum_number_of_answers)
        .subscribe((result: boolean) => {
        });
      return;
    }

    this.question.answers.forEach((item, index) => {
      this.formmultipleselect.removeControl(index.toString());

      this.formmultipleselect.removeControl('mycheckbox' + index.toString());

    });


    this.question.answers.splice(i, 1);

    if (this.question.answers.some((a, index) => a.correctAnswer == true))
      this.anyCheckboxChecked = true;
    else
      this.anyCheckboxChecked = false;


    const group: any = {};


    this.question.answers.forEach((answer, index) => {
      answer.letter = this.alphabet[index];

      group[index.toString()] = new UntypedFormControl(answer.text, Validators.required);
      group['mycheckbox' + index.toString()] = new UntypedFormControl(answer.correctAnswer);

    });

    this.formmultipleselect = new UntypedFormGroup(group);

  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }
}
