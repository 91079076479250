import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AuthenticateComponent } from './account/authenticate/authenticate.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { LoginComponent } from './account/login/login.component';
import { LogoutComponent } from './account/logout/logout.component';
import { ProfileComponent } from './account/profile/profile.component';
import { RegisterComponent } from './account/register/register.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { SsoComponent } from './account/sso/sso.component';
import { UpgradeComponent } from './account/upgrade/upgrade.component';
import { AdminShellComponent } from './admin/admin-shell/admin-shell.component';
import { AdminHomeComponent } from './admin/home/home.component';
import { StripeComponent } from './admin/stripe/stripe.component';
import { AdminUserDetailComponent } from './admin/users/user-detail/user-detail.component';
import { AdminUsersComponent } from './admin/users/users.component';
import { AdminGuard } from './guards/admin.guard';
import { AnonymousGuard } from './guards/anonymous.guard';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { ConfirmNavigationGuard } from './guards/confirm-navigation.guard';
import { HomeComponent } from './home/home/home.component';
import { MainComponent } from './home/main/main.component';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { ServerErrorComponent } from './layout/server-error/server-error.component';
import { UnauthorizedComponent } from './layout/unauthorized/unauthorized.component';
import { UnavailableComponent } from './layout/unavailable/unavailable.component';
import { BulkComponent } from './scormify/v1/bulk/bulk.component';
import { AppShellComponent } from './scormify/v1/app-shell/app-shell.component';
import { HubComponent } from './scormify/v1/hub/hub.component';
import { ObjectDetailComponent } from './scormify/v1/hub/object-detail/object-detail.component';
import { NewComponent } from './scormify/v1/new/new.component';
import { ManageStylesComponent } from './styles/manage-styles/manage-styles.component';
import { StyleEditorComponent } from './styles/style-editor/style-editor.component';
import { StyleShellComponent } from './styles/style-shell/style-shell.component';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AnonymousGuard],
    data: { title: 'Scormify: Easy to Use SCORM Conversion Tool', hideHeader: true, noSuffix: true },
  },
  {
    path: 'main',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AnonymousGuard],
    data: { title: 'Login', hideHeader: true },
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: { title: 'Logout', hideHeader: true },
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [AnonymousGuard],
    data: { title: 'Register', hideHeader: true },
  },
  {
    path: 'authenticate',
    component: AuthenticateComponent,
    data: { title: 'Scormify', hideHeader: true },
  },
  {
    path: 'reset-password/:searchString',
    component: ResetPasswordComponent,
    data: { title: 'Reset Your Password', hideHeader: false },
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: { title: 'Reset Your Password', hideHeader: false },
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: { title: 'Forgot Password', hideHeader: false },
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthenticatedGuard],
    data: { title: 'Home', hideHeader: false },
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthenticatedGuard],
    data: { title: 'Profile', hideHeader: false },
  },
  {
    path: 'profile/:state',
    component: ProfileComponent,
    canActivate: [AuthenticatedGuard],
    data: { title: 'Profile', hideHeader: false },
  },
  {
    path: 'upgrade',
    component: UpgradeComponent,
    canActivate: [AuthenticatedGuard],
    data: { title: 'Upgrade' },
  },
  {
    path: 'styles',
    component: StyleShellComponent,
    canActivate: [AuthenticatedGuard],
    data: { title: 'Manage Styles' },
    children: [
      {
        path: '',
        redirectTo: 'hub',
        pathMatch: 'full',
      },
      {
        path: 'hub',
        component: ManageStylesComponent,
        canActivate: [AuthenticatedGuard],
        data: { title: 'Style Hub' },
      },
      {
        path: 'new',
        component: StyleEditorComponent,
        canActivate: [AuthenticatedGuard],
        data: { title: 'New Style' },
      },
      {
        path: ':id',
        component: StyleEditorComponent,
        canActivate: [AuthenticatedGuard],
        canDeactivate: [ConfirmNavigationGuard],
        data: { title: 'Edit Style' },
      },
    ],
  },
  {
    path: 'app',
    component: AppShellComponent,
    canActivate: [AuthenticatedGuard],
    data: { title: 'Hub' },
    children: [
      {
        path: '',
        redirectTo: 'hub',
        pathMatch: 'full',
      },
      {
        path: 'hub',
        component: HubComponent,
        canActivate: [AuthenticatedGuard],
        data: { title: 'Hub' },
      },
      {
        path: 'hub/:id/detail',
        component: ObjectDetailComponent,
        canActivate: [AuthenticatedGuard],
        data: { title: 'Detail' },
      },
      {
        path: 'new',
        component: NewComponent,
        canActivate: [AuthenticatedGuard],
        data: { title: 'New Course' },
      },
      {
        path: 'new/:type',
        component: NewComponent,
        canActivate: [AuthenticatedGuard],
        data: { title: 'New Course' },
      },
      {
        path: 'bulk',
        component: BulkComponent,
        canActivate: [AuthenticatedGuard],
        data: { title: 'Bulk Create' },
      },
    ],
  },

  {
    path: 'admin',
    component: AdminShellComponent,
    canActivate: [AdminGuard],
    data: { title: 'Admin' },
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: AdminHomeComponent,
        canActivate: [AdminGuard],
        data: { title: 'Admin - Home' },
      },
      {
        path: 'users',
        component: AdminUsersComponent,
        canActivate: [AdminGuard],
        data: { title: 'Admin - Users' },
      },
      {
        path: 'user/:id',
        component: AdminUserDetailComponent,
        canActivate: [AdminGuard],
        data: { title: 'Admin - User Detail' },
      },
      {
        path: 'stripe',
        component: StripeComponent,
        canActivate: [AdminGuard],
        data: { title: 'Admin - Stripe' },
      },
    ],
  },
  {
    path: 'sso',
    component: SsoComponent,
    data: { title: 'Single Sign On' },
  },
  {
    path: 'error',
    component: ServerErrorComponent,
    data: { title: 'Error' },
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    data: { title: 'Unauthorized' },
  },
  {
    path: 'maintenance',
    component: UnavailableComponent,
    data: { title: 'Maintenance' },
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: { title: 'Not Found' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }), CommonModule, FormsModule, BrowserModule],
  exports: [RouterModule],
})
export class AppRoutingModule { }
