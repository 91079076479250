<section class="container" id="sso">

  <div class="row col">
    <div class="alert alert-danger" *ngIf="error">
      {{error}}
    </div>
    <br>
    <div class="alert alert-success" *ngIf="message">
      {{message}}
    </div>
  </div>
</section>
