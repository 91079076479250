import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { faCheck, faPlus, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { ColumnMode, TableColumn } from '@swimlane/ngx-datatable';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, filter, take, takeUntil } from 'rxjs/operators';
import { ApiResponse } from '../../../models/api-response.model';
import { ObjectType, TypeSize } from '../../../models/object-type.model';
import { Scormify } from '../../../models/scormify.model';
import { SlugifyPipe } from '../../../pipes/slugify.pipe';
import { Scormifyv1Service } from '../../../services/scormifyv1.service';
import { StylesService } from '../../../services/styles.service';
import { IState } from '../../../store/reducers';
import { BaseScormifyComponent } from '../base-scormify-component';
import { texts } from './../../../../assets/texts/texts';

declare var gtag: any;

@Component({
  selector: 'app-bulk',
  templateUrl: './bulk.component.html',
  styleUrls: ['./bulk.component.scss'],
  providers: [SlugifyPipe],
  standalone: false
})
export class BulkComponent extends BaseScormifyComponent implements OnInit {
  success$: Subject<boolean> = new Subject();
  types$: Array<BehaviorSubject<ObjectType>> = [];
  objects$: Array<BehaviorSubject<Scormify>> = [];
  columns: Array<TableColumn> = [
    { name: texts.label_title },
    { name: texts.label_type, }
  ];
  texts = texts;
  faTimes = faTimesCircle;
  faTrash = faTrash;
  faPlus = faPlus;
  faCheck = faCheck;
  fb: UntypedFormBuilder;
  createForm: FormGroup;
  ColumnMode = ColumnMode;
  submitted = false;
  response: ApiResponse;
  showBulkImport = false;

  @ViewChild('datatable') datatable: any;
  hasError = false;
  error: string;
  formsWithError: any;

  constructor(
    stylesService: StylesService,
    fb: UntypedFormBuilder,
    slugifyPipe: SlugifyPipe,
    _store: Store<IState>,
    svc: Scormifyv1Service
  ) {
    super(svc, slugifyPipe, fb, stylesService, _store);
  }

  ngOnInit(): void {
    this.loading = true;
    this.appState$
      .pipe(
        filter(state => !!state.user),
        takeUntil(this.destroyed$),
      )
      .subscribe((state) => {
        this.state = state;
        if (this.state.user.$flags.ALLOW_BULK_IMPORT) {
          this.showBulkImport = true;
        }
      });
    this.setData(true).pipe(take(1)).subscribe(() => {
      this.types.unshift({ name: 'Select a Type', key: 'DEFAULT', windowSize: new TypeSize(), disabled: true });
      this.styles.unshift({ name: 'Select a Style', id: 'DEFAULT', disabled: true });
      this.setupCreateForm();
      this.watchChanges();
      // this.fetchSavedForm()
      this.loading = false;
    });
  }

  watchChanges() {
    this.createForm?.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.update();
    })
    this.createForm?.valueChanges.pipe(debounceTime(1000), takeUntil(this.destroyed$)).subscribe(() => {
      if (this.createForm.dirty) {
        //this.saveFormState();
      }
    })
  }

  setupCreateForm() {
    this.createForm = this.fb.group({
      objects: this.fb.array([this.createFormGroup(true)])
    });
    this.types$.push(new BehaviorSubject(new ObjectType()));
    this.objects$.push(new BehaviorSubject(new Scormify()));
  }

  clear() {
    this.createForm.reset();
    this.setupCreateForm();
    this.watchChanges();
  }

  get objectArray() {
    return (this.createForm?.get('objects') as UntypedFormArray);
  }

  get objectControls() {
    return this.objectArray.controls;
  }

  get objectControlsReady(): boolean {
    return this.createForm && !!this.objectControls.length;
  }

  update() {
    (this.createForm.get('objects') as UntypedFormArray).controls = [...(this.createForm.get('objects') as UntypedFormArray).controls];
  }


  rowObject(rowIndex) {
    return this.objectControls[rowIndex]
  }

  addRow(copyFromAbove: boolean) {
    if (copyFromAbove) {
      const idx = this.objectArray.length - 1;
      const toCopy = this.objectArray.at(idx);
      const newForm = this.createFormGroup(true);
      newForm.patchValue(toCopy.value);
      this.objectArray.push(newForm);
      this.types$.push(new BehaviorSubject<ObjectType>(new ObjectType()));
      this.updateTypeSub(this.getTypeFromKey(toCopy.value.type), idx + 1);
      this.objects$.push(new BehaviorSubject<Scormify>(new Scormify()))
      newForm.patchValue(toCopy.value);
      this.setFormByType(newForm, toCopy.value.type, false);
      this.updateObjectSub(toCopy.value, idx + 1);
      newForm.updateValueAndValidity();
      newForm.markAllAsTouched();
      newForm.markAsDirty();

    } else {
      this.objectArray.push(this.createFormGroup(true));
      this.types$.push(new BehaviorSubject<ObjectType>(new ObjectType()));
      this.objects$.push(new BehaviorSubject<Scormify>(new Scormify()))
    }
    return this.objectArray.length - 1;
  }

  getTypeFromKey(typeKey: string) {
    return this.types.find(t => t.key === typeKey);
  }

  setTypeFromEvent($event, rowIndex) {
    let form = this.getFormGroupFromRow(rowIndex);
    this.setType($event.target.value, form, rowIndex);
  }

  setType(typeKey, form: FormGroup, rowIndex) {
    if (!form) return;
    const type = this.getTypeFromKey(typeKey);
    this.types$[rowIndex].next(type);
    this.setFormByType(form, type, false);
    this.updateObjectSub(form.value, rowIndex);
  }

  isTypeSet(form: FormGroup) {
    if (!form) return;
    const type = form.get('type').value;
    return type !== 'DEFAULT';
  }

  getTypeSubFromRow(rowIndex) {
    return this.types$[rowIndex];
  }

  updateTypeSub(type: ObjectType, rowIndex) {
    this.getTypeSubFromRow(rowIndex).next(type);
  }

  getRowType(form: FormGroup) {
    if (!form) return;
    return form.get('type').value;
  }

  getFormGroupFromRow(rowIndex) {
    return this.objectArray.at(rowIndex) as FormGroup;
  }

  getformGroupValue(form: FormGroup) {
    return form.value;
  }

  getObjectSubFromRow(rowIndex) {
    return this.objects$[rowIndex];
  }

  updateObjectSub(object: Scormify, rowIndex) {
    this.getObjectSubFromRow(rowIndex).next(object);
  }

  removeRow(rowIndex) {
    console.log('row to remove: ', rowIndex);
    // zap 1 to account for header row
    this.objectArray.removeAt(rowIndex);
    this.types$.splice(rowIndex, 1);
    this.objects$.splice(rowIndex, 1)
  }

  createEnabled() {
    this.createForm.valid;
  }

  onSubmit() {
    window.scrollTo(0, 0);
    this.submitted = true;
    if (this.createForm.invalid) {
      this.hasError = true;
      this.error = 'There are invalid entries.  Invalid entries are marked with a red checkmark.  Correct them and try again.';
      this.createForm.markAllAsTouched();
      return;
    } else {
      this.hasError = false;
    }
    this.loading = true;

    this.svc.bulk(this.createForm.value.objects).subscribe(res => {
      this.loading = false;
      this.response = res;
      const link = this.response.bulkResponse.links.find(x => x.rel === 'download');
      this.download(link.href);
      this.clear();
      this.submitted = false;
    }, (err) => {
      this.loading = false;
      this.submitted = false;
      console.log(err);
    });
  }

  sendBulkStartAnalyticsEvent() {
    gtag('event', 'bulk_start', {
      objectCount: this.createForm.value.objects.length,
    });
  }
  sendBulkEndAnalyticsEvent() {
    gtag('event', 'bulk_success', {
      objectCount: this.createForm.value.objects.length,
    });
  }

  saveFormState() {
    var payloadRaw = JSON.stringify(this.createForm.value);
    sessionStorage.setItem('bulk-form-state', payloadRaw);
  }

  fetchSavedForm() {
    var storedForm = sessionStorage.getItem('bulk-form-state');
    if (storedForm && storedForm !== null) {
      let formState = JSON.parse(storedForm);
      for (let i = 0; i < formState.objects.length; ++i) {
        this.setFormFromFormState(i, formState);
      }
      this.createForm.updateValueAndValidity();
    }
  }

  setFormFromFormState(i: number, formState) {
    let fgCtx = this.objectArray.controls[i] as FormGroup;
    if (!fgCtx) {
      i = this.addRow(false);
      fgCtx = this.objectArray.controls[i] as FormGroup;
    }
    this.setType(formState.objects[i].type, fgCtx, i);
    fgCtx.setValue(formState.objects[i]);
    fgCtx.updateValueAndValidity();
  }

  import($event) {
    gtag('event', 'bulk_import_start', {
      objectCount: this.objectArray.length,
    });
    if (this.objectArray.length === 1) {
      this.objectArray.removeAt(0);
      this.objects$.splice(0, 1);
      this.types$.splice(0, 1);
    }
    for (let importObject of $event) {
      let i = this.addRow(false);
      let fgCtx = this.objectArray.controls[i] as FormGroup;
      fgCtx.patchValue(importObject);
      if (importObject.type) {
        this.setType(importObject.type, fgCtx, i);
      }
      fgCtx.patchValue(importObject);
      fgCtx.markAllAsTouched();
      fgCtx.updateValueAndValidity();
      this.createForm.updateValueAndValidity();
      this.objectArray.updateValueAndValidity();
      this.objects$[i].next(fgCtx.value);
    }
    gtag('event', 'bulk_import_end', {
      objectCount: this.objectArray.length,
    });
  }

}
