import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AccountService } from '../services/account.service';

@Injectable()
export class AnonymousGuard  {
  constructor(public svc: AccountService, public router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.svc.getIsAuthenticated().pipe(
      take(1),
      map((res) => {
        if (res) {
          this.router.navigate(['authenticate']);
          return false;
        }
        return true;
      })
    );
  }
}
