import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  OnChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { texts } from './../../../../../../../assets/texts/texts';
import { Answer, Question, QuestionType } from './../../../../../../models/scormify.model';
import { SlugifyPipe } from './../../../../../../pipes/slugify.pipe';

@Component({
    selector: 'app-question-truefalse',
    templateUrl: './truefalse.component.html',
    providers: [SlugifyPipe],
    standalone: false
})
export class TrueFalseComponent implements OnInit, OnDestroy, OnChanges {
  formtruefalse: UntypedFormGroup;
  public alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  texts = texts;
  public correctAnswer: string = '';

  constructor() {}

  @Input()
  public question: Question;

  @Output()
  addQuestionOutput = new EventEmitter<Question>();

  @Output()
  saveQuestionOutput = new EventEmitter<Question>();

  @Output()
  cancelQuestion = new EventEmitter();

  @Output()
  deleteQuestion = new EventEmitter<Question>();

  ngOnInit() {
    if (this.question.id == null) {
      this.prepareTrueFalseQuestion();
    } else {
      this.prepareTrueFalseQuestionExistingQuestion();
    }
  }

  prepareTrueFalseQuestionExistingQuestion() {
    const group: any = {};

    let correctAnswer = null;

    correctAnswer = this.question.answers.find((a) => a.correctAnswer == true);

    this.correctAnswer = correctAnswer.letter;

    group['correctAnswer'] = new UntypedFormControl(this.correctAnswer, Validators.required);

    group['questionText'] = new UntypedFormControl(this.question.text, Validators.required);

    this.formtruefalse = new UntypedFormGroup(group);
  }

  prepareTrueFalseQuestion() {
    this.question.questionType = QuestionType.trueFalse;

    this.question.answers = new Array<Answer>();

    let answer = new Answer();
    answer.text = this.texts.true;
    answer.letter = this.alphabet[0];
    answer.correctAnswer = false;
    this.question.answers.push(answer);

    answer = new Answer();
    answer.text = this.texts.false;
    answer.letter = this.alphabet[1];
    answer.correctAnswer = false;
    this.question.answers.push(answer);

    const group: any = {};

    group['correctAnswer'] = new UntypedFormControl(null, Validators.required);

    group['questionText'] = new UntypedFormControl('', Validators.required);

    this.formtruefalse = new UntypedFormGroup(group);
  }

  onCancelClick() {
    this.cancelQuestion.emit();
  }

  onDeleteClick() {
    this.deleteQuestion.emit(this.question);
  }

  ngOnDestroy(): void {}

  ngOnChanges() {
    this.ngOnInit();
  }

  onInsertClick() {
    this.question.questionType = QuestionType.trueFalse;

    let correctItem = this.question.answers.find((a) => a.letter == this.correctAnswer);

    let correctIndex = this.question.answers.indexOf(correctItem);

    correctItem.correctAnswer = true;

    this.question.answers[correctIndex] == correctItem;
    this.addQuestionOutput.emit(this.question);
  }

  onSaveClick() {
    this.question.questionType = QuestionType.trueFalse;

    this.question.answers.forEach((obj) => {
      if (this.correctAnswer == obj.letter) obj.correctAnswer = true;
      else obj.correctAnswer = false;
    });
    this.saveQuestionOutput.emit(this.question);
  }
}
