<section class="container" id="server-error">
  <h1 class="display-5 text-danger">Oh no!</h1>
  <p class="lead">The previous operation encountered a fatal error.</p>
  <ul class="nav mt-3" *ngIf="isAuthenticated">
    <li class="nav-item3 me-3">
      <a class="btn btn-primary" (click)="goBack()">Back</a>
    </li>
    <li class="nav-item">
      <a class="btn btn-primary" routerLink="/home">Home</a>
    </li>
  </ul>
  <br>
  <ul class="nav" *ngIf="!isAuthenticated">
    <li class="nav-item">
      <button class="nav-link btn btn-primary" (click)="goBack()">Back</button>
    </li>
    <li class="nav-item ms-3">
      <a class="nav-link btn btn-primary" routerLink="/login">Login</a>
    </li>
    <li class="nav-item  ms-3">
      <a class="nav-link btn btn-primary" routerLink="/register">Register</a>
    </li>
  </ul>
  <br>
  <div style="width:100%;height:0;padding-bottom:77%;position:relative;"><iframe src="https://giphy.com/embed/oXB0K4oFw3fck" width="100%" height="100%" style="position:absolute; border:0" title="" class="giphy-embed" allowFullScreen></iframe></div><p><a href="https://giphy.com/gifs/sad-arrested-development-disappointed-oXB0K4oFw3fck">via GIPHY</a></p>
</section>
