import { Component } from '@angular/core';
import { faFacebook, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faGlobe, faLifeRing } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false
})
export class FooterComponent {
  year = new Date().getFullYear();
  faTwitter = faTwitter;
  faYoutube = faYoutube;
  faFacebook = faFacebook;
  faLinkedIn = faLinkedin;
  faSupport = faLifeRing;
  faGlobe = faGlobe;
}
