import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { texts } from '../../../../assets/texts/texts';
import { Scormify, Quiz, Question, QuestionType, Answer } from '../../../models/scormify.model';


@Component({
    selector: 'app-quiz-modal',
    templateUrl: './quiz-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class QuizModalComponent {
  scormify: Scormify
  quiz: Quiz;
  question: Question;
  texts = texts;
  QuestionType = QuestionType;
  choosenAnswers: Answer[] = []

  constructor(public activeModal: NgbActiveModal) {

  }

  onSelected(answer: Answer): void {

    if (this.question.questionType === QuestionType.multipleChoice || this.question.questionType === QuestionType.trueFalse) {
      this.choosenAnswers = [];
      this.choosenAnswers.push(answer);
    }

    if (this.question.questionType === QuestionType.multipleSelect) {
      let answerInChoosenAnswers = this.choosenAnswers.find(ca => ca.letter == answer.letter);

      if (answerInChoosenAnswers) {
        let index = this.choosenAnswers.findIndex(ca => ca.letter == answer.letter);
        this.choosenAnswers.splice(index, 1);
      }
      else
        this.choosenAnswers.push(answer);

    }

  }

  onConfirmClick() {
    this.activeModal.close(true);
    this.question.answers = this.choosenAnswers;
  }

  onCancelClick() {
    this.activeModal.close(false)
  }
}
