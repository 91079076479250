import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Scormify } from '../../../models/scormify.model';
import { texts } from './../../../../assets/texts/texts';
import { ApiResponse, Link } from './../../../models/api-response.model';
import { ObjectType } from './../../../models/object-type.model';
import { LinksService } from './../../../services/links.service';
import { Scormifyv1Service } from './../../../services/scormifyv1.service';
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-hub',
    templateUrl: './hub.component.html',
    styleUrls: ['./hub.component.scss'],
    standalone: false
})
export class HubComponent implements OnInit, OnDestroy {
  objects: Array<Scormify>;
  objectsCopy: Array<Scormify>;
  columns = [
    { prop: 'descriptors.title', name: texts.label_title },
    { prop: 'type.name', name: texts.label_type },
    { prop: 'status', name: texts.label_status },
    { prop: 'metadata.createdAt', name: texts.label_created },
  ];
  historySub: Subscription;
  loading = true;
  faTimes = faTimes;
  faSpinner = faSpinner;

  datePipe = new DatePipe('en');
  filters = {
    search: '',
    types: [],
    status: [],
  };
  result: ApiResponse;
  types: Array<ObjectType>;
  type$: Subscription;
  processing = false;
  texts = texts;
  statuses = [texts.status_done, texts.status_processing, texts.status_error];
  constructor(private svc: Scormifyv1Service, private links: LinksService) { }

  ngOnInit() {
    this.getHistory();
    this.getTypes();
  }

  getHistory() {
    this.loading = true;
    this.historySub = this.svc.getHistory().subscribe((response: ApiResponse) => {
      this.objects = response.payload;
      this.objectsCopy = response.payload;
      this.filterChange(null);
      this.loading = false;
    });
  }

  getTypes() {
    this.type$ = this.svc
      .getTypes()
      .pipe(take(1))
      .subscribe((res: ApiResponse) => {
        this.types = res.payload;
      });
  }

  gotoPage(object: Scormify) {
    return (window.location.href = object.$links.filter(
      (_link: Link) => _link.rel === 'page'
    )[0].href);
  }

  dispatch(action: Link, object: Scormify, row: number) {
    this.processing = true;
    this.objects[row].republishing = true;
    this.links.xhrAction(action, object, false).subscribe(
      (res) => {
        this.objects[row].republishing = false;
        this.processing = false;
        const downloadLink = res.links.find((link) => link.rel === 'download');
        this.download(downloadLink.href);
      },
      (err) => {
        this.objects[row].republishing = false;
        this.processing = false;
      }
    );
  }

  download(url) {
    const a = document.createElement('a')
    a.href = url
    a.download = '';
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  getStatusClass(value): any {
    return {
      'badge-danger': value === texts.status_error,
      'badge-success': value === texts.status_done,
      'badge-warning': value === texts.status_processing,
    };
  }

  filterChange($event) {
    this.objects = this.objectsCopy.filter((x) => this.filterSearch(x));
  }

  filterSearch(object: Scormify) {
    let search = this.filters.search.toLocaleLowerCase();
    if (this.filters.search === '') {
      search = undefined;
    }
    return (
      (!search || object.descriptors.title.toLowerCase().includes(search)) &&
      (!this.filters.types.length || this.filters.types.find((x) => x.key === object.type.key)) &&
      (!this.filters.status.length || this.filters.status.find((x) => x === object.status))
    );
  }

  clearSearch() {
    this.filters = {
      search: '',
      types: [],
      status: [],
    };
    this.objects = Object.assign([], this.objectsCopy);
  }

  ngOnDestroy() {
    this.historySub.unsubscribe();
  }
}
