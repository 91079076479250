import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha-2';
import { QuillModule } from 'ngx-quill';
import { ToastrModule } from 'ngx-toastr';
import { AuthenticateComponent } from './account/authenticate/authenticate.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { LoginComponent } from './account/login/login.component';
import { LogoutComponent } from './account/logout/logout.component';
import { ProfileComponent } from './account/profile/profile.component';
import { RegisterComponent } from './account/register/register.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { GenerateApiKeyComponent } from './account/shared/generate-api-key/generate-api-key.component';
import { ManageSubscriptionButtonComponent } from './account/shared/manage-subscription-button/manage-subscription-button.component';
import { PasswordInputComponent } from './account/shared/password-input/password-input.component';
import { PaymentFormComponent } from './account/shared/payment-form/payment-form.component';
import { ProfileFormComponent } from './account/shared/profile-form/profile-form.component';
import { StripeRedirectComponent } from './account/shared/stripe-redirect/stripe-redirect.component';
import { SsoComponent } from './account/sso/sso.component';
import { StepOneComponent } from './account/upgrade/step-one/step-one.component';
import { StepThreeComponent } from './account/upgrade/step-three/step-three.component';
import { StepTwoComponent } from './account/upgrade/step-two/step-two.component';
import { SubCardComponent } from './account/upgrade/sub-card/sub-card.component';
import { UpgradeComponent } from './account/upgrade/upgrade.component';
import { AdminShellComponent } from './admin/admin-shell/admin-shell.component';
import { AdminHomeComponent } from './admin/home/home.component';
import { AdminNavComponent } from './admin/shared/nav/nav.component';
import { StripeComponent } from './admin/stripe/stripe.component';
import { AdminUserDetailComponent } from './admin/users/user-detail/user-detail.component';
import { AdminUsersComponent } from './admin/users/users.component';
import { AppComponent } from './app.component';
import { GoToLinkDirective } from './directives/go-to-link.directive';
import { AdminGuard } from './guards/admin.guard';
import { AnonymousGuard } from './guards/anonymous.guard';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { ConfirmNavigationGuard } from './guards/confirm-navigation.guard';
import { HomeComponent } from './home/home/home.component';
import { MainComponent } from './home/main/main.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { ServerErrorComponent } from './layout/server-error/server-error.component';
import { UnauthorizedComponent } from './layout/unauthorized/unauthorized.component';
import { UnavailableComponent } from './layout/unavailable/unavailable.component';
import { LogInterceptor } from './middleware/logger.interceptor';
import { RequestInterceptor } from './middleware/request.interceptor';
import { LinkPipe } from './pipes/link.pipe';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { AppShellComponent } from './scormify/v1/app-shell/app-shell.component';
import { BulkImportComponent } from './scormify/v1/bulk/bulk-import/bulk-import.component';
import { BulkComponent } from './scormify/v1/bulk/bulk.component';
import { HubComponent } from './scormify/v1/hub/hub.component';
import { ObjectDetailComponent } from './scormify/v1/hub/object-detail/object-detail.component';
import { NewComponent } from './scormify/v1/new/new.component';
import { CompletionComponent } from './scormify/v1/shared/completion/completion.component';
import { CreateShellComponent } from './scormify/v1/shared/create-shell/create-shell.component';
import { DocumentComponent } from './scormify/v1/shared/document/document.component';
import { NewShellComponent } from './scormify/v1/shared/new-shell/new-shell.component';
import { NewVideoComponent } from './scormify/v1/shared/new-video/new-video.component';
import { PowerpointComponent } from './scormify/v1/shared/powerpoint/powerpoint.component';
import { MultipleChoiceComponent } from './scormify/v1/shared/quizes/question/multiplechoice/multiplechoice.component';
import { MultipleSelectComponent } from './scormify/v1/shared/quizes/question/multipleselect/multipleselect.component';
import { QuestionComponent } from './scormify/v1/shared/quizes/question/question.component';
import { TipComponent } from './scormify/v1/shared/quizes/question/tip/tip.component';
import { TrueFalseComponent } from './scormify/v1/shared/quizes/question/truefalse/truefalse.component';
import { VideoComponent } from './scormify/v1/shared/quizes/video.component';
import { StepComponent } from './scormify/v1/shared/step/step.component';
import { StreamingComponent } from './scormify/v1/shared/streaming/streaming.component';
import { UploaderComponent } from './scormify/v1/shared/uploader/uploader.component';
import { VimeoComponent } from './scormify/v1/shared/vimeo/vimeo.component';
import { YoutubeComponent } from './scormify/v1/shared/youtube/youtube.component';
import { AccountService } from './services/account.service';
import { AppService } from './services/app.service';
import { LinksService } from './services/links.service';
import { CloudMediaValidatorDirective } from './shared/directives/cloud-media-validator.directive';
import { ConfettiDirective } from './shared/directives/confetti.directive';
import { EmailAvailableDirective } from './shared/directives/email-available.directive';
import { ThresholdDirective } from './shared/directives/threshold.directive';
import { ConfirmModalComponent } from './shared/modals/confirm-modal/confirm-modal.component';
import { ConfirmNavigationModalComponent } from './shared/modals/confirm-navigation-modal/confirm-navigation-modal.component';
import { QuizModalComponent } from './shared/modals/quiz-modal/quiz-modal.component';
import { QuizScoreModalComponent } from './shared/modals/quiz-score-modal/quiz-score-modal.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { metaReducers, reducers } from './store/reducers';
import { ManageStylesComponent } from './styles/manage-styles/manage-styles.component';
import { StyleEditorComponent } from './styles/style-editor/style-editor.component';
import { StyleShellComponent } from './styles/style-shell/style-shell.component';
import { OkModalComponent } from './shared/modals/ok-modal/ok-modal.component';
import { WindowRef } from './window';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';
import { ScrolltopComponent } from './shared/scrolltop/scrolltop.component';
import { ClipboardModule } from 'ngx-clipboard';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        LoginComponent,
        RegisterComponent,
        MainComponent,
        ResetPasswordComponent,
        ForgotPasswordComponent,
        ServerErrorComponent,
        NotFoundComponent,
        EmailAvailableDirective,
        HomeComponent,
        ProfileComponent,
        GenerateApiKeyComponent,
        ProfileFormComponent,
        PasswordInputComponent,
        SsoComponent,
        UnavailableComponent,
        AdminHomeComponent,
        UnauthorizedComponent,
        AdminNavComponent,
        AdminUsersComponent,
        AdminShellComponent,
        AdminUserDetailComponent,
        ManageStylesComponent,
        NewComponent,
        HubComponent,
        CompletionComponent,
        StreamingComponent,
        VimeoComponent,
        YoutubeComponent,
        DocumentComponent,
        PowerpointComponent,
        AppShellComponent,
        NewShellComponent,
        CreateShellComponent,
        VideoComponent,
        SlugifyPipe,
        CloudMediaValidatorDirective,
        StyleShellComponent,
        LinkPipe,
        StyleEditorComponent,
        GoToLinkDirective,
        ConfirmNavigationModalComponent,
        ConfirmModalComponent,
        SpinnerComponent,
        ObjectDetailComponent,
        UploaderComponent,
        ManageSubscriptionButtonComponent,
        UpgradeComponent,
        SubCardComponent,
        StepOneComponent,
        StepTwoComponent,
        PaymentFormComponent,
        StepThreeComponent,
        StripeRedirectComponent,
        StepComponent,
        LogoutComponent,
        ConfettiDirective,
        StripeComponent,
        AuthenticateComponent,
        ThresholdDirective,
        BulkComponent,
        BulkImportComponent,
        NewVideoComponent,
        QuestionComponent,
        MultipleChoiceComponent,
        MultipleSelectComponent,
        TrueFalseComponent,
        TipComponent,
        QuizModalComponent,
        QuizScoreModalComponent,
        OkModalComponent
    ],
    bootstrap: [AppComponent],
    imports: [CommonModule,
        BrowserModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        BrowserAnimationsModule,
        QuillModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-top-center',
            preventDuplicates: true,
            closeButton: true,
        }),
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreDevtoolsModule.instrument(),
        RecaptchaV3Module,
        NgxDatatableModule,
        NgSelectModule,
        FontAwesomeModule,
        AppRoutingModule,
        MonacoEditorModule,
        ClipboardModule,
        NgxGoogleAnalyticsModule.forRoot('G-D7F52YCLMZ'),
        NgxGoogleAnalyticsRouterModule,
        NgxFileDropModule,
        ScrolltopComponent
    ]
    , providers: [
        AccountService,
        LinksService,
        AppService,
        AuthenticatedGuard,
        AnonymousGuard,
        ConfirmNavigationGuard,
        AdminGuard,
        NgbActiveModal,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LogInterceptor,
            multi: true,
        },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LeMoLoUAAAAAABvwKLlCZ9HjAJoi1VciN9TwPR3' },
        WindowRef,
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule { }
