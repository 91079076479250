import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { constants } from '../../../assets/constants/constants';
import { environment } from './../../../environments/environment';
import { AwsResponse } from './../../models/aws-response';
import { MuseResponse } from './../../models/muse-response';

export class APIUploader {
  endpoint = `${environment.api}/${this.path}`;
  constructor(private http: HttpClient, private path: string) {}

  public uploadFile(file: File) {
    const form = new FormData();
    form.append('file0', file);

    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    const req = new HttpRequest('POST', this.endpoint, form, {
      reportProgress: true,
      headers: headers,
    });

    return this.http
      .post<AwsResponse>(this.endpoint, form, {
        headers,
      })
      .pipe(take(1));
  }

  public uploadToMuse(file: File) {
    const form = new FormData();
    form.append('file', file);
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    const req = new HttpRequest('POST', `${environment.subService}${this.path}`, form, {
      reportProgress: true,
      headers: headers,
    });
    return this.http
      .post<MuseResponse>(`${environment.subService}${this.path}`, form, {
        headers,
      })
      .pipe(take(1));
  }

  public removeFromMuse(fid: string) {
    return this.http.delete(`${environment.api}/scormify/file/muse?fid=${fid}`);
  }

  public removeFile(aws: AwsResponse) {
    let headers = new HttpHeaders();
    headers = headers.set(constants.header_aws_delete, aws.Key);
    return this.http.delete(this.endpoint, { headers: headers }).pipe(take(1));
  }
}
