<app-spinner *ngIf="loading"></app-spinner>
<section id="user-detail" *ngIf="!loading">
  <div class="row">
    <div class="col-12">
      <div class="row" *ngIf="user.status.deleted">
        <div class="col-12 mb-2">User was soft deleted. They no longer can reset their account.</div>
      </div>
      <app-profile-form [userId]="id"></app-profile-form>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 mb-3">
      <h1>Comp Sub</h1>
      <p>This functionality is lightly tested - ensure the results.</p>
      <div>
        <ul class="nav flex-direction-row">
          <li class="nav-item">
            <button class="btn btn-primary" (click)="compBasic()">Comp Basic</button>
            <button class="btn btn-primary" (click)="compPro()">Comp Pro</button>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-12">
      <h1>Subscription Information</h1>
      <div class="row">
        <div class="col-2 strong text-right">Plan</div>
        <div class="col-8">{{user.subscription.plan}}</div>
      </div>
      <div class="row">
        <div class="col-2 strong text-right">CustomerId</div>
        <div class="col-8">{{user.subscription.customerId}}</div>
      </div>
      <div class="row">
        <div class="col-2 strong text-right">SubscriptionId</div>
        <div class="col-8">{{user.subscription.SubscriptionId}}</div>
      </div>
      <div class="row">
        <div class="col-2 strong text-right">Lock Status</div>
        <div class="col-8">{{user.subscription.lockStatus.isLocked}}</div>
      </div>
      <div class="row">
        <div class="col-2 strong text-right">Lock Reason</div>
        <div class="col-8">{{user.subscription.lockStatus.lockReason}}</div>
      </div>
      <div class="row">
        <div class="col-2 strong text-right">Last Refresh</div>
        <div class="col-8">{{user.subscription.lastRefresh | date}}</div>
      </div>
      <div class="row">
        <div class="col-2 strong text-right">Next Refresh</div>
        <div class="col-8">{{user.subscription.nextRefresh | date}}</div>
      </div>
      <h3 class="mt-3">Buckets</h3>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Serial</th>
            <th scope="col">Conversions</th>
            <th scope="col">Used</th>
            <th scope="col">Created</th>
            <th scope="col">Expires</th>
            <th scope="col">Is Expired</th>
            <th scope="col">Is Initial Allowance</th>
            <th scope="col">Is Monthly Allowance</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let bucket of user.subscription.buckets">
            <th scope="row">{{bucket.serial}}</th>
            <td>{{bucket.conversions}}</td>
            <td>{{bucket.used}}</td>
            <td>{{bucket.createdAt | date}}</td>
            <td>{{bucket.expires | date}}</td>
            <td>{{bucket.isExpired}}</td>
            <td>{{bucket.isInitialAllowance}}</td>
            <td>{{bucket.isMonthlyAllowance}}</td>
          </tr>

        </tbody>
      </table>
      <h3 class="mt-5">Usage</h3>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Conversions this Cycle</th>
            <th scope="col">Cycle Allowance</th>
            <th scope="col">Total Conversions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">{{user.subscription.usage.conversionsThisCycle}}</td>
            <td>{{user.subscription.usage.cycleAllowance}}</td>
            <td>{{user.subscription.usage.totalConversions}}</td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ul class="nav">
        <li class="nav-item">
          <button class="btn btn-primary nav-link" (click)="goBack()">Back</button>
        </li>
      </ul>
    </div>
  </div>
</section>