import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { ApiResponse } from '../../models/api-response.model';
import { User } from '../../models/user.model';
import { AccountService } from '../../services/account.service';
import { LinksService } from '../../services/links.service';
import { texts } from './../../../assets/texts/texts';
import { constants } from './../../../assets/constants/constants';
import { ReplaySubject } from 'rxjs';

declare var gtag: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [AccountService],
  standalone: false
})
export class LoginComponent implements OnInit, OnDestroy {
  error: ApiResponse;
  user: User;
  persistLogin: Boolean;
  confirmed: Boolean;
  reset: Boolean;
  case: string;
  message: string;
  texts = texts;
  faSpinner = faSpinner;
  loading = false;
  reloadDone: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  errorMessage: string;

  constructor(
    private svc: AccountService,
    private links: LinksService,
    private router: Router,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit() {
    this.user = new User();
    this.user.profile = {
      email: '',
    };
    this.user.password = '';
    this.setPersistLogin();
    this.confirmed = !!this.route.snapshot.paramMap.get('confirmed');
    this.reset = !!this.route.snapshot.queryParamMap.get('reset');
    this.case = this.route.snapshot.queryParamMap.get('case');
    this.reloadDone = this.route.snapshot.queryParamMap.get('reload');
    if (this.case) {
      switch (this.case) {
        case 'inactivity':
          this.message = texts.message_login_inactivity;
          break;
        case 'expired-token':
          this.message = texts.message_login_expired_token;
          break;
        case 'sign-out':
          this.message = texts.message_login_sign_out;
          break;
      }
      if (!this.reloadDone) {
        window.location.search = '?reload=1&case=' + this.case;
      }
    }
  }

  setPersistLogin() {
    this.persistLogin = true;
    if (this.svc.getPeristLogin() === false) {
      this.persistLogin = false;
    }
  }

  clearAlerts() {
    this.confirmed = null;
    this.reset = null;
    this.case = null;
  }

  onSubmit(f: NgForm) {
    localStorage.removeItem(constants.ls_token);
    this.loading = true;
    this.clearAlerts();
    this.recaptchaV3Service
      .execute('login')
      .toPromise()
      .then((token) => this.login(token))
      .catch((error) => {
        this.errorMessage = 'Failed Recaptcha check.   Please enable cookies and try again.';
      });
  }

  login(token: string) {
    this.user.recaptchaToken = token;
    localStorage.setItem(constants.ls_persist_login, this.persistLogin.toString());
    this.svc
      .login(this.user, token)
      .toPromise()
      .then((res) => {
        gtag("event", "login", {
          method: "email"
        });
        this.links.resolve(res);
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
        this.error = err.error.message || err.error;
        if (err.error.action) {
          this.links.resolve(err.error);
        }
      });
  }

  forgotPassword() {
    return this.router.navigate(['forgot-password']);
  }

  register() {
    return this.router.navigate(['register']);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
