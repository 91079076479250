<form (ngSubmit)="createStripeToken(f)" class="checkout" #f="ngForm" novalidate>
    <div class="form-group">
        <label for="billing-email">Billing Email</label>
        <input class="form-control" type="email" name="billing-email"  id="billing-email" autocomplete="email" [(ngModel)]="billingEmail" required>
    </div>
    <div class="form-group">
        <label for="name-on-card">Name on Card</label>
        <input class="form-control" type="text" name="name-on-card"  id="name-on-card"  [(ngModel)]="billingName" required>
    </div>
    <label for="card-info">Card Info</label>
    <div id="form-field">
            <div id="card-info" class="box-sh" #cardInfo></div>
            <div id="card-errors" role="alert" *ngIf="cardError">
                {{ cardError }}
            </div>
            <p class="mt-5 small">By confirming your subscription, you allow Knowledge Anywhere to charge your card for this payment and future payments until the cancellation of the subscription.</p>
            <button id="submit-button" type="submit" class="btn btn-primary mt-3">
                {{getButtonText() | titlecase}}
            </button>
        </div>
</form>
