<nav
     class="navbar navbar-expand-xl navbar-dark container-fluid"
     [ngClass]="{ ' bg-ka-dark-grey': !isCollapsed }">
  <a class="navbar-brand" href="https://www.knowledgeanywhere.com" target="_blank">
    <img
         src="./../../../assets/images-new/scormify-logo-light.png"
         class="d-inline-block align-top"
         alt="scormify-logo-light-image"
         loading="lazy" />
  </a>
  <button
          class="navbar-toggler"
          type="button"
          (click)="isCollapsed = !isCollapsed"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
    <fa-icon [icon]="faBars" class="text-ka-green"></fa-icon>
  </button>
  <div
       class="collapse navbar-collapse justify-content-sm-center"
       id="navbarSupportedContent"
       [ngbCollapse]="isCollapsed">
    <ul class="navbar-nav d-flex me-auto ms-auto justify-content-center nav-links" *ngIf="!isCollapsed">
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0);" (click)="scrollTo('#features')">Features <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0);" (click)="scrollTo('#resources')">Resources</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0);" (click)="scrollTo('#pricing')">Pricing</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="login">Login</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-ka-turquoise" routerLink="register">Register</a>
      </li>
    </ul>
    <div class="my-2 my-lg-0 d-flex ms-auto" *ngIf="isCollapsed">
      <ul class="nav nav-links">
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0);" (click)="scrollTo('#features')">Features <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0);" (click)="scrollTo('#resources')">Resources</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0);" (click)="scrollTo('#pricing')">Pricing</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="login">Login</a>
        </li>
        <li class="nav-item">
          <a class="btn btn-lg btn-ka-turquoise" href="#" routerLink="register">Register</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="jumbotron jumbotron-fluid">
  <div class="container justify-content-center">
    <div class="row">
      <div class="col-lg-6 intruduction-text-container">
        <h1 class="text-light mb-5 font-weight-semibold heading-xlarge">
          The easiest and
          cheapest way to get
          content into your LMS
        </h1>
        <h2 class="text-light heading-large">
          Try our SCORM conversion tool for free!<br />
          Transform videos, PDFs, and PowerPoints
          into beautiful courses in three easy steps.
        </h2>
      </div>
      <div class="col-lg-6 pt-4 try-for-free-container">
        <h4 class="text-light text-center text-uppercase position-relative">
          <a href="#" routerLink="register">
            <h1 class="heading-xlarge text-ka-green font-weight-semibold text-center">Try for free!</h1>
            <div>
              <img src="../../../assets/images-new/icon-upload.png" alt="icon-image" width="110">
              <h1 class="font-weight-semibold d-inline-block align-middle upload-your-files">Upload<br />your files</h1>
            </div>
          </a>
          <img src="../../../assets/images-new/arrow-1.png" alt="arrow-1-image" class="arrow arrow-1">
          <img src="../../../assets/images-new/arrow-2.png" alt="arrow-2-image" class="arrow arrow-2">
          <img src="../../../assets/images-new/arrow-3.png" alt="arrow-3-image" class="arrow arrow-3">
        </h4>
      </div>
    </div>
  </div>
</div>
<div id="content-container" class="container-fluid">
  <!-- #region features -->
  <div class="row content-section justify-content-center text-darkgrey" id="features">
    <div class="col-lg-5 screenshot-container position-relative flex-centered">
      <img src="../../../assets/images-new/scormify-1.png" alt="scormify-1-image" class="screenshot img-fluid img-shadow">
      <img src="../../../assets/images-new/arrow-4.png" alt="arrow-4-image" class="arrow arrow-4">
    </div>
    <div class="col-lg-5 ">
      <h2 class="fw-bold text-darkgrey heading-large">Quickly convert your materials<br class="d-none d-sm-none d-md-block" />
        into SCORM compliant eLearning</h2>

      <ul class="feature-list list-unstyled">
        <li>
          <div class="feature-icon-container">
            <img src="../../../assets/images-new/icon-pdf.png" alt="pdf-icon" class="feature-icon">
          </div>
          <div class="feature-description">
            <h3 class="font-large-1">PDF & Documents</h3>
          </div>
        </li>
        <li>
          <div class="feature-icon-container align-top">
            <img src="../../../assets/images-new/icon-video.png" alt="video-icon" class="feature-icon">
          </div>
          <div class="feature-description font-large-1">
            <h3>MP4 Videos, Streaming, Vimeo, YouTube</h3>
            <p>Includes ability to add interactive quizzing to direct video uploads!</p>
          </div>
        </li>
        <li class="pt-0">
          <div class="feature-icon-container">
            <img src="../../../assets/images-new/icon-powerpoint.png" alt="ppt-icon" class="feature-icon">
          </div>
          <div class="feature-description">
            <h3 class="font-large-1">PowerPoints</h3>
          </div>
        </li>
      </ul>
    </div>
    <div class="w-100 spacing-70"></div>

    <div class="col-lg-5 ">
      <h2 class="fw-bold text-darkgrey heading-large">Use our 1, 2, 3 steps to customize<br class="d-none d-sm-none d-md-block" />
        your eLearning course</h2>

      <ul class="mt-4 ms-4" class="steps-list font-large-1">
        <li>Specify title, length, and language</li>
        <li>Set completion rate</li>
        <li>Add styles (regular and dark mode)</li>
      </ul>
    </div>
    <div class="col-lg-5 screenshot-container flex-centered">
      <img src="../../../assets/images-new/scormify-2.png" alt="scormify-2-image" class="screenshot img-fluid img-shadow">
    </div>

    <div class="w-100 spacing-70"></div>

    <div class="col-lg-5 screenshot-container flex-centered">
      <iframe width="700" height="393" class="video-fluid img-shadow" src="https://www.youtube.com/embed/HgWVD6y_MjQ" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    <div class="col-lg-5 ">
      <h2 class="fw-bold text-darkgrey heading-large mt-5">Add interactive quizzing to video uploads</h2>
      <div class="font-large-1 padding-lg-100 mt-5">
        Easily convert video files while inserting
        quiz questions at key intervals, making
        your videos SCORM compliant, ready to
        upload on any training platform, and an
        interactive quiz for your end learners.
      </div>
    </div>

  </div>
  <!-- #endregion -->

  <div class="row content-section bg-ka-dark-blue justify-content-center">
    <div class="col-sm-10 col-md-10 col-lg-8 text-light py-5 position-relative">
      <h2 class="d-block text-center text-light-1 font-larger">
        <p>Check it out for yourself at no cost!</p>
        <p>The first three conversions are free.</p>
      </h2>
      <a
         class="btn btn-large btn-block btn-try-free font-larger mt-5 d-block text-center"
         href="#"
         routerLink="register">Try free</a>
      <img src="../../../assets/images-new/arrow-5.png" alt="arrow-5-image" class="arrow arrow-5">
      <img src="../../../assets/images-new/arrow-6.png" alt="arrow-6-image" class="arrow arrow-6">

    </div>
  </div>

  <!-- #region resources -->
  <div class="row content-section justify-content-center" id="resources">
    <div class="col-sm-11 col-md-9">
      <h2 class="d-block text-center text-darkgrey font-weight-semibold text-uppercase">Frequently asked questions</h2>
      <div class="w-100 spacing-70"></div>

      <div class="row d-flex justify-content-center font-large text-darkgrey">
        <div class="col-lg-6 faq-section">
          <p>
            <span class="font-weight-semibold">What's a conversion?</span> A conversion is defined
            as transforming a single object into a SCORM
            course. Once you have converted an object, it
            will count towards your allotment and may be
            downloaded an unlimited amount of times.
          </p>
          <p>
            <span class="font-weight-semibold">What is SCORM?</span> SCORM, or “Shareable
            Content Object Reference Model,” is a
            collection of standards and specifications
            for eLearning technology, and serves as a
            guideline or reference point for how
            developers can successfully use different
            standards in combination with one another.
            To learn more about SCORM, <a href="https://help.knowledgeanywhere.com/what-is-a-scorm-file" rel="noopener" target="_blank" class="link-blue">click here</a>.
          </p>
          <p>
            <span class="font-weight-semibold">How can I bulk upload items?</span> To learn how to
            bulk upload, watch this informative video: <a href="https://www.knowledgeanywhere.com/resources/video-detail/how-to-bulk-upload-training-content-on-scormify" target="_blank" class="link-blue">How
              to Bulk Upload Training Content on Scormify</a>
          </p>
          <p>
            <span class="font-weight-semibold">Can I see my past downloads?</span> Yes! Scormify
            has a "History" section in the top menu bar,
            which shows all previously converted objects.
          </p>
          <p>
            <span class="font-weight-semibold">Do you have group plans for larger teams?</span> Yes! <a href="mailto:support@knowledgeanywhere.com" class="link-blue">Contact us</a> to see group pricing.
          </p>
        </div>
        <div class="col-lg-6 faq-section">
          <p>
            <span class="font-weight-semibold">Can I see a demo of the system?</span><br class="d-none d-sm-none d-md-block" />
            Yes! Watch this video to see how
            Scormify works: <a href="https://www.knowledgeanywhere.com/resources/video-detail/scormify-demo-how-to-turn-your-video-content-into-a-scorm-course" target="_blank" class="link-blue">How To Turn Your
              Video Content Into A SCORM Course</a>
          </p>
          <p>
            <span class="font-weight-semibold">Are my uploaded documents secure?</span><br class="d-none d-sm-none d-md-block" />
            Documents are stored on Amazon S3 for
            future retrieval. Documents are stored in such
            a way they are not publicly accessible, and
            cannot be downloaded outside of Scormify.
          </p>
          <p>
            <span class="font-weight-semibold">How can I get support?</span> If you need support,
            visit our <a href="https://help.knowledgeanywhere.com/scorm-conversion-tool-scormify" rel="noopener" target="_blank" class="link-blue">Scormify Help Desk</a> here or contact
            <a href="mailto:support@knowledgeanywhere.com" target="_blank" class="link-blue">support&#64;knowledgeanywhere.com</a>
          </p>
          <p>
            <span class="font-weight-semibold">How can I add interactive quizzing to a video?</span>
            When choosing the "upload video file" option, look for a checkmark that says "Add Quiz." This allows you to insert quiz questions to your video at key intervals. To watch a full demo video on the process, click <a href="https://youtu.be/HgWVD6y_MjQ" target="_blank">here</a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion -->
  <!-- #region pricing -->
  <div class="row content-section justify-content-center bg-white" id="pricing">
    <div class="col-sm-10 col-md-8 col-lg-7 position-relative">
      <img src="../../../assets/images-new/arrow-7.png" alt="arrow-7-image" class="arrow arrow-7">
      <img src="../../../assets/images-new/arrow-8.png" alt="arrow-8-image" class="arrow arrow-8">

      <h2 class="d-block text-center text-darkgrey fw-bold font-larger">$19/mo.</h2>
      <h2 class="d-block text-center text-darkgrey fw-bold text-uppercase">Pro version includes</h2>
      <div class="row justify-content-center d-flex">
        <div class="col-lg-6">
          <ul class="list-checkbox">
            <li class="fw-bold">Unlimited conversions</li>
            <li>Custom theming</li>
            <li>Download history</li>
            <li>Vimeo conversions</li>
            <li>YouTube conversions</li>
          </ul>
        </div>
        <div class="col-lg-6">
          <ul class="list-checkbox">
            <li>PDF/Word conversions</li>
            <li>PowerPoint conversions</li>
            <li>Upload and convert video files</li>
            <li>Add quizzing to videos</li>
            <li>Bulk conversions</li>
          </ul>
        </div>
      </div>
      <div class="try-for-free-here-container text-center">
        <a class="btn btn-lg btn-ka-green text-uppercase mb-3" href="#" routerLink="register">Try for free here</a><br />
        <a href="mailto:support@knowledgeanywhere.com" class="link-blue">Contact us</a> to inquire about team pricing.
      </div>
      <div class="w-100 spacing-70"></div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-9">
      <div class="row justify-content-center d-flex">
        <div class="col-lg-6 screenshot-container flex-centered">
          <img src="../../../assets/images-new/scormify-3b.png" alt="scormify-3-image" class="img-fluid">
        </div>
        <div class="col-lg-6 other-links position-relative">
          <img src="../../../assets/images-new/arrow-9.png" alt="arrow-9-image" class="arrow arrow-9">
          <h2 class="fw-bold text-darkgrey heading-large">A Training Ecosystem<br class="d-none d-sm-none d-md-block" />
            to fit all your needs</h2>
          <p class="text-darkgrey">
            Want more awesome training tools with free trials? Utilize <a href="https://knowledgeanywhere.com/lms-features/ai-assessments/" target="_blank" class="link-blue">quiz-based microlearning</a>
            and easily track <a href="https://knowledgeanywhere.com/lms-features/reporting-and-analytics/" target="_blank" class="link-blue">employee progress</a>
            by trying our sleek <a href="https://knowledgeanywhere.com/learning-management-system-lms/" target="_blank" class="link-blue">LMS platform</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion -->
</div>