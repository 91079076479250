import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { forkJoin, Observable, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { texts } from '../../../assets/texts/texts';
import { ApiResponse } from '../../models/api-response.model';
import { AwsResponse } from '../../models/aws-response';
import { MuseResponse } from '../../models/muse-response';
import { Locale, ObjectType } from '../../models/object-type.model';
import { Completion, StreamingVideo, Vimeo, Youtube, PDF, PowerPoint, NewVideo } from '../../models/scormify.model';
import { Style } from '../../models/style.model';
import { SlugifyPipe } from '../../pipes/slugify.pipe';
import { Scormifyv1Service } from '../../services/scormifyv1.service';
import { StylesService } from '../../services/styles.service';
import { mediaValidatorFn } from '../../shared/functions/media-validator.fn';
import { IState } from '../../store/reducers';
import { selectApp } from '../../store/selectors/app.selectors';
import { IApp } from '../../store/state/app.state';


@Component({
  template: '', providers: [SlugifyPipe],
  standalone: false
})
export class BaseScormifyComponent implements OnDestroy {
  styles: Array<Style>;
  createForm: FormGroup;
  types: Array<ObjectType>;
  locales: Array<Locale>;
  texts = texts;
  loading = true;
  state: IApp;
  appState$: Observable<IApp>;
  destroyed$: Subject<void> = new Subject();
  hydrated$: Subject<boolean> = new Subject();
  goNext: boolean = false;
  muse: MuseResponse;
  constructor(public svc: Scormifyv1Service, public slugifyPipe: SlugifyPipe, public fb: FormBuilder, public stylesService: StylesService, public _store: Store<IState>) {
    this.appState$ = this._store.pipe(select(selectApp));
  }


  setData(bulkOnly = false) {
    return forkJoin({ locales: this.svc.getLocales(), styles: this.stylesService.getStyles(true), types: this.svc.getTypes() }).pipe(take(1), tap(val => {
      if (bulkOnly) {
        this.types = val.types.payload.filter(t => t.allowBulk);
      } else {
        this.types = val.types.payload;
      }
      this.styles = val.styles;
      this.locales = val.locales.payload;

    }));
  }

  createFormGroup(withDefaults = false) {
    const defaultType = this.types[0];
    const defaultStyle = this.styles[0];
    return this.fb.group({
      type: [withDefaults ? defaultType.key : null, [this.isValidStyle(), Validators.required]],
      descriptors: this.fb.group({
        title: ['', [Validators.required]],
        filename: ['', [Validators.required]]
      }),
      completion: this.fb.group({
        threshold: [80, [Validators.min(0), Validators.max(100)]],
        disableScrubber: [false],
        enableBookmarks: [true],
        completeOnDownload: [true],
        addOverlayQuiz: [false]
      }),
      locale: ['en'],
      allowLangOverride: [true],
      forceWindowSize: [false],
      windowSize: this.fb.group({
        width: [1475],
        height: [900],
      }),
      style: [withDefaults ? defaultStyle.id : null, [this.isValidStyle(), Validators.required]],
    });

  }

  isValidStyle(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      return control.value !== 'DEFAULT' ? null : { required: true };
    };
  }

  getTypes() {
    this.svc
      .getTypes()
      .pipe(take(1))
      .subscribe((res: ApiResponse) => {
        this.types = res.payload;
      });
  }

  watchSlugify(value, form: FormGroup) {
    form.patchValue({
      descriptors: {
        filename: this.slugifyPipe.transform(value),
      },
    });
  }


  addStreamingGroup(form: FormGroup) {
    form.addControl(
      'streamingVideo',
      this.fb.group({
        posterUrl: this.fb.control('', [mediaValidatorFn('poster')]),
        hlsUrl: this.fb.control('', [mediaValidatorFn('hls')]),
        mp4Url: this.fb.control('', Validators.required),
        webmUrl: this.fb.control('', [mediaValidatorFn('webm')]),
        forceVideoSize: this.fb.control(false),
        responsive: this.fb.control(false),
        fluid: this.fb.control(false),
        videoSize: this.fb.group({
          height: this.fb.control(720),
          width: this.fb.control(1280),
        }),
        aspectRatio: this.fb.control('16:9'),
        autoplay: this.fb.control(false),
      })
    );
  }
  addVimeoGroup(form: FormGroup) {
    form.addControl(
      'vimeo',
      this.fb.group({
        url: this.fb.control('', [mediaValidatorFn('vimeo'), Validators.required]),
        id: this.fb.control(''),
      })
    );
  }
  addYoutubeGroup(form: FormGroup) {
    form.addControl(
      'youtube',
      this.fb.group({
        url: this.fb.control('', [mediaValidatorFn('youtube'), Validators.required]),
        id: this.fb.control(''),
      })
    );
  }
  addPPTGroup(form: FormGroup) {
    form.addControl(
      'ppt',
      this.fb.group({
        file: this.fb.group({
          Location: this.fb.control('', Validators.required),
          ETag: this.fb.control(''),
          Key: this.fb.control(''),
          Bucket: this.fb.control(''),
        }),
      })
    );
  }

  addPdfGroup(form: FormGroup) {
    form.addControl(
      'pdf',
      this.fb.group({
        allowDocumentDownload: this.fb.control(false),
        file: this.fb.group({
          Location: this.fb.control('', Validators.required),
          ETag: this.fb.control(''),
          Key: this.fb.control(''),
          Bucket: this.fb.control(''),
        }),
      })
    );
  }

  getTypeKey(type: ObjectType | string) {
    if (!type) return 'DEFAULT';
    return typeof type === 'string' ? type : type.key;
  }

  setFormByType(form: FormGroup, type: ObjectType | string, reset: boolean) {
    form.removeControl('vimeo');
    form.removeControl('pdf');
    form.removeControl('ppt');
    form.removeControl('youtube');
    form.removeControl('streamingVideo');
    this.createForm.removeControl('muse');
    switch (this.getTypeKey(type)) {
      case 'VIDEO_STREAMING':
        this.addStreamingGroup(form);
        break;
      case 'VIDEO_VIMEO':
        this.addVimeoGroup(form);
        break;
      case 'VIDEO_YOUTUBE':
        this.addYoutubeGroup(form);
        break;
      case 'PPT':
        this.addPPTGroup(form);
        break;
      case 'PDF':
        this.addPdfGroup(form);
        break;
      case 'MUSE':
        this.addVideoUploadGroup();
        break;
    }
    if (reset) {
      this.setInitialValues(form);
    }

    form.updateValueAndValidity();
    form.markAsPristine();
    this.loading = false;
  }

  setInitialValues(form: FormGroup) {
    form.get('descriptors.title').reset();
    form.get('descriptors.filename').reset();
    const storedStyleId = localStorage.getItem('style-id');
    if (this.styles.find((x) => x.id === storedStyleId)) {
      form.get('style').setValue(storedStyleId);
    } else {
      form.get('style').setValue(this.styles[0].id);
    }

    const defaultLocale = this.locales.find((x) => x.locale === 'en').locale;
    form.get('locale').setValue(defaultLocale);
  }

  setWindowSize(form: FormGroup, type: ObjectType) {
    form.patchValue({
      windowSize: {
        width: type.windowSize.width,
        height: type.windowSize.height,
      },
    });
  }

  onCompletion(form: FormGroup, completion: Completion) {
    if (completion.addOverlayQuiz) {
      this.goNext = true;
      completion.enableBookmarks = false;
    } else {
      this.goNext = false;
    }
    form.patchValue({
      completion: completion,
    });
  }

  onStreaming(form: FormGroup, video: StreamingVideo) {
    form.patchValue({
      streamingVideo: video,
    });
  }

  onVimeo(form: FormGroup, vimeo: Vimeo) {
    form.patchValue({
      vimeo: vimeo,
    });
  }

  onYoutube(form: FormGroup, youtube: Youtube) {
    form.patchValue({
      youtube: youtube,
    });
  }

  onPDF(form: FormGroup, pdf: PDF) {
    if (pdf.file?.Key) {
      form.patchValue({
        pdf: pdf,
      });
    } else {
      pdf.file = new AwsResponse();
    }
  }

  onRemovePDF(form: FormGroup) {
    form.get('pdf').reset();
  }

  onPowerPoint(form: FormGroup, ppt: PowerPoint) {
    if (ppt.file?.Key) {
      form.patchValue({
        ppt: ppt,
      });
    } else {
      ppt.file = new AwsResponse();
    }
  }

  onRemovePowerPoint(form: FormGroup) {
    form.get('ppt').reset();
  }

  onType(form: FormGroup, type: ObjectType, reset: boolean) {
    this.setFormByType(form, type, reset);
    form.patchValue({
      type: type.key,
    });
  }

  /** Muse */


  addVideoUploadGroup() {
    this.createForm.addControl(
      'muse',
      this.fb.group({
        file: this.fb.group({
          fid: this.fb.control(''),
          svid: this.fb.control(''),
          filename: this.fb.control(''),
          title: this.fb.control(''),
          description: this.fb.control(''),
          url: this.fb.control('', Validators.required),
          duration: this.fb.control(''),
          width: this.fb.control(''),
          height: this.fb.control(''),
          size: this.fb.control(''),
          tcreated: this.fb.control(''),
          visibility: this.fb.control(''),
          ingesting: this.fb.control(''),
          Location: this.fb.control(''),
          ETag: this.fb.control(''),
          Key: this.fb.control(''),
          Bucket: this.fb.control(''),
        }),
        forceVideoSize: this.fb.control(false),
        disableSubtitles: this.fb.control(false),
        disableSearch: this.fb.control(false),
        videoSize: this.fb.group({
          height: this.fb.control(720),
          width: this.fb.control(1280),
        }),
        aspectRatio: this.fb.control('16:9'),
      }),
    );
  }

  onUpload(muse: NewVideo) {
    this.muse = muse.file;
    if (!this.muse) {
      this.createForm.get('muse.file.url').reset();
    }
    if (muse) {
      this.createForm.patchValue({
        muse: muse,
      });
    } else {
      muse.file = new MuseResponse();
    }
  }

  onRemoveMuse() {
    this.createForm.get('muse').reset();
  }

  download(url) {
    const a = document.createElement('a')
    a.href = url
    a.download = '';
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }


  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}

