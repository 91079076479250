import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CheckoutItem, CheckoutItemType } from '../../../models/checkout-item';
import { selectApp } from '../../../store/selectors/app.selectors';
import { IState } from '../../../store/reducers';
import { environment } from '../../../../environments/environment';

declare var Stripe: any;

// https://medium.com/better-programming/payments-simplified-stripe-angular-express-4a88bf69f82e
// https://stripe.com/docs/js/element/mount
// https://www.digitalocean.com/community/tutorials/angular-stripe-elements

@Component({
    selector: 'app-payment-form',
    templateUrl: './payment-form.component.html',
    styleUrls: ['./payment-form.component.scss'],
    standalone: false
})
export class PaymentFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() item: CheckoutItem;
  @Output() token = new EventEmitter<object>();
  @ViewChild('cardInfo') cardInfo: ElementRef;
  card: any;
  cardHandler = this.onChange.bind(this);
  cardError: string;
  stripe: any;
  appState$ = this._store.pipe(select(selectApp));
  billingEmail: string = '';
  billingName: string = '';
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private cd: ChangeDetectorRef, private _store: Store<IState>) {}

  ngOnInit(): void {
    this.appState$.pipe(takeUntil(this.destroyed$)).subscribe((state) => {
      this.billingEmail = state.user.profile.email;
    });
  }

  ngOnDestroy() {
    if (this.card) {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngAfterViewInit() {
    this.initiateCardElement();
  }

  initiateCardElement() {
    this.stripe = Stripe(environment.sk);
    const elements = this.stripe.elements();
    this.card = elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  onChange({ error }) {
    if (error) {
      this.cardError = error.message;
    } else {
      this.cardError = null;
    }
    this.cd.detectChanges();
  }

  async createStripeToken(f: NgForm) {
    const { token, error } = await this.stripe.createToken(this.card, { name: this.billingName });
    if (token) {
      const payload = {
        token: token.id,
        billingEmail: this.billingEmail,
        billingName: this.billingName,
      };
      this.onSuccess(payload);
    } else {
      this.onError(error);
    }
  }

  onSuccess(token) {
    this.token.emit(token);
  }

  onError(error) {
    if (error.message) {
      this.cardError = error.message;
    }
  }

  getButtonText() {
    switch (this.item.itemType) {
      case CheckoutItemType.bucket:
        break;
      default:
        return `Subscribe to ${this.item.item.scormifySubscription.shortName}`;
    }
  }
}
