<section id="upgrade" class="container-fluid">
  <div class="loader" *ngIf="loading"></div>
  <div *ngIf="!loading">
    <div class="container mt-5">
      <app-step-one [subs]="scormifySubscriptions" (selection)="onSelection($event)" *ngIf="selectedStep === 1"></app-step-one>
    </div>
    <div class="container mt-5">
      <div *ngIf="selectedStep === 2">
        <app-step-two [selected]="subscriptionSelection" (success)="onSuccess($event)" (clear)="clear()"></app-step-two>
      </div>
    </div>
  
    <div class="container mt-5">
      <div *ngIf="selectedStep === 3">
        <app-step-three [selected]="subscriptionSelection"></app-step-three>
      </div>
    </div>
  </div>
</section>
