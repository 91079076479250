<section id="profile-form">
  <h1 class="display-5" *ngIf="!readonly">Profile</h1>
  <h1 class="display-5" *ngIf="readonly">User Detail</h1>
  <br>
  <div class="alert alert-danger" *ngIf="error">{{error}}</div>
  <div class="alert alert-success" *ngIf="message">{{message}}</div>
  <div class="row" *ngIf="loading">
    <div class="col">
      <app-spinner></app-spinner>
      </div>
    </div>
  <div class="row" *ngIf="!loading">
    <div class="col">
      <form novalidate [formGroup]="profileForm" (ngSubmit)="onSubmit(profileForm)">
        <div formGroupName="profile">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>First Name:</label>
                <input type="text" class="form-control" required placeholder="" id="name" formControlName="firstName">
                <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                  <div class="text-danger" *ngIf="firstName.errors.required">
                    First Name is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>Last Name:</label>
                <input type="text" class="form-control" required placeholder="" id="last" formControlName="lastName">
                <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                  <div class="text-danger" *ngIf="lastName.errors.required">
                    Last Name is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>Email:</label>
                <input type="email" class="form-control" required placeholder="" id="email" formControlName="email" autocomplete="username">
                <div *ngIf="email.invalid && (email.dirty || email.touched)">
                  <div class="text-danger" *ngIf="email.errors.required">
                    Email is required.
                  </div>
                  <div class="text-danger" *ngIf="email.errors.email">
                    Please provide a valid email.
                  </div>
                </div>
                <small id="emailHelp" class="form-text text-muted" *ngIf="!readonly">We'll never share your email with anyone else.</small>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>Company:</label>
                <input type="text" class="form-control" required placeholder="" id="company" formControlName="company">
                <div *ngIf="company.invalid && (company.dirty || company.touched)">
                  <div class="text-danger" *ngIf="company.errors.required">
                    company Name is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>{{texts.label_job_role}}:</label>
                <!--<input type="text" class="form-control" placeholder="" id="jobrole" formControlName="jobRole"> -->
                <select class="form-control" id="jobrole" formControlName="jobRole" #jobrole required>
                  <option value="" disabled>{{texts.form_placeholder}}</option>
                  <option *ngFor="let role of jobRoles" [ngValue]="role">{{role}}</option>
                </select>
                <small class="form-text text-muted">{{texts.form_optional}}</small>
              </div>
            </div>
          </div>
        </div>
        <div formGroupName="status">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" placeholder="" id="optin" formControlName="optInEmail">
                <label class="form-check-label">Opt in to marketing related emails.</label>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-sm-12">
            <ul class="list-inline" *ngIf="!readonly">
              <li class=" list-inline-item ">
                <button [disabled]="profileForm.invalid " class="btn btn-primary " type="submit ">Save</button>
              </li>
            </ul>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
