import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpClient) {}

  getPlans() {
    return this.http.get(`${environment.subService}/protected/subscriptions/plans`).pipe(take(1));
  }

  getCoupons() {
    return this.http.get(`${environment.subService}/protected/subscriptions/coupons`).pipe(take(1));
  }
}
