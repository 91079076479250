<div *ngIf="!showQuizes" class="row">
  <div class="col">
    <app-spinner *ngIf="loading && !createError"></app-spinner>

    <div *ngIf="loading && !createError">
      <p class="pScormLoader">{{texts.message_please_be_patient_scorm_is_prepared}}</p>
      <p class="pScormLoader">{{texts.message_dont_refresh_page}}</p>
    </div>

    <form novalidate [formGroup]="createForm" (ngSubmit)="onSubmit(createForm)" *ngIf="!loading && selectedType">
      <div formGroupName="descriptors">
        <div class="form-group">
          <label>{{texts.label_title}}
            <span [ngbTooltip]="texts.tooltip_title">
              <fa-icon [icon]="faQC"></fa-icon>
            </span>
          </label>
          <input id="descriptors-title" type="text" class="form-control" formControlName="title" #title
                 (change)="watchSlugify(title.value, createForm)" required />
          <small class="form-text"
                 [ngClass]="{'text-danger': title.invalid  && title?.errors?.required, 'text-muted': !title.invalid}">{{texts.form_required}}</small>
        </div>
        <input id="descriptors-filename" type="hidden" class="form-control" formControlName="filename" #filename />
      </div>
      <div class="form-group">
        <label>
          {{texts.label_style}}
          <span [ngbTooltip]="texts.tooltip_style">
            <fa-icon [icon]="faQC"></fa-icon>
          </span>
        </label>
        <select class="form-control" formControlName="style" #style [title]="texts.label_style">
          <option *ngFor="let style of styles" [value]="style.id">{{style.name}}</option>
        </select>
        <small class="form-text"
               [ngClass]="{'text-danger': style.invalid  && (style.dirty || style.touched) && style.errors.required, 'text-muted': !(style.invalid  && (style.dirty || style.touched) && style.errors?.required)}">{{texts.form_required}}</small>
      </div>
      <div class="form-check form-check-inline mb-2" *ngIf="selectedType.opts.canSetWindowSize">
        <input class="form-check-input" type="checkbox" id="forceWindowSize" formControlName="forceWindowSize"
               #forceWindowSize>
        <label class="form-check-label" for="forceWindowSize">
          {{texts.label_window_size_override}} <span [ngbTooltip]="texts.tooltip_window_size">
            <fa-icon [icon]="faQC"></fa-icon>
          </span>
        </label>
      </div>
      <div class="form-group" formGroupName="windowSize"
           *ngIf="selectedType.opts.canSetWindowSize && getForceWindowSize()">
        <label>
          {{texts.label_window_size}}
        </label>
        <div class="form-row">
          <div class="col input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">w</span>
            </div>
            <input type="number" #width formControlName="width" class="form-control" placeholder="width">
            <div class="input-group-append">
              <span class="input-group-text">px</span>
            </div>
          </div>

          <div class="col input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">h</span>
            </div>
            <input type="number" #height formControlName="height" class="form-control" placeholder="height">
            <div class="input-group-append">
              <span class="input-group-text">px</span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>{{texts.label_language}}
          <span [ngbTooltip]="texts.tooltip_language">
            <fa-icon [icon]="faQC"></fa-icon>
          </span>
        </label>
        <select class="form-control" #locale formControlName="locale">
          <option *ngFor="let opt of locales" [ngValue]="opt.locale">{{opt.name}}</option>
        </select>
      </div>
      <div class="form-check form-check-inline mb-2">
        <input class="form-check-input" type="checkbox" id="allowLangOverride" formControlName="allowLangOverride"
               #allowLangOverride>
        <label class="form-check-label" for="allowLangOverride">{{texts.label_language_override}}
          <span [ngbTooltip]="texts.tooltip_language_override">
            <fa-icon [icon]="faQC"></fa-icon>
          </span>
        </label>
      </div>
      <div formGroupName="completion" class="hidden">
        <input formControlName="threshold" type="hidden">
      </div>
      <app-completion class="create-section mb-5" [object$]="object$" (update)="onCompletion(createForm, $event)" [rowIndex]="0"
                      [type$]="type$"></app-completion>
      <div [ngSwitch]="selectedType.key">
        <ng-template [ngSwitchCase]="'VIDEO_STREAMING'">
          <app-streaming (update)="onStreaming(createForm, $event)" [success$]="success$"></app-streaming>
        </ng-template>
        <ng-template [ngSwitchCase]="'VIDEO_YOUTUBE'">
          <app-youtube (update)="onYoutube(createForm, $event)" [object$]="object$" [success$]="success$"></app-youtube>
        </ng-template>
        <ng-template [ngSwitchCase]="'VIDEO_VIMEO'">
          <app-vimeo (update)="onVimeo(createForm, $event)" [object$]="object$" [success$]="success$"></app-vimeo>
        </ng-template>
        <ng-template [ngSwitchCase]="'PPT'">
          <app-powerpoint [type$]="type$" (remove)="onRemove($event)" (update)="onPowerPoint(createForm, $event)" [success$]="success$">
          </app-powerpoint>
        </ng-template>
        <ng-template [ngSwitchCase]="'PDF'">
          <app-document [type$]="type$" (remove)="onRemove($event)" (update)="onPDF(createForm, $event)" [success$]="success$"></app-document>
        </ng-template>
        <ng-template [ngSwitchCase]="'MUSE'" *ngIf="!addQuiz">
          <app-new-video [type$]="type$" (update)="onUpload($event)" (remove)="onRemove($event)" [success$]="success$">
          </app-new-video>
          <div formGroupName="muse" class="hidden">
            <div formGroupName="file" class="hidden">
              <input formControlName="Location" type="hidden">
            </div>

          </div>
        </ng-template>
      </div>
      <div class="form-group mt-4">
        <button type="submit" class="btn btn-primary"
                [disabled]="getIsInValid() || goNext || isRemoving">{{texts.button_create}}</button>
        <button *ngIf="this.createAndNextVisible" type="button" class="btn btn-primary"
                (click)="onCreateAndNext($event)"
                [disabled]="getIsInValid() || !goNext || isRemoving">{{texts.button_create_and_next}}</button>

      </div>
      <div class="form-group mt-4">
      </div>
    </form>
  </div>
</div>

<div *ngIf="showQuizes" class="row">
  <div class="col-sm-12 col-lg-12 border-left border-right" *ngIf="this.showQuizes">
    <app-video [success$]="success$" [object$]="object$" (publish)="onPublish($event)">
    </app-video>
  </div>
</div>