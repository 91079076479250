import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { faPlus, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { MuseResponse } from '../../../../models/muse-response';
import { ObjectType } from '../../../../models/object-type.model';
import { ScormifyResponse } from '../../../../models/scormify-response';
import { SubscriptionPlan } from '../../../../models/user-subscription';
import { ByteSize, User } from '../../../../models/user.model';
import { BaseComponent } from '../../../../shared/base/base.component';
import { generateGuid } from '../../../../shared/functions/guid-generator.fn';
import { IState } from '../../../../store/reducers';
import { selectApp } from '../../../../store/selectors/app.selectors';
import { texts } from './../../../../../assets/texts/texts';
import { AwsResponse } from './../../../../models/aws-response';
import { APIUploader } from './../../../../shared/adapters/apiuploader';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
  standalone: false
})
export class UploaderComponent extends BaseComponent implements OnInit, OnDestroy {
  private _uploader: APIUploader;
  error: any;
  appState$ = this._store.pipe(select(selectApp));
  nonce = generateGuid();

  @Input() type$: BehaviorSubject<ObjectType>;
  @Input() success$: Subject<boolean>;
  @Output() uploadSuccess = new EventEmitter<ScormifyResponse>();
  @Output() isUploading = new EventEmitter<boolean>();
  @Output() isRemoving = new EventEmitter<boolean>();

  hasFile = false;
  theFile: File = null;
  faPlus = faPlus;
  faRemove = faTimes;
  texts = texts;
  uploading = false;
  removing = false;
  response: ScormifyResponse;
  faSpinner = faSpinner;
  invalid = false;
  selectedType: ObjectType;
  isGb = false;
  sizeLimit: ByteSize;
  user: User;

  constructor(private _store: Store<IState>, private http: HttpClient) {
    super();
  }

  ngOnInit() {
    this.success$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.reset();
    });
    combineLatest([this.appState$, this.type$])
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        const [state, type] = value;
        if (type) {
          this.selectedType = type;
          this._uploader = new APIUploader(this.http, type.uploadPath);
        }
        if (state) {
          if (state.subscription?.plan == SubscriptionPlan.free) {
            this.sizeLimit = state.user.$limits.docUploadSizeFree;
          }
          if (state.subscription?.plan == SubscriptionPlan.pro || state.subscription?.plan == SubscriptionPlan.basic) {
            this.sizeLimit = state.user.$limits.docUploadSizePro;
            if (this.selectedType.key === 'MUSE') {
              this.sizeLimit = state.user.$limits.videoUploadSize;
            }
          }
        }
      });
  }

  getLabel() {
    switch (this.selectedType.key) {
      case 'PPT':
        return texts.button_select_ppt;
      case 'PDF':
        return texts.button_select_pdf;
      case 'MUSE':
        return texts.button_select_video_upload;
      default:
        return texts.button_select_generic;
    }
  }

  handleFileInput(files: FileList) {
    this.error = undefined;
    this.theFile = files.item(0);
    this.uploading = true;
    this.isUploading.emit(this.uploading);
    this._uploader
      .uploadFile(this.theFile)
      .pipe(first())
      .subscribe({
        next: (res) => {
          this.hasFile = true;
          this.uploading = false;
          this.isUploading.emit(this.uploading);
          this.uploadSuccess.emit(res);
          this.response = res;
        },
        error: (err) => {
          this.uploading = false;
          this.error = err;
        }
      });
  }

  isInvalid() {
    this.invalid = false;
    if (!this.selectedType.mimes.some((m) => m === this.theFile.type)) {
      this.invalid = true;
      return true;
    }
    return false;
  }

  remove() {
    this.removing = true;
    this.isUploading.emit(this.uploading);
    this.isRemoving.emit(this.removing);
    if (this.selectedType.key === 'MUSE') {
      this._uploader.removeFromMuse((this.response as MuseResponse).fid).subscribe((res) => {
        this.uploading = false;
        this.removing = false;
        this.hasFile = false;
        this.theFile = null;
        this.isUploading.emit(this.uploading);
        this.isRemoving.emit(this.removing);
        this.uploadSuccess.emit(null);
        this.response = null;
      });
    } else {
      this.uploading = true;
      this._uploader.removeFile(this.response as AwsResponse).subscribe((res) => {
        this.uploading = false;
        this.removing = false;
        this.hasFile = false;
        this.theFile = null;
        this.isUploading.emit(this.uploading);
        this.isRemoving.emit(this.removing);
        this.uploadSuccess.emit(null);
        this.response = null;
      });
    }
  }

  reset() {
    this.uploading = false;
    this.hasFile = false;
    this.theFile = null;
    this.response = null;
  }

  getRemoveLabel() {
    return texts.button_remove_file.replace('@@FILE@@', this.theFile.name);
  }
}

interface SizeLimit {
  sizeLimit: number;
  unit: string;
}
