import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { Link } from '../models/api-response.model';
import { goToLink } from '../shared/functions/link.fn';
import { ApiResponse } from './../models/api-response.model';
import { IconDefinition } from '@fortawesome/angular-fontawesome';
import { faPencilAlt, faToggleOff, faToggleOn, faTrash } from '@fortawesome/free-solid-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class LinksService {
  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService) { }

  handleRel(link: Link): void | ApiResponse | Observable<ApiResponse> {
    switch (link.rel) {
      case 'redirect':
        this.redirect(link);
        break;
      case 'clear-token':
        this.clearToken();
        break;
      default:
        return this.httpMethod(link).pipe(
          take(1),
          tap((res: ApiResponse) => this.resolve(res))
        );
    }
  }

  resolve(res: ApiResponse) {
    if (res.message) {
      this.toastr.success(res.message);
    }
    if (!res.action) {
      return res;
    }
    if (res.action) {
      this.handleRel(res.action);
    }
  }

  xhrAction(action: Link, object: any, showToast = true): Observable<ApiResponse> {
    if (action.method) {
      return this.httpMethod(action).pipe(
        tap((res) => {
          this.toastr.success(res?.message ?? 'Ok');
        }),
        take(1),
        catchError((err: HttpErrorResponse) => {
          this.toastr.error('The operation encountered an unknown error');
          return throwError(err.error.message);
        })
      );
    }
  }

  buttonAction(link: Link) { }

  redirect(action: Link): Promise<boolean> {
    if (action.param) {
      return this.router.navigate([action.href, action.param]);
    } else if (action.params) {
      const parts: [String] = [action.href];
      for (const part of action.params) {
        parts.push(part);
      }
      return this.router.navigate(parts);
    }
    return this.router.navigate([action.href]);
  }

  clearToken() {
    localStorage.removeItem('token');
  }

  httpMethod(action: Link, data?: any): Observable<ApiResponse> {
    data = data ? data : {};
    if (!action.href) return of(new ApiResponse());
    switch (action.method.toString().toUpperCase()) {
      case 'GET':
        return this.http.get(action.href).pipe(
          take(1),
          map((res: ApiResponse) => res)
        );
      case 'PUT':
        return this.http.put(action.href, data).pipe(
          take(1),
          map((res: ApiResponse) => res)
        );
      case 'POST':
        return this.http.post(action.href, data).pipe(
          take(1),
          map((res: ApiResponse) => res)
        );
      case 'PATCH':
        return this.http.patch(action.href, data).pipe(
          take(1),
          map((res: ApiResponse) => res)
        );
      case 'DELETE':
        return this.http.delete(action.href).pipe(
          take(1),
          map((res: ApiResponse) => res)
        );
    }
  }

  goToLink(link: Array<Link>, rel: string) {
    return goToLink(link, rel);
  }

  getIcon(link): IconDefinition {
    let icon: IconDefinition;
    switch (link.icon) {
      case 'faTrash':
        icon = faTrash;
        break;
      case 'faPencilAlt':
        icon = faPencilAlt;
        break;
      case 'faToggleOn':
        icon = faToggleOn;
        break;
      case 'faToggleOff':
        icon = faToggleOff;
        break;
    }
    return icon;
  }
}
