<section id="profile" class="container" scrollSpyElement="profileElem">
  <div class="alert alert-primary alert-dismissible" *ngIf="isInvalid">
    {{texts.message_profile_invalid}}
  </div>
  <div class="alert alert-primary alert-dismissible" *ngIf="isPartial">
    {{texts.message_profile_invalid}}
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-3">
      <div class="sticky-top">
        <ul class="nav nav-pills flex-md-column" role="navigation" id="profile-menu" scroll-spy>
          <li class="nav-item">
            <button class="nav-link btn btn-link" (click)="scrollTo('#sec-profile-form')">Profile</button>
          </li>
          <li class="nav-item">
            <button class="nav-link btn btn-link" (click)="scrollTo('#sec-change-password')">Change Password</button>
          </li>
          <li class="nav-item d-none">
            <button class="nav-link btn btn-link" (click)="scrollTo('#sec-gen-api-key')">Api Keys</button>
          </li>
          <li class="nav-item">
            <button class="nav-link btn btn-link" (click)="scrollTo('#sec-settings')">Settings</button>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-9">
      <app-profile-form id="sec-profile-form"></app-profile-form>

      <hr class="my-4">
      <h2 id="sec-change-password">Change Password</h2>
      <app-password-input [label]="texts.label_new_password" [standalone]="true"></app-password-input>
      <app-generate-api-key id="sec-gen-api-key" class="d-none"></app-generate-api-key>
      <hr class="my-4">
      <div id="sec-settings" class="mb-4">
        <h2 class="mb-3">{{texts.header_settings_preferences}}</h2>
        <div class="alert alert-danger" (click)="removeMeConfirm()" *ngIf="showRemoveMeConfirm">
          <p>{{texts.message_delete_account}}</p>
          <button class="btn btn-danger">{{texts.button_confirm}}</button>&nbsp;&nbsp;
          <button class="btn btn-danger" (click)="removeMeCancel()">{{texts.button_cancel}}</button>
        </div>
        <ul class="nav" *ngIf="!showRemoveMeConfirm">
          <li>
            <button class="btn btn-danger" (click)="removeMe()">{{texts.button_delete_account}}</button>
          </li>
        </ul>
        <p class="mt-3">GDPR requests may be emailed to support&#64;knowledgeanywhere.com</p>
      </div>
    </div>
  </div>
</section>