import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  OnChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ModalService } from '../../../../../../services/modal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { texts } from '../../../../../../../assets/texts/texts';
import { Question, QuestionType, Answer } from '../../../../../../models/scormify.model';
import { SlugifyPipe } from '../../../../../../pipes/slugify.pipe';

@Component({
    selector: 'app-question-multiplechoice',
    templateUrl: './multiplechoice.component.html',
    providers: [SlugifyPipe],
    standalone: false
})
export class MultipleChoiceComponent implements OnInit, OnDestroy, OnChanges {
  formmultiplechoice: UntypedFormGroup;
  public alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  texts = texts;
  faBin = faTrash;

  public correctAnswer: string = '';

  constructor(private modalService: ModalService, public activeModal: NgbActiveModal) { }

  @Input()
  public question: Question;

  @Output()
  addQuestionOutput = new EventEmitter<Question>();

  @Output()
  saveQuestionOutput = new EventEmitter<Question>();

  @Output()
  cancelQuestion = new EventEmitter();

  @Output()
  deleteQuestion = new EventEmitter<Question>();

  ngOnInit() {
    if (this.question.id == null) {
      this.prepareMultipleChoiceQuestion();
    } else {
      this.prepareMultipleChoiceQuestionExistingQuestion();
    }
  }

  prepareMultipleChoiceQuestionExistingQuestion() {
    const group: any = {};

    this.question.answers.forEach((answer, index) => {
      group[index.toString()] = new UntypedFormControl(answer.text, Validators.required);

      if (answer.correctAnswer) this.correctAnswer = index.toString();
    });

    group['correctAnswer'] = new UntypedFormControl(this.correctAnswer, Validators.required);

    group['questionText'] = new UntypedFormControl(this.question.text, Validators.required);

    this.formmultiplechoice = new UntypedFormGroup(group);
  }

  prepareMultipleChoiceQuestion() {
    this.question.questionType = QuestionType.multipleChoice;

    this.question.answers = new Array<Answer>();

    for (let i = 0; i < 3; i++) {
      let answer = new Answer();
      answer.letter = this.alphabet[i];
      answer.correctAnswer = false;
      this.question.answers.push(answer);
    }

    const group: any = {};

    this.question.answers.forEach((answer, index) => {
      group[index.toString()] = new UntypedFormControl(answer.text, Validators.required);
    });

    group['correctAnswer'] = new UntypedFormControl(null, Validators.required);

    group['questionText'] = new UntypedFormControl('', Validators.required);

    this.formmultiplechoice = new UntypedFormGroup(group);
  }

  onAddNewOptionClick() {
    let answer = new Answer();
    answer.letter = this.alphabet[this.question.answers.length];
    answer.correctAnswer = false;

    this.question.answers.push(answer);

    this.formmultiplechoice.addControl((this.question.answers.length - 1).toString(), new UntypedFormControl('', Validators.required));
  }

  onCancelClick() {
    this.cancelQuestion.emit();
  }

  onDeleteClick() {
    this.deleteQuestion.emit(this.question);
  }

  ngOnDestroy(): void { }

  ngOnChanges() {
    this.ngOnInit();
  }

  onInsertClick() {
    this.question.questionType = QuestionType.multipleChoice;


    this.question.answers.forEach((obj, index) => {
      obj.letter = this.alphabet[index];
      if (this.correctAnswer == index.toString()) obj.correctAnswer = true;
      else obj.correctAnswer = false;
    });

    this.addQuestionOutput.emit(this.question);
  }

  onSaveClick() {
    this.question.questionType = QuestionType.multipleChoice;

    this.question.answers.forEach((obj, index) => {
      obj.letter = this.alphabet[index];
      if (this.correctAnswer == index.toString()) obj.correctAnswer = true;
      else obj.correctAnswer = false;
    });
    this.saveQuestionOutput.emit(this.question);
  }




  onDeleteAnswerClick(answerLetter: string, i: number, call: number) {

    if (this.question.answers.length == 2) {
      this.modalService
        .ok(texts.header_delete_answer, texts.message_multiple_choice_minimum_number_of_answers)
        .subscribe((result: boolean) => {
        });
      return;
    }

    this.question.answers.forEach((answer, index) => {
      this.formmultiplechoice.removeControl(i.toString());

    });
    this.formmultiplechoice.removeControl('correctAnswer');


    this.question.answers.splice(i, 1);

    if (this.correctAnswer == i.toString())
      this.correctAnswer = null;

    let correctAnswerNumber = -1;

    if (this.correctAnswer != '')
      correctAnswerNumber = (Number)(this.correctAnswer);

    if (correctAnswerNumber == i && correctAnswerNumber == -1)
      this.correctAnswer = null;
    else if (correctAnswerNumber > i)
      this.correctAnswer = (correctAnswerNumber - 1).toString();


    const group: any = {};


    this.question.answers.forEach((answer, index) => {
      answer.letter = this.alphabet[index];
      group[index.toString()] = new UntypedFormControl(answer.text, Validators.required);

    });

    group['correctAnswer'] = new UntypedFormControl(this.correctAnswer, Validators.required);

    group['questionText'] = new UntypedFormControl(this.question.text, Validators.required);

    this.formmultiplechoice = new UntypedFormGroup(group);

  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }
}
