<div class="alert alert-danger" *ngIf="error">{{error}}</div>
<div class="alert alert-success" *ngIf="message">{{message}}</div>
<app-spinner *ngIf="loading"></app-spinner>
<div *ngIf="!loading">
  <form novalidate  #f="ngForm" (ngSubmit)="onSubmit(f)">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group mb-3">
          <label>{{label}}</label>
          <input type="hidden" id="email"  autocomplete="email" name="email"  [(ngModel)]="email" #emailinput="ngModel">
          <input type="password" class="form-control" name="password" required placeholder="" id="password" #passwordinp="ngModel"  [(ngModel)]="password" autocomplete="new-password" (ngModelChange)="onChange($event)">
          <div *ngIf="passwordinp.invalid && (passwordinp.dirty || passwordinp.touched)">
            <div class="text-danger" *ngIf="passwordinp.errors.required">
              Password is required.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="standalone">
      <div class="col-sm-12">
        <ul class="list-inline">
          <li class="list-inline-item">
            <button [disabled]="passwordinp.invalid" type="submit" class="btn btn-primary">Save</button>
          </li>
        </ul>
      </div>
    </div>
  </form>
</div>


