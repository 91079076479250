import { mediaExtractId } from '../shared/functions/media-extract-id.fn';
import { Link } from './api-response.model';
import { AwsResponse } from './aws-response';
import { Metadata } from './metadata.model';
import { MuseResponse } from './muse-response';
import { ObjectType, TypeSize } from './object-type.model';
export class Scormify {
  id?: string;
  _id?: string;
  descriptors?: Descriptors;
  completion?: Completion;
  type?: ObjectType;
  style?: string;
  streamingVideo?: StreamingVideo;
  youtube?: Youtube;
  vimeo?: Vimeo;
  ppt?: PowerPoint;
  pdf?: PDF;
  muse?: NewVideo;
  locale?: string;
  allowLangOverride = true;
  forceWindowSize = false;
  windowSize?: TypeSize;
  $resource?: string;
  $links?: [Link];
  $actions?: [Link];
  metadata?: Metadata;
  status?: string;
  republishing?: boolean;
  uploadedVideo?: UploadedVideo;
  quiz?:Quiz;

  get isStreaming() {
    return this.type.key === 'VIDEO_STREAMING';
  }

  get isYouTube() {
    return this.type.key === 'VIDEO_YOUTUBE';
  }

  get isVimeo() {
    return this.type.key === 'VIDEO_VIMEO';
  }

  get isPowerPoint() {
    return this.type.key === 'PPT';
  }

  get isPDF() {
    return this.type.key === 'PDF';
  }
}

export interface DtoScormify {
  id?: string;
  _id?: string;
  descriptors?: Descriptors;
  completion?: Completion;
  type?: string;
  style?: string;
  streamingVideo?: StreamingVideo;
  youtube?: Youtube;
  vimeo?: Vimeo;
  ppt?: PowerPoint;
  pdf?: PDF;
  locale?: string;
  allowLangOverride: boolean;
  forceWindowSize: boolean;
  windowSize?: TypeSize;
}

export class Descriptors {
  title?: string;
  filename?: string;
}

export class Completion {
  threshold: number = 99;
  disableScrubber = false;
  completeOnDownload = false;
  enableBookmarks = true;
  addOverlayQuiz = false;
}

export class Quiz
{
  passingScore:number;
  questions:Array<Question>;
}

export class Question {
  id:number;
  text:string;
  questionType:number;
  timeStamp:number;
  answers:Array<Answer>;
}

export class Answer {
  letter:string;
  text:string;
  correctAnswer:boolean;
}

export class StreamingVideo {
  posterUrl?: string;
  hlsUrl?: string;
  mp4Url?: string;
  webmUrl?: string;
  fluid = false;
  responsive = true;
  aspectRatio?: string;
  forceVideoSize = false;
  videoSize?: TypeSize = new TypeSize();
  autoplay = false;
}

export class Vimeo {
  url?: string;

  get id() {
    return mediaExtractId('vimeo', this.url);
  }
}

export class Youtube {
  url?: string;

  get id() {
    return mediaExtractId('vimeo', this.url);
  }
}

export class UploadedVideo {
  url?: string;

  get id() {
    return mediaExtractId('vimeo', this.url);
  }
}


export class PowerPoint {
  file?: AwsResponse;
}

export class PDF {
  allowDocumentDownload: boolean;
  file?: AwsResponse;
}

export class NewVideo {
  file?: MuseResponse;
  forceVideoSize: boolean;
  aspectRatio?: string;
  videoSize?: TypeSize = new TypeSize();
  disableSearch: boolean;
  disableSubtitles: boolean;
}

export class Ratio {
  label: string;
  height: number;
  width: number;
  defaultHeight: number;
  defaultWidth: number;
}

export const ratios: Array<Ratio> = [
  {
    label: '16:9',
    height: 9,
    width: 16,
    defaultHeight: 405,
    defaultWidth: 720,
  },
  {
    label: '4:3',
    height: 3,
    width: 4,
    defaultHeight: 540,
    defaultWidth: 720,
  },
  {
    label: '8:5',
    height: 5,
    width: 8,
    defaultHeight: 450,
    defaultWidth: 720,
  },
];

// Source https://github.com/ryanhefner/calculate-aspect-ratio/blob/master/src/index.js
// https://calculateaspectratio.com/

export const gcd = (a, b) => {
  return b ? gcd(b, a % b) : a;
};

export const aspectRatio = (width, height): Ratio => {
  const divisor = gcd(width, height);
  const ratio = new Ratio();
  ratio.label = `${width / divisor}:${height / divisor}`;
  ratio.width = width / divisor;
  ratio.height = height / divisor;
  ratio.defaultHeight = height;
  ratio.defaultWidth = width;

  return ratio;
};

export enum QuestionType {
  multipleChoice = 1,
  multipleSelect = 2,
  trueFalse = 3,
  tip = 4,
}
