<div class="form-group" *ngIf="!hasFile">
  <label for="file-select" class="file-select-label">
    <span class="btn btn-primary upload-button" [ngClass]="{'disabled': uploading || hasFile}">
      <span *ngIf="!uploading"><fa-icon [icon]="faPlus"></fa-icon>&nbsp;Import from CSV</span>
      <span *ngIf="uploading"><fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon></span>
    </span>
    <small class="text-danger form-text" *ngIf="error">{{ error.error?.message }}</small>
  </label>
  <input type="file" id="file-select" accept=".csv, .xlsx" (change)="handleFileInput($event.target.files)"
         class="d-none">
</div>