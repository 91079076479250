export class TypeSize {
  height: number;
  width: number;
}

export class ObjectType {
  name: string;
  key: string;
  opts?: Opts;
  slug?: string;
  conversion?: boolean;
  background?: boolean;
  uploadPath?: string;
  accept?: string;
  defaultMaxSizeMB?: string;
  windowSize = new TypeSize();
  mimes?: string[];
  allowBulk?: boolean;
  disabled?: boolean;
}

export class Opts {
  disableScrubber?: boolean;
  completeOnDownload?: boolean;
  showThreshold?: boolean;
  enableBookmarks: boolean;
  forceWindowSizeDefault: boolean;
  canSetWindowSize: boolean;
  disableThreshold: boolean;
  addOverlayQuiz: boolean;
}

export class Locale {
  name: string;
  locale: string;
}

export enum ObjectTypeKeys {
  Streaming = 'VIDEO_STREAMING',
  Vimeo = 'VIDEO_VIMEO',
  YouTube = 'VIDEO_YOUTUBE',
  PowerPoint = 'PPT',
  PDF = 'PDF',
  Muse = 'MUSE'
}
