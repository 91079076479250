<section id="admin-home">
  <div class="row">
    <div class="col">
      <h1>Admin Home</h1>
      <br>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="card-group">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Users</h3>
            <p class="card-text">{{numberOfUsers}}</p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Videos</h3>
            <p class="card-text">{{numberOfVideos}}</p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Styles</h3>
            <p class="card-text">{{numberOfStyles}}</p>
          </div>
        </div>
        <div class="card">
         <div class="card-body">
          <h3 class="card-title">Quizes</h3>
          <p class="card-text">{{numberOfQuizes}}</p>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
         <h3 class="card-title">Questions</h3>
         <p class="card-text">{{numberOfQuestions}}</p>
       </div>
     </div>
      </div>
    </div>
  </div>
</section>
