import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { texts } from '../../../../../assets/texts/texts';
import { AwsResponse } from '../../../../models/aws-response';
import { ObjectType } from '../../../../models/object-type.model';
import { PDF, Scormify } from '../../../../models/scormify.model';
import { BaseComponent } from '../../../../shared/base/base.component';
import { IState } from '../../../../store/reducers';
import { selectApp } from '../../../../store/selectors/app.selectors';
import { constants } from './../../../../../assets/constants/constants';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  standalone: false
})
export class DocumentComponent extends BaseComponent {
  pdf = new PDF();
  appState$ = this._store.pipe(select(selectApp));
  uploading = false;
  constants = constants;
  texts = texts;
  faQC = faQuestionCircle;
  @Input() success$: Subject<boolean>;
  @Input() type$: BehaviorSubject<ObjectType>;
  @Input() object$: BehaviorSubject<Scormify>;
  @Output() update = new EventEmitter<PDF>();
  @Output() remove = new EventEmitter<boolean>();
  selectedType: ObjectType;

  constructor(private _store: Store<IState>) {
    super();
  }

  onChanges() {
    this.update.emit(this.pdf);
  }

  isRemoving($event: boolean) {
    this.remove.emit($event);
  }

  uploadSuccess($event: AwsResponse) {
    this.pdf.file = $event;
    this.update.emit(this.pdf);
  }
}
