export interface IResourceDefinition {
  title: string;
  href: string;
  image: string;
}

export const resources: Array<IResourceDefinition> = [
  {
    title: 'What is SCORM and Why Is It Important?',
    href:
      'https://www.knowledgeanywhere.com/resources/article-detail/what-is-scorm-and-why-is-it-important',
    image: '../../../assets/images/resource_1.jpg',
  },
  {
    title: 'How To Turn Your Video Content Into A SCORM Course',
    href:
      'https://www.knowledgeanywhere.com/resources/video-detail/scormify-demo-how-to-turn-your-video-content-into-a-scorm-course',
    image: '../../../assets/images/resource_2.jpg',
  },
  {
    title: 'How to Bulk Upload Training Content on Scormify',
    href:
      'https://www.knowledgeanywhere.com/resources/video-detail/how-to-bulk-upload-training-content-on-scormify',
    image: '../../../assets/images/resource_3.jpg',
  },
];
