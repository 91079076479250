<div class="form-group">
  <label>{{texts.label_mp4_url}}
    <span [ngbTooltip]="texts.tooltip_mp4_url">
    <fa-icon [icon]="faQC"></fa-icon>
  </span>
</label>
  <input id="mp4url" class="form-control" [(ngModel)]="video.mp4Url" appCloudMediaValidator="mp4"
    [ngModelOptions]="{updateOn: 'blur'}" (ngModelChange)="onChanges()" name="mp4url" #mp4url="ngModel">
  <small class="form-text"
    [ngClass]="{'text-danger': (mp4url.invalid  && (mp4url.dirty || mp4url.touched) && mp4url?.errors?.required), 'text-muted': !!mp4url.invalid}">{{texts.form_required}}</small>
  <div *ngIf="mp4url.invalid  && (mp4url.dirty || mp4url.touched)" class="text-danger">
    <small class="form-text" *ngIf="mp4url?.errors?.mediaValidator">{{texts.validation_mp4_url}}</small>
  </div>

</div>
<div class="form-group">
  <label>{{texts.label_poster_url}}
    <span [ngbTooltip]="texts.tooltip_poster_url">
    <fa-icon [icon]="faQC"></fa-icon>
  </span></label>
  <input id="posterurl" class="form-control" [(ngModel)]="video.posterUrl" appCloudMediaValidator="poster"
    [ngModelOptions]="{updateOn: 'blur'}" (ngModelChange)="onChanges()" name="posterurl" #posterurl="ngModel">
  <small class="form-text text-muted">{{texts.form_optional}}</small>
  <div *ngIf="posterurl.invalid  && (posterurl.dirty || posterurl.touched)" class="text-danger">
    <small class="form-text" *ngIf="posterurl?.errors?.mediaValidator">{{texts.validation_poster_url}}</small>
  </div>

</div>
<div class="form-group">
  <label>{{texts.label_hls_url}}
    <span [ngbTooltip]="texts.tooltip_hls_url">
    <fa-icon [icon]="faQC"></fa-icon>
  </span></label>
  <input id="hlsurl" class="form-control" [(ngModel)]="video.hlsUrl" appCloudMediaValidator="hls"
    [ngModelOptions]="{updateOn: 'blur'}" (ngModelChange)="onChanges()" name="hlsurl" #hlsurl="ngModel">
  <small class="form-text text-muted">{{texts.form_optional}}</small>
  <div *ngIf="hlsurl.invalid  && (hlsurl.dirty || hlsurl.touched)" class="text-danger">
    <small class="form-text" *ngIf="hlsurl?.errors?.mediaValidator">{{texts.validation_hls_url}}</small>
  </div>

</div>
<div class="form-group">
  <label>{{texts.label_webm_url}}
    <span [ngbTooltip]="texts.tooltip_webm_url">
    <fa-icon [icon]="faQC"></fa-icon>
  </span></label>
  <input id="webmurl" class="form-control" [(ngModel)]="video.webmUrl" appCloudMediaValidator="webm"
    [ngModelOptions]="{updateOn: 'blur'}" name="webmurl" #webmurl="ngModel" (ngModelChange)="onChanges()">
  <small class="form-text text-muted">{{texts.form_optional}}</small>
  <div *ngIf="webmurl.invalid  && (webmurl.dirty || webmurl.touched)" class="text-danger">
    <small class="form-text" *ngIf="webmurl?.errors?.mediaValidator">{{texts.validation_webm_url}}</small>
  </div>
</div>
<h5 class="mb-4">{{texts.label_video_options}}</h5>
<div class="form-group">
  <div class="form-check form-check-inline">
    <input class="form-check-input" type="checkbox" id="responsivemode" name="responsivemode"
      [(ngModel)]="video.responsive" [disabled]="video.forceVideoSize" (ngModelChange)="onChanges()">
    <label class="form-check-label" for="responsivemode">{{texts.label_responsive}}
      <span [ngbTooltip]="texts.tooltip_responsive">
      <fa-icon [icon]="faQC"></fa-icon>
    </span></label>
  </div>
  <div class="form-check form-check-inline d-none deprecated">
    <input class="form-check-input" type="checkbox" id="fluidmode" name="fluidmode" [(ngModel)]="video.fluid"
      [disabled]="video.forceVideoSize" (ngModelChange)="onChanges()">
    <label class="form-check-label" for="fluidmode">{{texts.label_fluid_mode}}
      <span [ngbTooltip]="texts.tooltip_fluid_mode">
      <fa-icon [icon]="faQC"></fa-icon>
    </span></label>
  </div>
  <div class="form-check form-check-inline">
    <input class="form-check-input" type="checkbox" id="autoplay" name="autoplay" [(ngModel)]="video.autoplay"
      (ngModelChange)="onChanges()">
    <label class="form-check-label" for="autoplay">{{texts.label_autoplay}}
      <span [ngbTooltip]="texts.tooltip_autoplay">
      <fa-icon [icon]="faQC"></fa-icon>
    </span>
  </label>
  </div>
</div>
<div class="form-group">
  <div class="form-check form-check-inline">
    <input class="form-check-input" type="checkbox" id="forcesize" name="forcesize" [(ngModel)]="video.forceVideoSize"
      (change)="onForceSizeChange()">
    <label class="form-check-label" for="forcesize">
      {{texts.label_force_size}}
      <span [ngbTooltip]="texts.tooltip_force_size">
        <fa-icon [icon]="faQC"></fa-icon>
      </span>
    </label>
  </div>
  <div *ngIf="video.forceVideoSize" class="mt-3">
    <p class="help-block text-muted">
      {{texts.label_checked_force_size}}
    </p>
    <div class="form-group">
      <label>{{texts.label_common_aspect_ratios}}</label>
      <select class="form-control" (change)="onAspectChange($event.target.value)" [disabled]="!constrain">
        <option *ngFor="let r of ratios" [value]="r.label">{{r.label}}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label>
        {{texts.label_aspect_ratio}}
      </label>
      <div class="form-row">
        <div class="col input-group">
          <input type="number" name="ratiow" #ratiow class="form-control" [(ngModel)]="ratio.width" placeholder="width"
            [disabled]="!constrain" (ngModelChange)="onChanges()">
        </div>
        <div class="col input-group">
          <input type="number" name="ratioh" #ratioh class="form-control" [(ngModel)]="ratio.height"
            placeholder="height" [disabled]="!constrain" (ngModelChange)="onChanges()">
        </div>
      </div>
    </div>
    <div class="form-check form-check-inline mb-3">
      <input class="form-check-input" type="checkbox" id="constrain" name="constrain"
        (change)="onConstrain($event.target.value)" [(ngModel)]="constrain" (ngModelChange)="onChanges()">
      <label class="form-check-label" for="constrain">{{texts.label_constrain_proportions}}</label>
    </div>
    <div class="form-group">
      <label>
        {{texts.label_video_size}}
      </label>
      <div class="form-row">
        <div class="col input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">w</span>
          </div>
          <input type="number" name="sizew" #sizew class="form-control" [(ngModel)]="video.videoSize.width"
            placeholder="width" (change)="onSetWidth()" (ngModelChange)="onChanges()">
          <div class="input-group-append">
            <span class="input-group-text">px</span>
          </div>
        </div>

        <div class="col input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">h</span>
          </div>
          <input type="number" name="sizeh" #sizeh class="form-control" [(ngModel)]="video.videoSize.height"
            placeholder="height" (change)="onSetHeight()" (ngModelChange)="onChanges()">
          <div class="input-group-append">
            <span class="input-group-text">px</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
