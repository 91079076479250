<div class="row justify-content-end me-2">
  <div class="pricing-mode-switch">
    <label class="switch switch_type1" role="switch" placement="right">
      <input
        type="checkbox"
        class="switch__toggle"
        [checked]="showAnnualPricing"
        (change)="togglePricing($event)"
      />
      <span class="switch__label"></span>
    </label>
  </div>
  <span class="link-label pricing-mode-label ms-2">Show Annual Pricing</span>
</div>
<div class="row d-flex justify-content-center" *ngIf="!loading">
  <!--Basic-->
  <div class="col-lg-5 col-md-12 mb-5 mt-5" id="free-container">
    <div class="card sub alt-sub d-flex flex-column justify-content-center sub-v2" id="free">
      <div class="card-body sub-top">
        <h5 class="card-title">Trial</h5>
        <h6>&nbsp;</h6>
      </div>
      <div class="card-body sub-description-v2">
        <div class="mb-3">
          <h2>
            <span class="price-value">FREE</span>
          </h2>
        </div>
      </div>
      <ul class="d-flex pricing-tier-list flex-column ps-5 pe-5 list-unstyled">
        <li class="list-item justify-content-center">
          <strong>3 Conversions</strong>
        </li>
        <li class="list-item justify-content-center row">
          <div class="col-1">
            <fa-icon [icon]="faCheck" class="text-ka-green"></fa-icon>
          </div>
          <div class="col-10">Custom theming</div>
        </li>
        <li class="list-item justify-content-center row">
          <div class="col-1">
            <fa-icon [icon]="faCheck" class="text-ka-green"></fa-icon>
          </div>
          <div class="col-10">Download history</div>
        </li>
        <li class="list-item justify-content-center row">
          <div class="col-1">
            <fa-icon [icon]="faCheck" class="text-ka-green"></fa-icon>
          </div>
          <div class="col-10">Vimeo conversions</div>
        </li>
        <li class="list-item justify-content-center row">
          <div class="col-1">
            <fa-icon [icon]="faCheck" class="text-ka-green"></fa-icon>
          </div>
          <div class="col-10">YouTube conversions</div>
        </li>
        <li class="list-item justify-content-center row">
          <div class="col-1">
            <fa-icon [icon]="faCheck" class="text-ka-green"></fa-icon>
          </div>
          <div class="col-10">PDF/Word conversions</div>
        </li>
        <li class="list-item justify-content-center row">
          <div class="col-1">
            <fa-icon [icon]="faCheck" class="text-ka-green"></fa-icon>
          </div>
          <div class="col-10">PowerPoint conversions</div>
        </li>
        <li class="list-item justify-content-center row text-muted">
          <div class="col-1">
            <fa-icon [icon]="faBan" class="text-red"></fa-icon>
          </div>
          <div class="col-10">Upload and convert video files for streaming</div>
        </li>
        <li class="list-item justify-content-center row text-muted">
          <div class="col-1">
            <fa-icon [icon]="faBan" class="text-red"></fa-icon>
          </div>
          <div class="col-10">Add quizzing to videos</div>
        </li>
        <li class="list-item justify-content-center row text-muted">
          <div class="col-1">
            <fa-icon [icon]="faBan" class="text-red"></fa-icon>
          </div>
          <div class="col-10">Bulk conversions</div>
        </li>
      </ul>
    </div>
  </div>
  <!--PRO-->
  <div class="col-lg-5 col-md-12 mb-5 mt-5" id="pro-container">
    <div class="card sub alt-sub d-flex flex-column justify-content-center sub-v2" id="pro">
      <div class="card-body sub-top">
        <h5 class="card-title">PRO</h5>
        <h6>&nbsp;</h6>
      </div>
      <div class="card-body sub-description-v2">
        <div class="mb-3">
          <h2>
            <span class="price-value">${{ getPrice() }}/{{ getRenewal() }}</span>
          </h2>
        </div>
        <button class="btn btn-large btn-block btn-ka-turquoise sub-get-started" (click)="select()">
          Select
        </button>
      </div>
      <ul class="d-flex pricing-tier-list flex-column ps-5 pe-5 list-unstyled">
        <li class="list-item justify-content-center">
          <strong>Unlimited conversions</strong>
        </li>
        <li class="list-item justify-content-center row">
          <div class="col-1">
            <fa-icon [icon]="faCheck" class="text-ka-green"></fa-icon>
          </div>
          <div class="col-10">Custom theming</div>
        </li>
        <li class="list-item justify-content-center row">
          <div class="col-1">
            <fa-icon [icon]="faCheck" class="text-ka-green"></fa-icon>
          </div>
          <div class="col-10">Download history</div>
        </li>
        <li class="list-item justify-content-center row">
          <div class="col-1">
            <fa-icon [icon]="faCheck" class="text-ka-green"></fa-icon>
          </div>
          <div class="col-10">Vimeo conversions</div>
        </li>
        <li class="list-item justify-content-center row">
          <div class="col-1">
            <fa-icon [icon]="faCheck" class="text-ka-green"></fa-icon>
          </div>
          <div class="col-10">YouTube conversions</div>
        </li>
        <li class="list-item justify-content-center row">
          <div class="col-1">
            <fa-icon [icon]="faCheck" class="text-ka-green"></fa-icon>
          </div>
          <div class="col-10">PDF/Word conversions</div>
        </li>
        <li class="list-item justify-content-center row">
          <div class="col-1">
            <fa-icon [icon]="faCheck" class="text-ka-green"></fa-icon>
          </div>
          <div class="col-10">PowerPoint conversions</div>
        </li>
        <li class="list-item justify-content-center row">
          <div class="col-1">
            <fa-icon [icon]="faCheck" class="text-ka-green"></fa-icon>
          </div>
          <div class="col-10">Upload and convert video files for streaming</div>
        </li>
        <li class="list-item justify-content-center row">
          <div class="col-1">
            <fa-icon [icon]="faCheck" class="text-ka-green"></fa-icon>
          </div>
          <div class="col-10">Add quizzing to videos</div>
        </li>
        <li class="list-item justify-content-center row">
          <div class="col-1">
            <fa-icon [icon]="faCheck" class="text-ka-green"></fa-icon>
          </div>
          <div class="col-10">Bulk conversions</div>
        </li>
      </ul>
    </div>
  </div>
</div>
