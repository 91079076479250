import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faFacebook, faGithub, faGoogle, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { User } from '../../models/user.model';
import { AccountService } from '../../services/account.service';
import { IState } from '../../store/reducers';
import { selectApp } from '../../store/selectors/app.selectors';
import { texts } from './../../../assets/texts/texts';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    standalone: false
})
export class ProfileComponent implements OnInit, OnDestroy {
  state: String;
  isPartial = false;
  isInvalid = false;
  showRemoveMeConfirm = false;
  user: User;
  unlinkError: String;
  texts = texts;
  appState$ = this._store.pipe(select(selectApp));
  faGithub = faGithub;
  faFacebook = faFacebook;
  faGoogle = faGoogle;
  faLinkedin = faLinkedin;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  unlinking: boolean;

  constructor(
    private route: ActivatedRoute,
    private svc: AccountService,
    private toastr: ToastrService,
    private _store: Store<IState>
  ) { }

  ngOnInit() {
    this.route.paramMap.pipe(take(1)).subscribe((params) => {
      this.state = params.get('state');
    });
    this.appState$.pipe(takeUntil(this.destroyed$)).subscribe((state) => (this.user = state.user));
  }

  setState() {
    if (this.state === 'invalid') {
      this.isInvalid = true;
    }
    if (this.state === 'partial') {
      this.isPartial = true;
    }
  }

  removeMe() {
    this.showRemoveMeConfirm = true;
  }

  removeMeConfirm() {
    this.svc.remove(this.user.id);
  }

  removeMeCancel() {
    this.showRemoveMeConfirm = false;
  }

  scrollTo(target: string) {
    document.querySelector(target).scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
