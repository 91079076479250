<section class="container" id="confirm">

  <h1>{{texts.header_reset_password}}</h1>

  <p *ngIf="reset.profile.email">{{texts.text_reset_password_1}}</p>
  <p *ngIf="!reset.profile.email">{{texts.text_reset_password_2}}</p>
  <div class="alert alert-danger" *ngIf="error">{{error}}</div>
  <div class="alert alert-success" *ngIf="message">{{message}}</div>
  <form #f="ngForm" (ngSubmit)="dispatch()" novalidate>
    <div class="form-group">
      <label>{{texts.label_email}}</label>
      <input name="email" type="text" class="form-control" [(ngModel)]="reset.profile.email" [disabled]="precheckOk" required autocomplete="email">
    </div>
    <div class="form-group" *ngIf="precheckOk">
      <label>{{texts.label_confirmation_code}}:</label>
      <input name="code" type="text" class="form-control" [(ngModel)]="reset.resetCode" required>
    </div>
    <div class="form-group mb-3" *ngIf="precheckOk">
      <label>New Password:</label>
      <input type="password" class="form-control" name="new_password" required placeholder="" id="new_password" [(ngModel)]="reset.password" autocomplete="new-password">
    </div>
    <div class="row">
      <div class="col-sm-12">
        <ul class="list-inline">
          <li class="list-inline-item">
            <button type="submit" class="btn btn-primary">{{ precheckOk ? texts.button_confirm : texts.button_submit}}</button>
          </li>
          <li class="list-inline-item">
            <button type="button" (click)="resend()" class="btn btn-primary">{{texts.button_resend}}</button>
          </li>
        </ul>
      </div>
    </div>
  </form>
</section>
