import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { AdminService } from './../../services/admin.service';

@Component({
    selector: 'app-stripe',
    templateUrl: './stripe.component.html',
    standalone: false
})
export class StripeComponent implements OnInit, OnDestroy {
  plans: any;
  coupons: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private adminSvc: AdminService) {}

  ngOnInit(): void {
    this.getPlans();
    this.getCoupons();
  }

  getPlans() {
    this.adminSvc.getPlans().subscribe((r) => (this.plans = r));
  }
  getCoupons() {
    this.adminSvc.getCoupons().subscribe((r) => (this.coupons = r));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
