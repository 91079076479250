<section class="container" id="unauthorized">
  <h1 class="display-5">Unauthorized</h1>
  <p>Protected page or resource.</p>
  <ul class="nav mt-3" *ngIf="isAuthenticated">
    <li class="nav-item3 me-3">
      <a class="btn btn-primary" (click)="goBack()">Back</a>
    </li>
    <li class="nav-item">
      <a class="btn btn-primary" routerLink="/home">Home</a>
    </li>
  </ul>
  <br>
  <ul class="nav" *ngIf="!isAuthenticated">
    <li class="nav-item">
      <button class="nav-link btn btn-primary" (click)="goBack()">Back</button>
    </li>
    <li class="nav-item ms-3">
      <a class="nav-link btn btn-primary" routerLink="/login">Login</a>
    </li>
    <li class="nav-item  ms-3">
      <a class="nav-link btn btn-primary" routerLink="/register">Register</a>
    </li>
  </ul>
  <br>

</section>
