import { Link } from './api-response.model';

export interface UserSubscription {
  subscriptionId: string;
  customerId: string;
  plan: SubscriptionPlan;
  subscriptionRenewal: SubscriptionRenewal;
  couponId: boolean;
  startDate: string;
  endDate: string;
  lastRefreshed: string;
  nextRefresh: string;
  actions: Link[];
  links: Link[];
  objectTypes: ObjectTypes;
  usage: Usage;
  flags: Flags;
  buckets: Bucket[];
  refreshHistory: string[];
  lockStatus: LockStatus;
  cancelOnDate: Date;
  cancelsAtEndOfPeriod: boolean;
}

export interface LockStatus {
  lockReason: LockReason;
  isLocked: boolean;
}

export interface Flags {
  canUpgrade: boolean;
  discountUsed: boolean;
  hasSub: boolean;
  hasPaidSub: boolean;
  hasCompedSub: boolean;
  canHaveBuckets: boolean;
}

export enum SubscriptionRenewal {
  none = 'none',
  monthly = 'monthly',
  yearly = 'yearly',
}

export enum SubscriptionPlan {
  free = 'free',
  basic = 'basic',
  pro = 'pro',
  none = 'none',
}

export enum LockReason {
  none = 'none',
  usageExceeded = 'usageExceeded',
  dispute = 'dispute',
}

export interface Usage {
  totalConversions: number;
  conversionsThisMonth: number;
  monthlyAllowance: number;
  monthlyAdditionalConversions: number;
  usageExceeded: boolean;
  conversionsRemaining: number;
}

export interface Bucket {
  conversions: number;
  isInitialAllowance: boolean;
  expires: string;
  isExpired: boolean;
}

export interface ObjectTypes {
  VIDEO_STREAMING: ObjectType;
  VIDEO_VIMEO: ObjectType;
  VIDEO_YOUTUBE: ObjectType;
  PDF: ObjectType;
  PPT: ObjectType;
}

export interface ObjectType {
  usage: number;
  max: number;
  allowed: boolean;
}
